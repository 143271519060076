import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import style from './notification.module.css';
import { closeNotification } from '@reducers/notification';

const Notification = ({
  isOpen,
  type = 'success',
  message,
  isNotificationAlarm,
  ...props
}) => {
  const [xDown, setXDown] = useState();
  const [yDown, setYDown] = useState(null);

  useEffect(() => {
    if (type === 'success') {
      const id = setTimeout(() => {
        props.closeNotification();
        clearTimeout(id);
      }, 1000);
    }
  }, [isOpen]);

  const getTouches = (evt) => {
    return evt.touches || evt.originalEvent.touches;
  };

  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    setXDown(firstTouch.clientX);
    setYDown(firstTouch.clientY);
  };

  const handleTouchMove = (evt) => {
    evt.preventDefault();
    if (!xDown || !yDown) {
      return '';
    }
    setXDown(null);
    setYDown(null);
    return '';
  };

  return (
    <React.Fragment>
      <div className={cn('rounded shadow od-alert-message-font-style', style.notificationBar, isOpen ? style.notificationOpen : style.notificationClose, type === 'danger' ? style.alertDanger : style.alertSuccess)} onTouchMove={(e) => handleTouchMove(e)} onTouchStart={(e) => handleTouchStart(e)} role="alert">
        <div className="row mx-0">
          <div className="col-10 col-md-11 fw-600">
            {message}
          </div>
          <div className="col-2 p-0 text-right col-md-1" role="presentation" onClick={() => props.closeNotification()}>
            <i className="fa fa-times" />
          </div>
        </div>
        {isNotificationAlarm && (
          <div role="presentation" className={cn('col d-flex justify-content-end align-items-center close', style.close)} onClick={() => props.closeNotification()}>
            <span className="fs-20">
              &times;
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapSateToProps = ({ notification }) => ({
  isOpen: notification.isOpen,
  message: notification.message,
  type: notification.type,
  isNotificationAlarm: notification.isNotificationAlarm,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeNotification,
}, dispatch);
export default connect(
  mapSateToProps,
  mapDispatchToProps
)(Notification);
