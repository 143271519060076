import { getObjectValue } from './utills';

export const getUserBasicDetails = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).user;
};

export const setUserBasicDetails = (data) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  userData.user = data;
  window.localStorage.setItem('userData', JSON.stringify(userData));
};

export const getToken = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).token;
};

export const isUserLogin = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).token;
};

export const isTempToken = () => {
  return (JSON.parse(window.localStorage.getItem('userData')) || {}).temporary;
};

export const isSessionHasToken = () => {
  return !((JSON.parse(window.localStorage.getItem('userData')) || {}).temporary && !window.sessionStorage.getItem('temporary'));
};

export const flowType = () => {
  let flowTypeName = 'serverApp';
  const deviceType = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('deviceInfo')).deviceTypeId, false);
  switch (deviceType) {
    case 7: flowTypeName = 'serverApp'; break;
    case 15: flowTypeName = 'fireboard'; break;
    case 3: flowTypeName = 'kiosk'; break;
    case 10: flowTypeName = 'tableTop'; break;
    case 11: flowTypeName = 'cubby'; break;
    case 8: flowTypeName = 'orderUp'; break;
    case 14: flowTypeName = 'kds'; break;
    case 6: flowTypeName = 'paymentTerminal'; break;
    default:
      break;
  }
  return flowTypeName;
};

export default {
  getUserBasicDetails,
  setUserBasicDetails,
  getToken,
  isUserLogin,
  isTempToken,
  isSessionHasToken,
  flowType
};
