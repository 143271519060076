import { getFormatedPhoneNumber } from '@utility/helperFunction';
import { replaceAll } from '@utility/utills';

export const KEYBOARD_OPEN_REQUEST = 'keyboard/KEYBOARD_OPEN_REQUEST';

export const KEYBOARD_INPUT = 'keyboard/KEYBOARD_INPUT';
export const KEYBOARD_CLOSE_REQUEST = 'keyboard/KEYBOARD_CLOSE_REQUEST';
export const KEYBOARD_DATA = 'keyboard/KEYBOARD_DATA';
export const KEYBOARD_CLEAR = 'keyboard/KEYBOARD_CLEAR';

export const KEYBOARD_OPEN_TEXT_REQUEST = 'keyboard/KEYBOARD_OPEN_TEXT_REQUEST';

export const KEYBOARD_CLOSE_DONE_REQUEST = 'keyboard/KEYBOARD_CLOSE_DONE_REQUEST';

const initialState = {
  isKeyboardOpen: false,
  keyboardInputValue: '',
  type: 'text',
  value: '',
  name: '',
  maxLength: '',
  isAlpha: false,
  isDecimal: false,
  isCross:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYBOARD_OPEN_REQUEST: {
      return {
        ...state,
        isKeyboardOpen: true,
        keyboardInputValue: '',
      };
    }

    case KEYBOARD_OPEN_TEXT_REQUEST: {
      return {
        ...state,
        isKeyboardOpen: true,
      };
    }
    case KEYBOARD_CLOSE_REQUEST: {
      return {
        ...state,
        isKeyboardOpen: false,
        keyboardInputValue: '',
      };
    }

    case KEYBOARD_CLOSE_DONE_REQUEST: {
      return {
        ...state,
        isKeyboardOpen: false,
      };
    }
    case KEYBOARD_INPUT: {
      const text = replaceAll(action.payload, '-');
      return {
        ...state,
        keyboardInputValue: (/^\d+$/.test(replaceAll(action.payload, '-')) && Number(state.countryCode) === 1) ? getFormatedPhoneNumber(text) : action.payload
      };
    }
    case KEYBOARD_DATA: {
      return {
        ...state,
        type: action.payload.type,
        value: action.payload.value,
        maxLength: action.payload.maxLength,
        isAlpha: action.payload.isAlpha,
        isDecimal: action.payload.isDecimal,
        name: action.payload.name,
        countryCode: action.payload.countryCode,
        isDone:action.payload.isDone
      };
    }
    case KEYBOARD_CLEAR: {
      return {
        ...state,
        isCross:action.payload
      };
    }
    default:
      return state;
  }
};

export const keyboardOpen = () => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_OPEN_REQUEST,
      }
    );
  };
};

export const keyboardClose = () => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_CLOSE_REQUEST,
      }
    );
  };
};

export const keyboardCloseForDone = () => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_CLOSE_DONE_REQUEST,
      }
    );
  };
};
export const keyboardInput = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_INPUT,
        payload: data
      }
    );
  };
};

export const keyboardDetails = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_DATA,
        payload: data
      }
    );
  };
};

export const keyboardOpenForClickText = () => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_OPEN_TEXT_REQUEST,
      }
    );
  };
};

export const keyboardClearField = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: KEYBOARD_CLEAR,
        payload: data
      }
    );
  };
};