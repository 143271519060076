export const VISIBLE = 'optionsmenus/VISIBLE';

 const initialState = {
  allChecksVisible: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VISIBLE: {
      return {
        ...state,
        allChecksVisible: !state.allChecksVisible
      };
    }
    default:
      return state;
  }
};

export const setVisible = () => {
  return (dispatch) => {
    dispatch(
      {
        type: VISIBLE,
      }
    );
  };
};
