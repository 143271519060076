export default {
  // The URL we're connecting to
  hostname: process.env.REACT_APP_API_SERVER_HOST || 'https://iapi.dev.onedine.com:9443',
  // hostname: 'https://mapi.dev.onedine.com:4443',
  // Map shortnames to the actual endpoints, so that we can use them in the middleware
  endpoints: {
    activate: '/api/device/activate',
    storeInfo: '/api/device/storeInfo/deviceid',
    login: '/api/employee/login',
    register: '/api/account/register',
    reset_password: '/api/account/reset/password',
    fonts: '/api/fonts/getFontList/',
    logout: '/api/employee/logout',
    users: 'users',
    deleteUser: '/users',
    getUser: '/users',
    sendotp: '/api/accounts/password/forgot',
    sendCode: 'api/token/code/generate',
    verifyCode: '/api/token/code/verify',
    verifyotp: '/api/accounts/password/reset/email',
    verifyOtpPhone: '/api/accounts/password/reset/phone',
    // register: '/api/account/register',
    // login: '/api/token',
    changePassword: '/api/accounts/password/change',
    updateBasicDeails: '/api/accounts/profile/update',
    // fetchOffers: '/api/loyalties/offers',
    fetchOffers: '/api/loyalties/pointsAndOffers',
    loyaltyOffers: '/api/lookups/offerSystems',
    suscribeToOffers: '/api/offers/accept',
    featchWallet: '/api/accounts/wallet/cards',
    getOrderHistory: '/api/receipts/list',
    getOrderDetails: '/api/receipts/ticketId',
    verifyPhoneNumber: '/api/accounts/verifyPhoneNumber',
    create_profile: '/api/accounts/profile/create',
    getTable: '/api/accounts/popIn',
    assignSpot: '/api/orders/spotAssignment',
    generateUserName: '/api/accounts/generateUsername',
    checkUniqueUserName: '/api/accounts/isUsernameUnique',
    getOauth: 'api/token/create',
    getSurvey: 'api/survey/store',
    getSmsSurvey: 'api/surveys/check/checkHeaderId',
    // getSurvey: 'api/surveys/ticketId',
    sensorCheckIn: '/api/sensors/popin',
    getPopIn: '/api/users/popIn',
    getReceipt: '/api/receipts/referenceCode',
    updateTable: '/api/orders/stationAssignment',
    callServer: '/api/notification/callServer',
    submitSurvey: '/api/survey/guest',
    smsReceipt: '/api/receipts/sms',
    emailReceipt: '/api/receipts/email',
    getPopInblank: '/api/users/popIn',
    checkoutTable: '/api/users/popOut/checkInId',
    getManifest: '/api/accounts/wallet/cardManifest/storeId',
    getPayPalManifest: '/api/accounts/wallet/paypal/createOrder',
    addCard: '/api/accounts/wallet/addCard',
    // paymentByCard: '/api/payments/sale',
    paymentByCard: '/api/payments/online',
    itemsOrderPay: '/api/orders/orderPayItems',
    getMenuList: '/api/menus/screen/menuId',
    getMenuOrderDetails: '/api/menus/menuItem/menuScreenId',
    appCustomization: '/api/brands/customize/brandId',
    // getMenu: '/api/orders/menus/guestApp/storeId',
    // getMenu: '/api/menus/guest/order/storeId',
    getMenu: '/api/store/menu',
    getCallCenterMenu: '/api/store/menu/callCenter/order/compressed',
    placeOrder: '/api/orders/addItems',
    orderItems: '/api/orders/orderItems',
    priceCheck: '/api/order/priceCheck',
    upsellCheck: '/api/order/upSell',
    selectStage: '/api/checks/checkInSelection',
    getCompaniesList: '/api/companies/list',
    getCompanyById: '/api/companies/companyId',
    getBrandsByCompanyId: '/api/brands/brandId',
    getStoresByBrandId: '/api/stores/list/brandId',
    getStationsByStoreId: '/api/stations/storeId',
    registerSensor: '/api/sensors/register',
    googleSignIn: '/api/token/google',
    googleClientId: '/api/token/google/clientId',
    facebookSignIn: '/api/token/facebook',
    applePayGetSession: '/api/payments/applePay/getSession',
    sendToPaymentGateway: '/api/payments/online',
    getLoyaltyOtp: '/api/loyalties/account/signUp/init',
    loyaltyAccountSignUpComplete: '/api/loyalties/account/signUp/complete',
    // loyaltyAccountSignUp: '/api/loyalties/account/signUp',
    loyaltyAccountLookUp: '/api/loyalties/account/lookup',
    // loyaltyAccountAssign: '/api/loyalties/guest/assign',
    loyaltyAccountUnAssign: '/api/loyalties/guest/unAssign',
    getGooglePayMerchantInfo: '/api/payments/googlePay/getMerchantInfo',
    deleteCard: '/api/accounts/wallet/deleteCard/cardId',
    getStorePickUpTime: '/api/orders/pickupTimes/storeId',
    getStorePickupDeliveryTime: '/api/orders/deliveryTimes/storeId',
    postMyData: '/api/admin/myData',
    // getMenuCategory: '/api/menus/guest/browse/storeId',
    getMenuCategory: '/api/menus/guest/browse/compressed',
    getProfileOrderHistory: '/api/receipts/storeId',
    getStoreThemeUrl: '/api/store/theme',
    getGiftCardBalance: '/api/loyalties/giftCardBalance',
    gameCardProcess: '/api/order/gameCard/process',
    gameCardvalidate: '/api/order/gameCard/validate',
    gameCardBalance: '/api/order/gameCard/balance',
    giftCardvalidate: '/api/order/giftCard/validate',
    giftCardProcess: '/api/order/giftCard/process',
    giftCardBalances: '/api/order/giftCard/balance',
    giftCardSwipe: '/api/order/giftCard/reProcess',
    tableManagment: '/api/tms',
    createWaitList: '/api/tms/waitList/create',
    storeTag: '/api/tms/storeTag/storeId',
    availableTime: '/api/tms/availableTime',
    getStorePickUpTimeBasedOnDate: '/api/orders/deliveryTimes/storeId',
    storeOccasionList: '/api/lookups/occasions/storeId',
    firstAvailableTime: '/api/tms/firstAvailableTime',
    daisyChain: '/api/orders/daisyChain',
    getStoreDeliveryTime: '/api/orders/deliveryTimes/storeId',
    checkReceipt: '/api/receipts/check/referenceCode',
    getPreviewPopIn: 'api/users/popIn/menuPreview',
    renameCard: '/api/accounts/wallet/renameCard',
    featchWalletByStoreID: '/api/accounts/wallet/cards/storeId',
    acceptTerms: '/api/accounts/profile/acceptTerms',
    accrueLoyalty: '/api/loyalties/accrue',
    validateUnchanged: '/api/checks/validateUnchanged',
    // sessionTrack: '/api/admin/analytics',
    addFavourite: '/api/accounts/favorite/add',
    removeFavourite: '/api/accounts/favorite/delete/memberFavoriteId',
    fetchLoyaltyAccountsList: '/api/accounts/wallet/loyalties',
    renameLoyaltyAccountName: '/api/accounts/wallet/loyalties/rename',
    deleteLoyaltyAccountCard: '/api/accounts/wallet/loyalties/delete/memberLoyaltyId',
    getPickDeliveryTimes: '/api/orders/pickupDeliveryTimes',
    fetchAddressBookList: '/api/accounts/address',
    addNewMemberAddress: '/api/accounts/address/add',
    updateMemberAddress: '/api/accounts/address/update',
    deleteMemberAddress: '/api/accounts/address/delete/memberAddressId',
    acknowledge: 'api/notification/acknowledge',
    checkOpen: '/api/check/open',
    checkUpdate: '/api/check/updateOrder',
    getStorePickDeliveryTimes: 'api/store/pickupDeliveryTimes',
    occupySeat: '/api/seat/occupy',
    addToCheck: 'api/order/add',
    deleteItem: 'api/order/delete',
    updateCheck: 'api/order/update',
    move: 'api/seat/moveItems',
    // stage end points
    addToStage: 'api/check/stage/add',
    deleteStage: 'api/check/stage/delete',
    renameToStage: 'api/check/stage/rename',

    sendChecks: '/api/order/fire',
    checkPrep: '/api/checkPrep/bump',
    receiptPdf: 'api/store/menu/recipe/storeId',
    layout: 'api/store/kdsLayout/storeId',
    layoutUpdate: 'api/store/kdsLayout/updateEnabled/brandKdsLayoutId',
    duplicate: 'api/store/kds/mirror/deviceId',
    reprioritize: 'api/checkPrep/rePrioritize',
    prepStartAll: '/api/checkPrep/startAll',
    moveChecks: '/api/seat/moveItems',
    paymentInitialization: '/api/payment/init',
    paymentCash: '/api/payment/cash',
    paymentAuth: '/api/payment/auth',
    paymentSale: '/api/payment/sale',
    preAuthPayment: '/api/payment/preAuthComplete',
    paymentAuthError: '/api/payment/error',
    paymentAuthComplete: '/api/payment/authComplete',
    receiptEmail: '/api/paymentNew/receipt/email',
    giftcardreceiptEmail: '/api/payment/receipt/giftCard/email',
    receiptSms: '/api/paymentNew/receipt/sms',
    giftcardreceiptSms: '/api/payment/receipt/giftCard/sms',
    seatClear: '/api/seat/clear',
    employeeTips: '/api/employee/tips',
    deviceActivate: '/api/device/autoActivate',
    paymentSignature: '/api/payment/signature',
    preAuth: '/api/payment/preAuth',
    print: '/api/check/print',
    receiptPrint: '/api/paymentNew/receipt/print',
    giftCardReceiptPrint: 'api/payment/receipt/giftCard/print',
    orderTicketPrint: 'api/payment/orderNumberLabel/print',
    giftCardBalance: 'api/giftCard/balance',
    giftCardPaymentInit: 'api/payment/giftCardInit',
    giftCardPayment: 'api/payment/giftCardComplete',
    loyaltyAccountLookup: 'api/loyalty/accountLookup',
    loyaltyAccountAssign: 'api/loyalty/accountAssign',
    loyaltyValidateOffer: 'api/loyalty/validateOffer',
    loyaltyApplyOffer: 'api/loyalty/applyOffer',
    loyaltyAccountSignUp: 'api/loyalty/accountSignUp',
    textToPay: 'api/payment/textToPay',
    syncCheck: 'api/check/sync/storeId',
    updateCheckName: 'api/check/updateName',
    changeJob: 'api/employee/profile/jobType',
    tableTopTile: 'api/store/tile/tableTop/storeId',
    transferTable: 'api/check/transfer/station',
    transferEmployee: 'api/check/transfer/employee',
    voidItem: 'api/order/item/void',
    voidCheck: 'api/check/void',
    syncCheckForPayment: 'api/check/sync',
    orientationChange: 'api/employee/profile/orientation',
    getKioskTile: 'api/store/tile/kiosk/storeId',
    checkVoid: 'api/check/void',
    getCubbyTiles: 'api/store/tile/cubby/storeId',
    getCubbyLayoult: 'api/store/cubby/storeId',
    getOrderUpTiles: 'api/store/tile/orderUp/storeId',
    ingenioPayment: 'api/payment/device/sale',
    apiLog: 'api/admin/apiLog',
    // deleteLoyaltyAccount: 'api/loyalty/accountClear',
    changeLanguage: '/api/brands/theme/brandId',
    getTips: '/api/payment/device/tip',
    employeeCashOut: '/api/employee/cashOut/validate',
    drawerCashOut: '/api/employee/cashOut/drawer/validate',
    updateEmployeeCashOut: '/api/employee/cashOut/update',
    updateDrawerCashOut: '/api/employee/cashOut/drawer/update',
    applyDiscount: '/api/order/discount/add',
    postDiscount: '/api/order/discount/post',
    deleteDiscount: '/api/order/discount/delete',
    getCashIn: '/api/employee/cashIn/validate',
    updateCashIn: '/api/employee/cashIn/update',
    allChecks: 'api/cache/checksCompressed/storeId',
    allCheckPreps: 'api/cache/checkPrepsCompressed/storeId',
    renameSeat: 'api/seat/rename',
    confirmCheckId: 'api/check/idCheck/confirm',
    disableAgeRestrict: 'api/check/ageRestrictedItems/update',
    disableAgeRestrictSeat: 'api/seat/ageRestrictedItems/update',
    externalCreditCard: 'api/payment/externalSale',
    giftCardImage: 'api/giftCard/ocr',
    sendQRLink: 'api/check/sendQrLink',
    printTableQR:'api/check/qr/print',
    mergeOpenCheck: 'api/check/merge',

    
    logCapiClientHealthInfo: 'api/admin/apiLog/capi/client/healthInfo',
    unBumpItem: 'api/checkPrep/unBump',
    deleteCheckDiscount: '/api/order/discount/unPost',

    checkReSchedule: 'api/checkPrep/reSchedule',

    //New payment flow API's
    syncForPayment: 'api/paymentNew/check/sync',
    paymentSplitCheck: 'api/paymentNew/check/createSplit',
    paymentClearSplit: 'api/paymentNew/check/clearSplit',
    applyTip: 'api/paymentNew/tip/apply',
    payByCreditCard: 'api/paymentNew/creditCard/apply',
    paymentNewInitialization: '/api/paymentNew/payment/init',
    paymentNewPaymentComplete: 'api/paymentNew/payment/complete',
    paymentNewPaymentError: 'api/paymentNew/payment/error',
    paymentByCash: 'api/paymentNew/cash/apply',
    cashClearBalance: 'api/paymentNew/cash/clear',
    tipClear: 'api/paymentNew/tip/clear',
    newIngenicoPayment: 'api/paymentNew/device/sale',

    //New Loyalty APIs
    loyaltySignUp: 'api/paymentNew/loyalty/signUp',
    loyaltySignIn: 'api/paymentNew/loyalty/signIn',
    loyaltyGetOffers: 'api/loyalty/getOffers',
    applyLoyaltyOffer: 'api/paymentNew/loyalty/offer/apply',
    handleDeleteOffer: 'api/paymentNew/loyalty/offer/clear',
    deleteLoyaltyAccount: 'api/paymentNew/loyalty/clear',

    applyGiftCardBalance: 'api/paymentNew/giftCard/apply',
    giftCardDeleteBalance: 'api/paymentNew/giftCard/clear',
    creditCardDeleteBalance: 'api/paymentNew/creditCard/clear',
    loyaltyNewBalance: 'api/loyaltyNew/balance',

    // Survey 
    getPaymentNewSurvey : '/api/paymentNew/survey/store',
    savePaymentNewSurvey : '/api/paymentNew/survey/save',
    
  }
};
