export default {
  MOBILE_NUMBER: 'Please enter valid mobile number',
  PASSWORD: 'Please enter valid password',
  FULL_NAME: 'Please enter your full name',
  COUNTRY_CODE: 'Please enter valid country code',
  EMAIL_ID: 'Please enter valid email',
  REENTER_PASSWORD: 'Please re-enter password',
  DOB: 'Please enter your DOB',
  MOBILE_AND_EMAIL: 'Please enter valid mobile number or email Id',
  FIRST_NAME: 'Please enter your first name',
  LAST_NAME: 'Please enter your last name',
  STREET_ADDRESS: 'Please enter your street address',
  LOCALITY: 'Please enter your locality/district',
  CITY: 'Please enter your city',
  STATE: 'Please enter your state',
  PINCODE: 'Please enter valid pincode',
  DROPDOWN: 'Please enter',
  OTP: 'Please enter valid OTP',

  // Notification_message
  SERVER_ERROR: 'Internal server error',
  INVALID_LOGIN: 'Invalid Login Credentials',
  SOMETHING_WRONG: 'Oops! Something went wrong please try again later',
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again',
  USER_NOT_FOUND: 'User not found!',
  INVALID_TABLE_NO: 'Invalid Table Number please try again',
  SELECT_REQUIRED_MODIFIERS: 'Please select all the required modifiers',
  SELECT_EXCEEDS_MODIFIERS: 'Modifiers exceeds maximum limit',
  NEW_CARD_ADDED_ERROR: 'Error while adding the new card',
  NAME_REQUIRED: 'Name is required',
  TERMS: 'Terms must be accepted',
  PHONE_NUMBER_LENGTH: 'Phone number length must be',
  FIELD_REQUIRED: 'This field is required',
  VALID_EMAIL: 'Enter valid a email address',
  ADD_REMOVE_MODIFIER: 'Please edit/remove modifier',
  SEND_PENDING_ITEMS: 'Please send the pending items',
  SEND_QR_SMS_FAILURE: 'SMS Not Sent',
  MERGE_CHECKS_FAILURE: 'Checks Not Merged'
};
