import { getHeaders } from '@utility/helperFunction';

const TILE_TABLE_TOP_REQUEST = 'storeChecks/TILE_TABLE_TOP_REQUEST';
const TILE_TABLE_TOP_SUCCESS = 'storeChecks/TILE_TABLE_TOP_SUCCESS';
const TILE_TABLE_TOP_FAILURE = 'storeChecks/TILE_TABLE_TOP_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  tileTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TILE_TABLE_TOP_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        tileTypes: [],
      };
    }
    case TILE_TABLE_TOP_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        tileTypes: action.result
      };
    }
    case TILE_TABLE_TOP_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getTableTopTile = (storeId) => {
  const headers = getHeaders();
  return {
    types: [TILE_TABLE_TOP_REQUEST, TILE_TABLE_TOP_SUCCESS, TILE_TABLE_TOP_FAILURE],
    promise: (client) => client.get(`tableTopTile/${storeId}`, {
      headers
    })
  };
};
