export default class BatteryApi {
  constructor() {
    this.getPrototypes = () => {
      return new Promise((resolve, reject) => {
        if (navigator.getBattery) {
          resolve(navigator.getBattery());
        } else {
          reject(new Error('Battery API not supported on your device/computer'));
        }
      });
    };
  }
}
