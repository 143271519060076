import { EventEmitter } from 'events';

/* eslint-disable */
class tWebEventEmitter {
    ee: EventEmitter;
    constructor() {
        this.ee = new EventEmitter();
        if (this.getOS() === 'Android') {
          document && document.addEventListener("message", (response) => {
            const { message, event, error, storeInfo, deviceInfo, deviceApi, RNDevEnv, deviceTypeId, lastRebootOn, isDeviceAvailabile } = JSON.parse(response.data);
            if (event === 'deviceStatus') {
              window.sessionStorage.setItem('cardReader', message?.deviceState)
            }
            if (event === 'deviceAvailable') {
              window.sessionStorage.setItem('isDeviceAvailable', isDeviceAvailabile)
            }
            if (event === 'activation') {
              window.localStorage.setItem('activation', response.data)
            }
            if (storeInfo && storeInfo.data && deviceInfo) {
              this.ee.emit(event, storeInfo, deviceInfo, null, deviceApi, RNDevEnv, deviceTypeId, lastRebootOn);
            } else if (message === 'Print successful') {
              this.ee.emit(event, message, null);
            } else {
              this.ee.emit(event, message, error);
            }
          });
        } else {
          window && window.addEventListener("message", (response) => {
            if (response && response.data && typeof response.data === 'string' || response.data instanceof String) {
              try {              
                const { message, event, error, storeInfo, deviceInfo, deviceApi, RNDevEnv, deviceTypeId } = JSON.parse(response.data);
                if (storeInfo && storeInfo.data && deviceInfo) {
                  this.ee.emit(event, storeInfo, deviceInfo, null, deviceApi, RNDevEnv, deviceTypeId);
                } else if (message === 'Print successful') {
                  this.ee.emit(event, message, null);
                } else {
                  this.ee.emit(event, null, error);
                }
              } catch (e) { 
                console.log(e, response);
              };
            }
          });
        }
      }

      getOS = () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
        if (iosPlatforms.indexOf(platform) !== -1 || macosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        }
        return os;
      }

      addListeners = (event, callback) => {
        this.ee.on(event, callback);
      }
    
      removeListeners = (event, callback) => {
        this.ee.off(event, callback);
      }

      sendMessage = (data, successCallback, errorCallback) => {
        // var listener = (success, error) => {
        //   if (success) {
        //     successCallback && successCallback();
        //   } else if (error) {
        //     errorCallback && errorCallback();
        //   }
        //   this.removeListeners('print', listener);
        // };
        // this.addListeners('print', listener);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
      }

      activateDevice = (callback) => {
        var listener = (storeInfo, deviceInfo, mode, deviceApi, RNDevEnv, deviceTypeId) => {
          callback && callback(storeInfo, deviceInfo, mode, deviceApi, RNDevEnv, deviceTypeId);
          this.removeListeners('activation', listener);
        };
        this.addListeners('activation', listener);
      }

      activateIngenicoDevice = (callback) => {
        var listener = (storeInfo, deviceInfo, mode, deviceApi, RNDevEnv, deviceTypeId) => {
          callback && callback(storeInfo, deviceInfo, mode, deviceApi, RNDevEnv, deviceTypeId);
          this.removeListeners('activateIngenicoDevice', listener);
        };
        this.addListeners('activateIngenicoDevice', listener);
      }

      scanIngenicoDevice = (successCallback, errorCallback) => {
        var listener = (data, error) => {
          if (data) {
            successCallback && successCallback(data);
          } else if (error) {
            errorCallback && errorCallback(error);
          }
          this.removeListeners('scanMagCard', listener);
        };
        this.addListeners('scanMagCard', listener);
      }

      processCredit = (callback) => {
        var listener = (data) => {
          callback && callback(data);
          // this.removeListeners('processCredit', listener);
        };
        this.addListeners('processCredit', listener);
      }

      processCreditSale = (successCallback, errorCallback) => {
        let timeoutId = '';
        var listener = (data, error) => {   
          if (data) {
            successCallback && successCallback(data);
          } else if (error) {
            errorCallback && errorCallback(error);
          }
          clearTimeout(timeoutId);
          this.removeListeners('processCreditSale', listener);
        };
        // timeoutId = setTimeout(() => {
        //   clearTimeout(timeoutId);
        //   this.removeListeners('processCreditSale', listener);
        //   errorCallback({ message: 'Card transaction could not be processed. Card reader did not respond in 63 seconds' });
        // }, 63 * 1000);
        this.addListeners('processCreditSale', listener);
      }

      onProcessCreditTokenize = (callback) => {
        var listener = (data) => {
          callback && callback(data);
        };
        this.addListeners('onProcessCreditTokenize', listener);
      }

      onSuccessCreditTokenize = (successCallback, errorCallback) => {
        let timeoutId = '';
        var listener = (data, error) => {
          if (data) {
            successCallback && successCallback(data);
          } 
          // else if (error) {
          //   errorCallback && errorCallback(error);
          // }
          clearTimeout(timeoutId);
          this.removeListeners('onSuccessCreditTokenize', listener);
        };
        // timeoutId = setTimeout(() => {
        //   clearTimeout(timeoutId);
        //   this.removeListeners('onSuccessCreditTokenize', listener);
        //   errorCallback({ message: 'Card transaction could not be processed. Card reader did not respond in 63 seconds' });
        // }, 63 * 1000);
        this.addListeners('onSuccessCreditTokenize', listener);
      }

      onErrorCreditTokenize = (errorCallback) => {
        let timeoutId = '';
        var listener = (data, error) => {
          if (error) {
            errorCallback && errorCallback(error);
          }
          // clearTimeout(timeoutId);
          this.removeListeners('onErrorCreditTokenize', listener);
        };
        // timeoutId = setTimeout(() => {
        //   clearTimeout(timeoutId);
        //   this.removeListeners('onErrorCreditTokenize', listener);
        //   errorCallback({ message: 'Card transaction could not be processed. Card reader did not respond in 63 seconds' });
        // }, 63 * 1000);
        this.addListeners('onErrorCreditTokenize', listener);
      }

      processPayment = (successCallback, errorCallback) => {
        var listener = (data, error) => {
          if (data) {
            successCallback && successCallback(data);
          } else if (error) {
            errorCallback && errorCallback(error);
          }
          // this.removeListeners('processPayment', listener);
        };
        this.addListeners('processPayment', listener);
      }

      printData = (successCallback, errorCallback) => {
        var listener = (data, error) => {
          if (data) {
            successCallback && successCallback(data);
          } else if (error) {
            errorCallback && errorCallback(error);
          }
          this.removeListeners('printData', listener);
        };
        this.addListeners('printData', listener);
      }

      completePayment = (successCallback, errorCallback) => {
        var listener = (data, error) => {
          if (data) {
            successCallback && successCallback(data);
          } else if (error) {
            errorCallback && errorCallback(error);
          }
          this.removeListeners('completePayment', listener);
        };
        this.addListeners('completePayment', listener);
      }

      stopMagneticCardRead = (callback) => {
        var listener = (data) => {
          callback && callback(data);
          this.removeListeners('stopMagCard', listener);
        };
        this.addListeners('stopMagCard', listener);
      }

  syncStatus = status => {
    const data = {
      type: 'syncStatus',
      status: status,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  };

  sendVenueInfo = storeName => {
    const data = {
      type: 'venueInfo',
      storeName: storeName,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  sendException = (error) => {
    const data = {
      type: 'exception',
      error: error,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  sendAppMode = version => {
    window.ReactNativeWebView.postMessage(JSON.stringify({
      type: 'appMode',
      appMode: process.env.REACT_APP_MODE,
      webAppVersion: version,
    }));
  }
  
  playAudio = (play) => {
    const data = {
      type: 'playAudio',
      play: play,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  version = (versionInfo) => {
    const data = {
      type: 'webVersion',
      version: versionInfo,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  enableSideBar = (value) => {
    const data = {
      type: 'enableSideBar',
      isEnabled: value,
      isShow: value,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  mdmPolicy = (status) => {
    const data = {
      type: 'mdmPolicy',
      status: status,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }

  syncLogsData = (status, info, message) => {
    const reqData = {
      type: 'syncLogs',
      data: {
        logLevel : status,
        logMessage : { info, message }
      }
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  networkLogs = (url, requestBody, response, timeData) => {
    const reqData = {
      type: 'networkLogs',
      data: {
        logLevel : response.type,
        url,
        requestBody,
        response: response.body,
        timeData
      }
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  print = (data) => {
    const reqData = {
      type: 'printData',
      printType: 'items',
      data
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  inActive = (callback) => {
    var listener = (response) => {
      if (response) {
        callback && callback(response.enableInactivity);
      } 
    };
    this.addListeners('inactivity', listener);
  }

  cardreaderInitialized = (callback) => {
    var listener = (response) => {
      if (response.status) {
        callback && callback(response.status);
      } 
    };
    this.addListeners('cardreaderInitialized', listener);
  }

  deviceStatus = (callback) => {
    var listener = (response) => {
      if (response.deviceState) {
        callback && callback(response.deviceState);
      } 
    };
    this.addListeners('deviceStatus', listener);
  }

  postInactiveStatus = (status) => {
    const reqData = {
      type: 'inactivity',
      enableInactivity: status,
      inactivityTimeout: 60 * 1000
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  postScreenSaverStatus = (status) => {
    const reqData = {
      type: 'screensaver',
      enableScreensaver: false,
      screensaverTimeout: 180 * 1000
    }
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  postFlipSetting = (data) => {
    const reqData = {
      type: 'flipSetting',
      data,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  postEnableResizeView = (status) => {
    const reqData = {
      type: 'enableResizeView',
      data: {
        enable: status,
        show: status
      },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  postSetViewHeight = (height) => {
    const reqData = {
      type: 'setViewHeight',
      data: {
        height: height
      },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  getViewHeight = (successCallback) => {
    var listener = (data) => {
      if (data) {
        successCallback && successCallback(data);
      }
      this.removeListeners('getViewHeight', listener);
    };
    const reqData = {
      type: 'getViewHeight'
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
    this.addListeners('getViewHeight', listener);
  }

  isDeviceAvailable = (successCallback) => {
    var listener = (data) => {
      if (data) {
        successCallback && successCallback(data);
      }
      this.removeListeners('deviceAvailable', listener);
    };
    this.addListeners('deviceAvailable', listener);
  }

  deviceAvailable = () =>{
    const reqData = {
      type: 'isDeviceAvailable'
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  drawerOpen = (data) => {
    const reqData = {
      type: 'drawerOpen',
      data,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  drawerStatus = (data, successCallback) => {
    var listener = (success) => {
      if (success) {
        successCallback && successCallback(success);
      }
      this.removeListeners('getDrawerStatus', listener);
    };
    const reqData = {
      type: 'drawerStatus',
      data
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
    this.addListeners('getDrawerStatus', listener);
  }

  lauchExternalApp = (data) => {
    const reqData = {
      type: 'lauchExternalApp',
      data,
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

  reLaunchApp = () => {
    const reqData = {
      type: 'relaunchApp',
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }
  
  changeOrientation = (data) => {
    const reqData = {
      type: 'changeOrientation',
      data  
    };
    // {
    //   orientation: data
    // }
    window.ReactNativeWebView.postMessage(JSON.stringify(reqData));
  }

}

export default new tWebEventEmitter();
