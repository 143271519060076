/* eslint-disable consistent-return */
import pako from 'pako';
import {
  reverse,
  findIndex,
  // sortBy,
  filter,
  find
} from 'lodash';

export const getObjectValue = (cb, defaultValue) => {
  try {
    return cb();
  } catch (e) {
    // console.log(e);
    return defaultValue;
  }
};
export const urlSearchParams = (name) => {
  // eslint-disable-next-line
  const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results === null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
};
const decriptRespose = (base64Data) => {
  if (base64Data !== null) {
    const strData = atob(base64Data);
    // Convert binary string to character-number array
    const charData = strData.split('').map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Pako magic
    let data;
    try {
      data = pako.inflate(binData);
    } catch (e) {
      console.log(e);
    }
    // Convert gunzipped byteArray back to ascii string:
    // const strDataResult = String.fromCharCode.apply(null, new Uint16Array(data));
    return JSON.parse(new TextDecoder('utf-8').decode(data));
  }
  return '';
};

export const getImageUrl = (menuItem, screen, screenWidth, columnStyle) => {
  let imageUrl = '';
  if (screen === 'order') {
    if (screenWidth < 1024) {
      if (columnStyle && columnStyle.doubleWidth) {
        imageUrl = menuItem.mediumImageUrl ? menuItem.mediumImageUrl : menuItem.imageUrl;
      } else {
        imageUrl = menuItem.smallImageUrl ? menuItem.smallImageUrl : menuItem.imageUrl;
      }
    } else {
      imageUrl = menuItem.mediumImageUrl ? menuItem.mediumImageUrl : menuItem.imageUrl;
    }
  } else if (screen === 'itemDetail') {
    if (screenWidth < 1024) {
      imageUrl = menuItem.mediumImageUrl ? menuItem.mediumImageUrl : menuItem.imageUrl;
    } else {
      imageUrl = menuItem.largeImageUrl ? menuItem.largeImageUrl : menuItem.imageUrl;
    }
  } else if (screen === 'cart') {
    imageUrl = menuItem.thumbnailImageUrl ? menuItem.thumbnailImageUrl : menuItem.imageUrl;
  }
  return imageUrl;
  // return '';
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

const isNumericInput = (event) => {
  const key = event.keyCode;
  return ((key >= 48 && key <= 57) || (key >= 96 && key <= 105));
};

const isModifierKey = (event) => {
  const key = event.keyCode;
  return (event.shiftKey === true || key === 35 || key === 36) || (key === 8 || key === 9 || key === 13 || key === 46) || (key > 36 && key < 41) || ((event.ctrlKey === true || event.metaKey === true) && (key === 65 || key === 67 || key === 86 || key === 88 || key === 90));
};

export const enforceFormat = (event) => {
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

export const formatInputPhoneNumber = (event) => {
  if (isModifierKey(event)) { return; }
  const target = event.target;
  const input = target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    target.value = `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    target.value = `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    target.value = `(${zip}`;
  }
};

export const replaceAll = (string, pattern) => {
  if (string?.length > 0) {
    const splitString = string.split('');
    let concatString = '';
    splitString.forEach((char) => {
      if (pattern !== char) {
        concatString += char;
      }
    });
    return concatString;
  }
  return '';
};

export const deconstructStringUrl = (string) => {
  let message = '';
  let link = '';
  const splitText = string.split('');
  let linkCheck = false;
  let linkIndex = false;
  splitText.forEach((char, i) => {
    if (char === '<') {
      linkCheck = true;
      message += '¿';
    }
    if (char === '>') {
      linkIndex = i + 1;
    }
    if (linkIndex === i && char !== '<') {
      linkCheck = false;
      linkIndex = false;
    }
    if (linkCheck) {
      link += char;
    } else {
      message += char;
    }
  });
  link = replaceAll(link, '<').split('>').filter((d) => d);
  link = link.map((i) => {
    return i.split('|');
  }).flat();
  const splitMessage = message.split('');
  let concatChar = '';
  let index = 0;
  splitMessage.forEach((char) => {
    const replaceCheck = ['¿'].includes(char);
    if (replaceCheck) {
      concatChar += `¿#?${link[index]}¿`;
      index += 2;
    } else {
      concatChar += char;
    }
  });
  return [link, concatChar];
};

export const modifySeatObject = (payload) => {
  const Items = [];
  const PendingItemsData = [];
  const AllItems = [];
  ((payload?.orders && payload?.orders[0] && payload?.orders[0]?.items) || []).forEach((i) => {
    // const modifiersObj = JSON.parse(i.orderJson).modifierJson ? JSON.parse(JSON.parse(i.orderJson)?.modifierJson) : null;
    const modifiersObj = i.orderJson && JSON.parse(i.orderJson).modifierJson ? JSON.parse(JSON.parse(i.orderJson)?.modifierJson) : null;
    const item = {
      ...i,
      ...JSON.parse(i.orderJson),
      ...modifiersObj,
    };
    if (!modifiersObj?.isLinked) {
      AllItems.push(item);
      if (i.pending) {
        PendingItemsData.push(item);
      }
    }
  });
  ((payload?.items?.length > 0 && payload?.items) || []).forEach((i) => {
    const checkItem = (payload?.orders && payload?.orders[0] && payload?.orders[0]?.items).find((d) => d.checkItemId === i.id);
    if (checkItem) {
      const modifiersObj = JSON.parse(JSON.parse(checkItem.orderJson)?.modifierJson);
      const item = {
        ...i,
        ...JSON.parse(checkItem.orderJson),
        ...modifiersObj,
        price: i.price
      };
      if (!modifiersObj?.isLinked) {
        Items.push(item);
      }
    } else {
      Items.push(i);
    }
  });
  const PendingItems = reverse(PendingItemsData);
  return { Items, PendingItems, AllItems };
};

export const mapStringCartToObject = (cart, orders, modifierHashTable, modifierGroupsHashTable) => {
  if (modifierGroupsHashTable !== undefined) {
    const newArr = [];
    const checkIsWeight = [];
    cart.forEach((cartItem) => {
      let k = 1;
      const modifierToSend = [];
      if (cartItem.modifiers) {
        [...cartItem.modifiers].forEach((modPath) => {
          const updateModifer = (path, modiferListToUpdate, fullPath) => {
            if (path === '') {
            // return;
            } else {
              const nextPath = path
                .split('|')
                .slice(1)
                .join('|');
              const currentModifier = path.split('|')[0];
              const index = findIndex(
                modiferListToUpdate,
                (data) => (data.modifierId === currentModifier.split('-')[0] && data.modifierGroupId === currentModifier.split('-')[1])
              );
              if (index >= 0) {
                updateModifer(nextPath, modiferListToUpdate[index].modifiers, fullPath);
              } else {
                let isFound = false;
                // let fountObj = {};
                for (let idx = 0; idx < modiferListToUpdate.length; idx += 1) {
                  const element = modiferListToUpdate[idx];
                  if (element.modifierId === Number(currentModifier.split('-')[0]) && element.modifierGroupId === Number(currentModifier.split('-')[1]) && element.quantity > 1) {
                    isFound = true;
                  }
                }
                let isFoundVal = false;
                if (fullPath.includes('|')) {
                  if (fullPath.split('|')[1] === currentModifier) {
                    if (modifierGroupsHashTable[fullPath.split('|')[0].split('-')[1]].isWeight) {
                      isFoundVal = true;
                    }
                  }
                }
                let isWeightVal = false;
                if (modifierGroupsHashTable !== undefined && modifierGroupsHashTable[currentModifier?.split('-')[1]].isWeight) {
                  isWeightVal = true;
                }
                let isExist = findIndex(modiferListToUpdate, (mod) => mod.modifierId === Number(currentModifier.split('-')[0]) && mod.modifierGroupId === Number(currentModifier.split('-')[1]));
                if (isFound && modifierGroupsHashTable !== undefined && modifierGroupsHashTable[Number(modPath?.split('|')[0].split('-')[1])].isWeight) {
                  isExist = 0;
                } else if (modifierGroupsHashTable !== undefined && isWeightVal) {
                  if (checkIsWeight.length === 0) {
                    checkIsWeight.push(modPath);
                    isFound = true;
                  }
                }
                if (isExist === -1) {
                  modiferListToUpdate.push({
                    modifierId: Number(currentModifier.split('-')[0]),
                    modifierGroupId: Number(currentModifier.split('-')[1]) ? Number(currentModifier.split('-')[1]) : null,
                    quantity:
                    nextPath === '' && !isFound && !isFoundVal ? filter(cartItem.modifiers, (d) => d === fullPath).length : 1,
                    price: (modifierGroupsHashTable) ? getObjectValue(() => find(modifierGroupsHashTable[Number(currentModifier.split('-')[1])].modifierLinks, (d) => d.modifierId === Number(Number(currentModifier.split('-')[0]))).price, 0) : 0,
                    modifierName: modifierHashTable ? getObjectValue(() => modifierHashTable[Number(currentModifier.split('-')[0])].name, '') : '',
                    modifiers: [],
                    note: '',
                    isLinkedItem: modifierGroupsHashTable && getObjectValue(() => modifierGroupsHashTable[Number(currentModifier.split('-')[1])].linked, false),
                    excludeFromReceipt: modifierHashTable && getObjectValue(() => modifierHashTable[Number(currentModifier.split('-')[0])].excludeFromReceipt, false)
                  });
                }
                updateModifer(
                  nextPath,
                  modiferListToUpdate[isExist !== -1 ? isExist : modiferListToUpdate.length - 1].modifiers,
                  fullPath
                );
              }
            }
          };

          const updateModifer1 = (path) => {
            if (path === '') {
            // return;
            } else {
              const nextPath = path
                .split('|')
                .slice(1)
                .join('|');
              const currentModifier = path.split('|')[0];
              const newMod = [];
              newMod.push({
                modifierId: Number(`${currentModifier.split('-')[0]}${k}`),
                modifierGroupId: Number(currentModifier.split('-')[1]) ? Number(`${currentModifier.split('-')[1]}`) : null,
                quantity: 1,
                weightKey: true,
                weightModifierId: Number(`${currentModifier.split('-')[0]}`),
                price: (modifierGroupsHashTable) ? getObjectValue(() => find(modifierGroupsHashTable[Number(currentModifier.split('-')[1])].modifierLinks, (d) => d.modifierId === Number(Number(currentModifier.split('-')[0]))).price, 0) : 0,
                modifierName: modifierHashTable ? getObjectValue(() => `${modifierHashTable[Number(currentModifier.split('-')[0])].name}`, '') : '',
                modifiers: [],
                note: '',
                isLinkedItem: modifierGroupsHashTable && getObjectValue(() => modifierGroupsHashTable[Number(currentModifier.split('-')[1])].linked, false),
                excludeFromReceipt: modifierHashTable && getObjectValue(() => modifierHashTable[Number(currentModifier.split('-')[0])].excludeFromReceipt, false)
              });
              newMod[0].modifiers.push({
                modifierId: Number(nextPath.split('-')[0]),
                modifierGroupId: Number(nextPath.split('-')[1]) ? Number(nextPath.split('-')[1]) : null,
                quantity: 1,
                price: (modifierGroupsHashTable) ? getObjectValue(() => find(modifierGroupsHashTable[Number(nextPath.split('-')[1])].modifierLinks, (d) => d.modifierId === Number(Number(nextPath.split('-')[0]))).price, 0) : 0,
                modifierName: modifierHashTable ? getObjectValue(() => modifierHashTable[Number(nextPath.split('-')[0])].name, '') : '',
                modifiers: [],
                note: '',
                isLinkedItem: modifierGroupsHashTable && getObjectValue(() => modifierGroupsHashTable[Number(nextPath.split('-')[1])].linked, false),
                excludeFromReceipt: modifierHashTable && getObjectValue(() => modifierHashTable[Number(nextPath.split('-')[0])].excludeFromReceipt, false)
              });
              modifierToSend.push(newMod[0]);
              k += 1;
            // }
            //   updateModifer1(
            //     nextPath,
            //     modiferListToUpdate[isExist !== -1 ? isExist : modiferListToUpdate.length - 1].modifiers,
            //     fullPath
            //   );
            // }
            }
          };
          if (modPath?.includes('|') && modifierGroupsHashTable !== undefined && modifierGroupsHashTable[Number(modPath?.split('|')[0].split('-')[1])].isWeight) {
            if (newArr.length > 0) {
              updateModifer1(modPath, modifierToSend, modPath);
            } else {
              newArr.push(modPath);
              updateModifer(modPath, modifierToSend, modPath);
            }
          } else {
            updateModifer(modPath, modifierToSend, modPath);
          }
        });
      }
      // console.log('llll', modifierToSend);
      // for (let index = 0; index < modifierToSend.length; index += 1) {
      //   const element = modifierToSend[index];
      //   if (`${element.modifierId}`.length > 6) {
      //     element.modifierId = Math.floor(element.modifierId / 10);
      //     console.log('llllll', `${element.modifierId}`.length);
      //   }
      // }
      orders.push({ ...cartItem, price: Number.parseFloat(cartItem.price).toFixed(2), modifiers: modifierToSend });
    });
    return orders;
  }
  orders.push({ modifiers: [] });
  return orders;
};

export const renderExcludeModifier = (modifierGroupLinks, cartItem, modifierGroupsHashTable, modifiers, prefix) => {
  (modifierGroupLinks || []).forEach((mLinks) => {
    (modifierGroupsHashTable[mLinks.modifierGroupId]?.modifierLinks || []).forEach((modifierLinks) => {
      if (modifierLinks?.included) {
        if (modifierLinks.excludeAction === 1 && !find(cartItem.modifiers, (d) => d.startsWith(`${prefix}${modifiers[modifierLinks.modifierId].id}-${mLinks.modifierGroupId}`))) {
          cartItem.modifiers.push(`${prefix}${modifiers[modifierLinks.modifierId].id}-${mLinks.modifierGroupId}|${modifierLinks.excludeModifierId}-0`);
        } else if (modifierLinks.excludeAction === 2 && !find(cartItem.modifiers, (d) => d.startsWith(`${prefix}${modifiers[modifierLinks.modifierId].id}-${mLinks.modifierGroupId}`))) {
          cartItem.modifiers.push(`${prefix}${modifierLinks.excludeModifierId}-0`);
        }
      }
    });
  });
};

export default {
  decriptRespose,
  getObjectValue,
  urlSearchParams,
  getImageUrl,
  formatPhoneNumber,
  formatInputPhoneNumber,
  enforceFormat
};
