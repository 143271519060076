import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleTheme } from '@reducers/theme';
import { getObjectValue } from '../../../utility/utills';
import cn from 'classnames';
import styles from './loader.module.css';

const Loader = (props) => {

  const getTheme = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).serverAppTheme);
  // React Hooks

  useEffect(() => {
    toggleTheme(getTheme);
  },[]);

  // HTML CSS Code
  
  return (
    <div className={cn('mx-sm-0', props.noTransparent ? '' : styles.transparent)}>
      <div className={`row page mx-0 ${props.noTransparent ? '' : 'full-height'} justify-content-center align-items-center`}>
        <div className={cn(styles.imgSpin, 'od-spinner')} />
        {props.message && (
        <div className={cn('od-text-primary fw-600 fs-20 position-fixed', styles.message)} style={{color :  props.serverAppTheme?.primary_text }}>{props.message}</div>
        )}
      </div>
    </div>
  );
};
// export default Loader;

// Redux part
const mapStateToProps = ({theme}) => ({
  serverAppTheme: theme.serverAppTheme
 });
 const mapDispatchToProps = (dispatch) => bindActionCreators({toggleTheme}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Loader);
