import React from 'react';
import { Navigate, Outlet}  from 'react-router-dom';
import { getObjectValue } from '@utility/utills';

const privateRouteForActivation = () => {
    const deviceInfo = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('deviceInfo'), false));
    const storeInfo = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth'), false));
    
    return deviceInfo && storeInfo ? (
        <Outlet/>
    ) : (
        <Navigate to="/activate"/>
    )
}

export default privateRouteForActivation;