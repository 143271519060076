import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import Styles from './screenSaver.module.css';
import BatteryApi from './batteryApi';
import dateTime from './dateTime';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setScreenSaverStatus } from '@reducers/oauth';
import EventEmitter from '@utility/eventSubscribers';
import { flowType } from '../../utility/userInfo';
import { getObjectValue } from '@utility/utills';
import { setNotification } from '@reducers/notification';
// import { useHistory } from 'react-router-dom'

const ScreenSaver = ({ screenSaverStatus, setNotification }) => {
  const OrderUp = JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceTypeId === 8;
  const Battery = new BatteryApi();
  const [showScreenSaver, setShowScreenSaver] = useState(0);
  const [error, setError] = useState(false);
  const [Time, Date] = dateTime(showScreenSaver);
  const [batteryLevel, setBatteryLevel] = useState(0);
  const [chargingStatus, setChargingStatus] = useState(false);
  const screenSaverId = useRef(null);
  const isServerApp = flowType() === 'serverApp';
  // const history = useHistory()
  // const events = ['click', 'dblclick', 'touchstart', 'touchmove', 'touchend', 'touchcancel'];
  const events = ['click', 'dblclick'];
  const successListener = () => {
    Battery.getPrototypes().then((battery) => {
      setShowScreenSaver(true);
      setBatteryLevel(battery.level * 100);
      setChargingStatus(battery.charging);
      battery.addEventListener('levelchange', (e) => setBatteryLevel(e.currentTarget.level * 100), false);
      battery.addEventListener('chargingchange', (e) => setChargingStatus(e.currentTarget.charging), false);
    }).catch(() => {
      setShowScreenSaver(true);
      setError(true);
    });
  };

  const removeListener = () => {
    clearTimeout(screenSaverId.current);
    if (['/checkIn'].includes(window.location.pathname)) {
      if (window.ReactNativeWebView) {
        EventEmitter.enableSideBar(true);
      }
    }
    Battery.getPrototypes().then((battery) => {
      setShowScreenSaver(false);
      setBatteryLevel(battery.level * 100);
      setChargingStatus(battery.charging);
      battery.removeEventListener('levelchange', (e) => setBatteryLevel(e.currentTarget.level * 100), false);
      battery.removeEventListener('chargingchange', (e) => setChargingStatus(e.currentTarget.charging), false);
    }).catch(() => {
      setShowScreenSaver(false);
      setError(true);
    });
  };

  // function toHoursAndMinutes(totalMinutes) {
  //   const hours = Math.floor(totalMinutes / 60);
  //   const minutes = totalMinutes % 60;
  //   return { hours, minutes };
  // }

  // let getRelaunchTime = (storedTime, hoursMinutes) => {
  //   let hours = Number(storedTime[0]) + hoursMinutes.hours;
  //   let value = toHoursAndMinutes(Number(storedTime[1]) + hoursMinutes.minutes)
  //   let minutes;
  //   if (value.hours > 0) {
  //     hours = hours + value.hours;
  //     minutes=value.minutes
  //   } else {
  //     minutes = value.minutes
  //   }
  // return {hours, minutes}
  // }
  
  // useEffect(() => {
  //   history.listen((location) => {
  //     const deviceId = JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceTypeId
  //     if (deviceId === 7) {
  //       let cardReader = window.sessionStorage.getItem('cardReader');
  //       if (cardReader && !cardReader.includes('DEVICE_AVAILABLE') && !cardReader.includes('DEVICE_UNKNOWN')) {
  //         setNotification({
  //           message: "Card Reader Error",
  //           type: 'danger'
  //         });
  //       }
  //     }
  //   })
  // }, [history])

 
  // useEffect(() => {
  //   let relaunchLoad = true;
  //   const reLaunch = setInterval(() => {
  //     // console.log(['/checkIn'].includes(window.location.pathname) , showScreenSaver);
  //     if (window.sessionStorage.getItem('isLoggedInOnce')) {
  //       if (!['/guestinformation'].includes(window.location.pathname) && (['/checkIn'].includes(window.location.pathname) || showScreenSaver)) {
  //         const storeInfo = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth'), false));
  //         const deviceId = JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceTypeId
  //         if (deviceId === 7 && storeInfo?.relaunchEnabled) {
  //           let activatedTime = window.sessionStorage.getItem('deviceActivatedTime');
  //           let a = new window.Date();
  //           let lounch = storeInfo?.relaunchInterval;
  //           let storedTime = activatedTime?.split(":");
  //           let hoursMinutes = toHoursAndMinutes(lounch);
  //           // console.log(storedTime, a.getHours(), getRelaunchTime(storedTime, hoursMinutes).hours,  a.getMinutes() , getRelaunchTime(storedTime, hoursMinutes).minutes);
  //           if (storedTime && a.getHours() > getRelaunchTime(storedTime, hoursMinutes).hours || (a.getHours() === getRelaunchTime(storedTime, hoursMinutes).hours && a.getMinutes() >= getRelaunchTime(storedTime, hoursMinutes).minutes)) {
  //             if (window.ReactNativeWebView && relaunchLoad) {
  //               EventEmitter.reLaunchApp();
  //               relaunchLoad = false;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }, 1000);
  //   return () => clearInterval(reLaunch);
  // }, []);

  

  const startScreenSaver = (val) => {
    screenSaverId.current = setTimeout((count) => {
      if (count === 180) {
        clearTimeout(screenSaverId.current);
        if (window.ReactNativeWebView) {
          EventEmitter.enableSideBar(false);
        }
        successListener();
        return '';
      }
      return startScreenSaver(count + 1);
    }, 1000, val);
  };

  const displayBatterIcon = () => {
    if (chargingStatus) {
      return (
        <div className="position-relative color-success">
          <i className="fas fa-battery-empty fa-rotate-270" />
          <i className={cn('fa fa-bolt fs-10', Styles.charging)} />
        </div>
      );
    }
    if (error) {
      return '';
    }
    switch (true) {
      case batteryLevel === 100: return <i className="fas fa-battery-full color-success fa-rotate-270" />;
      case (batteryLevel < 100 && batteryLevel > 50): return <i className="fas fa-battery-three-quarters color-success fa-rotate-270" />;
      case (batteryLevel === 50): return <i className="fas fa-battery-half color-success fa-rotate-270" />;
      case (batteryLevel < 50 && batteryLevel > 0): return <i className="fas fa-battery-quarter color-warning fa-rotate-270" />;
      case (batteryLevel === 0): return <i className="fas fa-battery-empty color-danger fa-rotate-270" />;
      default: return '';
    }
  };
  useEffect(() => {
    if (screenSaverId.current) {
      removeListener();
    }
    if (screenSaverStatus) {
      startScreenSaver(0);
    }
    events.forEach((event) => {
      document.addEventListener(event, () => {
        if (screenSaverId.current || !screenSaverStatus) {
          removeListener();
        }
        if (screenSaverStatus) {
          startScreenSaver(0);
        }
      });
    });
    return () => {
      if (screenSaverId.current) {
        clearTimeout(screenSaverId.current);
      }
      events.forEach((event) => {
        document.removeEventListener(event, () => {
          if (screenSaverId.current) {
            removeListener();
          }
        });
      });
    };
  }, [screenSaverStatus]);
  return (
    <>
      {showScreenSaver && !OrderUp ? (
        <div
          className={cn('d-flex flex-wrap align-content-center justify-content-center align-items-center fs-40 position-absolute w-100 bg-black color-white h-100', Styles.screenSaver)}
        >
          {/* <div className="fw-600">{Time} &nbsp;</div> */}
          <div className="fs-24">{displayBatterIcon()}</div>
          {batteryLevel ? <div className="fs-20">{Number(batteryLevel).toFixed(0)}%</div> : ''}
          {/* <div className="w-100 text-center">{Date}</div> */}
        </div>
      ) : ''}
    </>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setScreenSaverStatus,
  setNotification,
}, dispatch);

const mapStateToProps = ({ oauth }) => ({
  loading: oauth.loading,
  screenSaverStatus: oauth.screenSaverStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSaver);
