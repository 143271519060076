import React, { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Notification from '@components/common/notification';
import ErrorPopUp from '@components/common/ErrorPopUp';
import ScreenSaver from '@containers/screenSaver';
import PageLoader from '@components/common/PageLoader';
import withRouter from './withRouter';

const VirtualKeyboard = withRouter(lazy(
    () => import('@formElements/SimpleKeyboard/virtualKeyboard')
))

const OnRouteChange = withRouter(lazy(
    () => import('@containers/common/OnRouteChange')
))

const LoadingComponent = ({ isLoading, isError }) => {
    if (isLoading) {
        return <PageLoader />;
    }
    if (isError) {
        return (
            <div>
                Sorry, unable to load the page
            </div>
        );
    }
    return null;
};

const RootLayout = () => {
    return (
        <>
            <div className='h-100'>
                <Suspense fallback={<LoadingComponent />}>
                    <Notification />
                    <ScreenSaver />
                    <OnRouteChange>
                        <Outlet />
                    </OnRouteChange>
                    <VirtualKeyboard />
                    <ErrorPopUp />
                </Suspense>
            </div>
        </>
    )
}

export default RootLayout;