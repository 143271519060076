import { filter, uniqBy } from 'lodash';
import { getHeaders } from '@utility/helperFunction';

export const GET_STORE_NOTIFICATIONS_REQUESTED = 'storeNotification/GET_STORE_NOTIFICATIONS_REQUESTED';
export const GET_STORE_NOTIFICATIONS_SUCCESS = 'storeNotification/GET_STORE_NOTIFICATIONS_SUCCESS';
export const UPDATE_STORE_NOTIFICATIONS = 'storeNotification/UPDATE_STORE_NOTIFICATIONS';
export const GET_STORE_NOTIFICATIONS_FAILURE = 'storeNotification/GET_STORE_NOTIFICATIONS_FAILURE';
export const REMOVE_STORE_NOTIFICATION = 'storeNotification/REMOVE_STORE_NOTIFICATION';

export const ACKNOWLEDGE_REQUESTED = 'storeNotification/ACKNOWLEDGE_REQUESTED';
export const ACKNOWLEDGE_SUCCESS = 'storeNotification/ACKNOWLEDGE_SUCCESS';
export const ACKNOWLEDGE_FAILURE = 'storeNotification/ACKNOWLEDGE_FAILURE';

export const CLEAR_NOTIFICATION = 'storeNotification/CLEAR_NOTIFICATION';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  acknowledged: false,
  storeNotification: [],
  notificationTableData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_NOTIFICATIONS_REQUESTED: {
      action.resolve({ loading: true });
      return {
        ...state,
        loading: true,
        loaded: false,
        storeNotification: []
      };
    }
    case GET_STORE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        storeNotification: uniqBy(action.payload, 'id'),
        notificationTableData: action.payload
      };
    }
    case UPDATE_STORE_NOTIFICATIONS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        storeNotification: uniqBy([...state.storeNotification, action.payload], 'id'),
        notificationTableData: action.payload
      };
    }
    case GET_STORE_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }

    case REMOVE_STORE_NOTIFICATION: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        storeNotification: filter(state.storeNotification, (item) => item.id !== action.payload)
      };
    }

    case CLEAR_NOTIFICATION: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        storeNotification: []
      };
    }

    case ACKNOWLEDGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }

    case ACKNOWLEDGE_SUCCESS: {
      return {
        ...state,
        acknowledged: action.result,
        loading: false,
        loaded: true,
        error: false
      };
    }

    case ACKNOWLEDGE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const dispatchStoreNotificationRequested = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: GET_STORE_NOTIFICATIONS_REQUESTED,
        resolve
      });
    });
  };
};

export const dispatchStoreNotificationSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_STORE_NOTIFICATIONS_SUCCESS,
      payload: data
    });
  };
};

export const dispatchStoreNotificationFailure = () => {
  return (dispatch) => {
    dispatch({
      type: GET_STORE_NOTIFICATIONS_FAILURE
    });
  };
};

export const removeStoreNotification = (id) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_STORE_NOTIFICATION,
      payload: id
    });
  };
};

export const acknowledge = (data) => {
  const headers = getHeaders();
  return {
    types: [ACKNOWLEDGE_REQUESTED, ACKNOWLEDGE_SUCCESS, ACKNOWLEDGE_FAILURE],
    promise: (client) => client.post('acknowledge', {
      data,
      headers
    })
  };
};
