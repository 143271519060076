import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client'; 
// import { connect as socketConnector } from './sockets/communicator';
// import { ConnectedRouter } from 'react-router-redux';
import './global.css'
import store from './store';
import routes from './routes';
// import { subscribe } from './CApiWebSocket/Subscriber';

import './include/bootstrap';

const target = document.querySelector('#root');
target.style.height = '100%';

// const connectionOptions = {
//   reconnectionDelay: 500,
//   reconnectionAttempts: Infinity,
// };

// socketConnector({
//   // url: 'https://capi.dev.onedine.com:8443' ? 'https://capi.dev.onedine.com:8443'
//   //   : process.env.REACT_APP_API_SOCKET_HOST || 'https://yapi.dev.onedine.com/iapi/connect',
//   url: process.env.REACT_APP_API_SOCKET_HOST || 'https://yapi.dev.onedine.com/iapi/connect',
//   connectCallback: () => {
//     console.warn('websocket connection successful');
//   },
//   disconnectCallback: () => {
//     console.warn('websocket connection unsuccessful');
//   },
//   connectionOptions,
//   apiKey: 'c0ded6ca39ba4694b56fd553f7304cd3',
//   deviceExternalId: '6839dbbfbd6b440896b09c66b2a895ea',
//   subscriber: (data) => { subscribe(data); }
// });
const root = createRoot(target)
root.render(
  <Provider store={store}>
    {routes}
  </Provider>
);
