export const getTime = (dateObj) => {
  const date = dateObj.split(':');
  return new Date().setHours(Number(date[0]), Number(date[1]), Number(date[2]) || 0);
};

export const getDate = (dateObj) => {
  const date = new Date(dateObj);
  return date;
};

export const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const truncate = (n, len) => {
  const ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
  let filename = n.replace(`.${ext}`, '');
  if (filename.length <= len) {
    return n;
  }
  filename = filename.substr(0, len) + (n.length > len ? '[...]' : '');
  return `${filename}.${ext}`;
};

export const getObjectValue = (cb, defaultValue) => {
  try {
    return cb();
  } catch (e) {
    // console.log(e);
    return defaultValue;
  }
};

export const playRingtone = (ringtone) => {
  const audioElement = new Audio(ringtone);
  audioElement.loop = false;
  audioElement.currentTime = 0;
  audioElement.play();
};

export const getDeviceUid = async () => {
  // const navigatorInfo = window.navigator;
  // const screenInfo = window.screen;
  // let uid = navigatorInfo.mimeTypes.length;
  // const userAgentId = navigatorInfo.userAgent.replace(/\D+/g, '');
  // uid += navigatorInfo.plugins.length;
  // const screenId = `${screenInfo.height || ''}${screenInfo.width || ''}${screenInfo.pixelDepth || ''}`;
  // const url = 'https://api.ipify.org/';
  // const request = await superagent.get(url);
  // const IpAddress = request && request.text.split('.').join('');
  // const uid = `${userAgentId}${screenId}${IpAddress}`;

  // 1st avenue server 03305e2a-c42e-11eb-8529-0242ac130012
  // a-lab server 03305e2a-c42e-11eb-8529-0242ac130018
  // a-lab tableTop 03305e2a-c42e-11eb-8529-0242ac130014
  // p-lab tableTop 03305e2a-c42e-11eb-8529-0242ac130007
  // p-lab server 03305e2a-c42e-11eb-8529-0242ac130008
  // uno-lab server 03305e2a-c42e-11eb-8529-0242ac130006
  // kiosk a-lab 03305e2a-c42e-11eb-8529-0242ac130002
  // kiosk p-lab 03305e2a-c42e-11eb-8529-0242ac130003
  return '03305e2a-c42e-11eb-8529-0242ac130003';
};

export const converTime = (time) => {
  const a = new Date(time);
  const b = new Date();
  let difference = (b - a) / 1000;
  let dataRequired = `${difference.toFixed(0)}s`;
  if (difference < 0) {
    dataRequired = '0s' || `${difference.toFixed(0)}s`;
  } else if (difference > 3600) {
    difference /= (60 * 60);
    difference = Math.abs(Math.round(difference));
    dataRequired = `${difference}h+`;
  } else if (difference > 60) {
    difference = Math.floor(difference / 60);
    dataRequired = `${difference}m+`;
  }
  return time ? dataRequired : '';
};

export const getFormatedPhoneNumber = (inputVal) => {
  const cleaned = (`${inputVal}`).replace(/\D/g, '');

  const normValue = `${cleaned.substring(0, 3)}${
    cleaned.length > 3 ? '-' : ''
  }${cleaned.substring(3, 6)}${
    cleaned.length > 6 ? '-' : ''
  }${cleaned.substring(6, 11)}`;
  return normValue;
};

export const convertDateToString = (d) => {
  const [hours, minutes, seconds] = d.split(':');
  const [month, currentDate, year] = [new Date().getMonth(), new Date().getDate(), new Date().getFullYear()];
  const date = new Date(year, month, currentDate, hours, minutes, seconds);
  return date;
};

export const createFilter = (key, headerData, data) => {
  return headerData && headerData.some((d) => {
    return data[d.key] && data[d.key].toString().toLowerCase().includes(key.toString().toLowerCase());
  });
};

export const sortTable = (sortColumn, data, sortBy) => {
  if (data.length === 1) {
    return data;
  }

  if (sortColumn === '' && sortBy) {
    return data;
  }
  const regxAlpha = /[^a-zA-Z]/g;
  const regxNumb = /[^0-9]/g;

  const sorted = data.sort((a, b) => {
    try {
      const aAlpha = a[sortColumn] && typeof a[sortColumn] !== 'number' && a[sortColumn].replace(regxAlpha, '');
      const bAlpha = b[sortColumn] && typeof b[sortColumn] !== 'number' && b[sortColumn].replace(regxAlpha, '');
      const aNum = a[sortColumn] && typeof a[sortColumn] !== 'number' ? parseInt(a[sortColumn].replace(regxNumb, ''), 10) : a[sortColumn];
      const bNum = b[sortColumn] && typeof b[sortColumn] !== 'number' ? parseInt(b[sortColumn].replace(regxNumb, ''), 10) : b[sortColumn];
      if (aNum === bNum) {
        return 0;
      }
      if (sortBy === 'asc' && aAlpha === bAlpha) {
        return aNum > bNum ? 1 : -1;
      }
      if (sortBy === 'desc' && aAlpha === bAlpha) {
        return aNum < bNum ? 1 : -1;
      }
      if (aAlpha > bAlpha && sortBy === 'asc') {
        return 1;
      }
      if (sortBy === 'desc' && aAlpha < bAlpha) {
        return 1;
      }
    } catch (e) {
      console.log(e);
    }
    return -1;
  });
  return sorted;
};

export const sort = (array, key) => {
  return array.sort((a, b) => { return a[key] - b[key]; });
};

export const getHeaders = () => {
  const header = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('headers')), {});
  return {
    storeId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('deviceInfo')).storeId, null),
    employeeId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('userInfo')).id, null),
    deviceId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('deviceInfo')).deviceId, null),
    checkHeaderId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('seat')).id, null),
    brandId: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).brandId, null),
    employeeCard: getObjectValue(() => JSON.parse(window.sessionStorage.getItem('userInfo')).employeeCard, null),
    ...header
  };
};

export const getSearchArray = (array, key, value) => {
  const item = [];
  for (const index = 0; index < array.length; index + 1) {
    if (array[index][key].startsWith(value)) {
      item.push(array[index]);
    }
  }
  return item;
};

export const oneDineFiltersList = [
  { name: 'pos', sourceType: 0 },
  { name: 'table', sourceType: 0 },
  { name: 'tab', sourceType: 0 },
  { name: 'kiosk', sourceType: 1 },
  { name: 'tableTop', sourceType: 2 },
  { name: 'orderPay', sourceType: 3 },
  { name: 'giftCard', sourceType: 4 },
  { name: 'curbside', sourceType: 5 },
  { name: 'online', sourceType: 6 },
  { name: 'walkUp', sourceType: 7 },
  { name: 'callIn', sourceType: 8 },
  { name: 'scanPay', sourceType: 9 },
  { name: 'driveThru', sourceType: 10 },
  { name: 'textToPay', sourceType: [6, 7, 8] }
];

export const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = 'id_';
  for (let i = 0; i < length - 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};