export const GET_GOOGLE_FONTS_REQUESTED = 'google/GOOGLE_FONTS_REQUESTED';
export const GET_GOOGLE_FONTS_SUCCESS = 'google/GOOGLE_FONTS_SUCCESS';
export const GET_GOOGLE_FONTS_FAILURE = 'google/GOOGLE_FONTS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  fonts: [],
  hasMore: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GOOGLE_FONTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_GOOGLE_FONTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        fonts: JSON.parse(action.result.fonts),
        hasMore: action.result.hasMore
      };
    }
    case GET_GOOGLE_FONTS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    default:
      return state;
  }
};

export const getAllFonts = (pageNo = 0, itemsPerPage = 10, searchTerm = '') => {
  const params = {
    pageNo,
    itemsPerPage,
    searchTerm
  };

  return {
    types: [GET_GOOGLE_FONTS_REQUESTED, GET_GOOGLE_FONTS_SUCCESS, GET_GOOGLE_FONTS_FAILURE],
    promise: (client) => client.get('fonts', { params })
  };
};
