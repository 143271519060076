export default {
  LOGIN_SUCCESS: 'Successfully Logged In',
  PASSWORD_MISMATCH: "Password and Confirm Password doesn't match!",
  OTP_SUCCESS: 'Code Sent successfully to your registered Mobile Number',

  // Notification_message
  SERVER_CALL: 'Notification sent',
  SERVER_SUCCESS_REQUEST: 'Server has received the request',
  MAIL_SENT_SUCCESS: 'Mail has been successfully sent',
  BASIC_UPDATE: 'Basic details successfully updated',
  NEW_PASSWORD_UPDATE: 'New Password successfully updated',
  MESSAGE_SENT: 'Message has been successfully sent',
  LOGIN_PASSWORD: 'please login with your new password',
  OTP_SENT_TO_MOBILE: 'A verification code has been sent to your mobile number.',
  SURVEY_SAVE: 'Thank you, survey has saved successfully',
  TABLE_UPDATE: 'Table Inforamtion updated successfully',
  LEAVE_TABLE: 'Leave Table request accepted successfully',
  NEW_CARD_ADDED: 'New card has been added successfully',
  CARD_DELETED_SUCCESSFULLY: 'Card has been deleted successfully',
  CARD_UPDATED_SUCCESSFULLY: 'Card has been updated successfully',
  SMS_CODE: 'Successfully verified your SMS code',
  LOYALTY_ACCOUNT_UPDATED_SUCCESSFULLY: 'Loyalty account has been updated successfully',
  LOYALTY_ACCOUNT_DELETED_SUCCESSFULLY: 'Loyalty account has been deleted successfully',
  MEMBER_ADDRESS_UPDATED_SUCCESSFULLY: 'Member address has been updated successfully',
  MEMBER_ADDRESS_ADDED_SUCCESSFULLY: 'Member address has been added successfully',
  MEMBER_ADDRESS_DELETED_SUCCESSFULLY: 'Member address has been deleted successfully',

  SUCCESSFULLY_SYNCED_CHECK: 'Successfully synced check',
  CREATE_TABLE_CHECK_SUCCESS: 'Successfully created new table check',
  CREATE_TAB_CHECK_SUCCESS: 'Successfully created new tab check',
  SINGLE_DELETE_CHECK_SUCCESS: 'Successfully deleted check item',
  MULTIPLE_DELETE_CHECK_SUCCESS: 'Successfully deleted check items',
  MULTIPLE_SENT_CHECK_SUCCESS: 'Successfully sent check items',
  SINGLE_SENT_CHECK_SUCCESS: 'Successfully sent check item',
  MOVE_CHECK_SUCCESS: 'Successfully moved check item',
  VOID_CHECK_SUCCESS: 'Successfully voided check item',
  CLEAR_SEAT_SUCCESS: 'Successfully clear seat',
  ITEM_DELETE_SUCCESS: 'Item deleted Successfully',
  ITEM_DELETE_SUCCESS_MULTIPLE: 'Items deleted Successfully',
  ITEM_DELETE_FAIL: 'Deletion Unsuccessful',
  MOVE_UNCHECK_SUCCESS: 'Successfully moved uncheck item',
  SENT_ITEM_SUCCESS: 'Sent the item successfully',
  SENT_ITEM_SUCCESS_MULTIPLE: 'Items Sent successfully',
  NEW_SEAT_CRESTION_SUCCESS: 'Successfully created new seat',
  CREATE_STAGE_SUCCESS: 'Successfully Staged Seats',
  RENAME_STAGE_SUCCESS: 'Stage name updated Successfully',
  DELETE_STAGE_SUCCESS: 'Successfully cleared Stage',
  TRANSFER_SUCCESS: 'Successfully transferred check',
  CREATE_CHECK_SUCCESS: 'Successfully created check',
  DELETED_LOYALTY_ACCOUNT: 'Successfully deleted loyalty account',
  CHANGED_LANGUAGE: 'Successfully changed language to',
  CONFIRM_ID_MESSAGE: 'Successfully confirmed check',
  SUCCESS_MESSAGE: 'Success',
  PRINT_SUCCESS: 'Print Successful',
  SEND_QR_SUCCESS: 'SMS Sent Successfully',
  MERGE_CHECKS_SUCCESS: 'Checks Merged Successfully',
  LOYALTY_ACCOUNT_FOUND :'Loyalty account found',
};
