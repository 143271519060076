import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '@components/common/Loader';
import { toggleTheme } from '@reducers/theme';
import { getObjectValue } from '../../../utility/utills';
import cn from 'classnames';
import styles from './modal.module.css';

const Modal = (props) => {

  // Props Destructuring

  const {
    isOpen, children, customClass, customClass2, customClass3, loading, isModalCenter, noBackground, style, customWidth, serverAppTheme,
    // editCallCenter 
  } = props

  const getTheme = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).serverAppTheme); 

  // React Hooks
  useEffect(() => {
    toggleTheme(getTheme);
  },[]);

  // HTML CSS CODE
  return (
    <div className="h-auto">
      {loading
      && <div><Loader /></div>}
      <>
        <div className={cn('modal', isOpen ? 'fade show d-block' : 'fade', customClass, styles.popupFlow,)}style={{background: serverAppTheme?.modal_bg}}>
          <div className={cn('h-100', customWidth? styles.customWidth : styles.modalDialog, isModalCenter ? styles.modalDialogCentered : styles.modalDialogTop, customClass2)} role="document">
            <div className={cn(noBackground ? 'modal-content od-text-primary text-primary' : 'modal-content od-app-background-color background-primary od-text-primary text-primary', customClass3)} style={style}>
              {children}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

// Redux Part
const mapStateToProps = ({theme}) => ({
  serverAppTheme: theme.serverAppTheme
 });
 const mapDispatchToProps = (dispatch) => bindActionCreators({toggleTheme}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
