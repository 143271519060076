export const CREATE_PROFILE_REQUESTED = 'signup/CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_SUCCESS = 'signup/CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_FAILURE = 'signup/CREATE_PROFILE_FAILURE';

export const PHONE_NUM_REQUESTED = 'signup/PHONE_NUM_REQUEST';
export const PHONE_NUM_SUCCESS = 'signup/PHONE_NUM_SUCCESS';
export const PHONE_NUM_FAILURE = 'signup/PHONE_NUM_FAILURE';

export const GENERATE_USERNAME_REQUESTED = 'signup/GENERATE_USERNAME_REQUEST';
export const GENERATE_USERNAME_SUCCESS = 'signup/GENERATE_USERNAME_SUCCESS';
export const GENERATE_USERNAME_FAILURE = 'signup/GENERATE_USERNAME_FAILURE';

export const CHECK_USERNAME_REQUESTED = 'signup/CHECK_USERNAME_REQUEST';
export const CHECK_USERNAME_SUCCESS = 'signup/CHECK_USERNAME_SUCCESS';
export const CHECK_USERNAME_FAILURE = 'signup/CHECK_USERNAME_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROFILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CREATE_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CREATE_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case PHONE_NUM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PHONE_NUM_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case PHONE_NUM_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GENERATE_USERNAME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GENERATE_USERNAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GENERATE_USERNAME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CHECK_USERNAME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CHECK_USERNAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CHECK_USERNAME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const createProfile = (data) => {
  return {
    types: [CREATE_PROFILE_REQUESTED, CREATE_PROFILE_SUCCESS, CREATE_PROFILE_FAILURE],
    promise: (client) => client.post('create_profile', {
      data
    })
  };
};

export const verifyPhone = (data) => {
  return {
    types: [PHONE_NUM_REQUESTED, PHONE_NUM_SUCCESS, PHONE_NUM_FAILURE],
    promise: (client) => client.post('sendCode', {
      data
    })
  };
};

export const verifyCode = (data) => {
  return {
    types: [PHONE_NUM_REQUESTED, PHONE_NUM_SUCCESS, PHONE_NUM_FAILURE],
    promise: (client) => client.post('verifyCode', {
      data
    })
  };
};

export const generateUserName = (firstName, lastName) => {
  return {
    types: [GENERATE_USERNAME_REQUESTED, GENERATE_USERNAME_SUCCESS, GENERATE_USERNAME_FAILURE],
    promise: (client) => client.get(`generateUserName?firstName=${firstName}&lastName=${lastName}`)
  };
};

export const checkUniqueUserName = (userName) => {
  return {
    types: [GENERATE_USERNAME_REQUESTED, GENERATE_USERNAME_SUCCESS, GENERATE_USERNAME_FAILURE],
    promise: (client) => client.get(`checkUniqueUserName?username=${userName}`)
  };
};
