export const POST_MY_DATA_REQUEST = 'myData/POST_MY_DATA_REQUEST';
export const POST_MY_DATA_SUCCESS = 'myData/POST_MY_DATA_SUCCESS';
export const POST_MY_DATA_FAILURE = 'myData/POST_MY_DATA_FAILURE';

const initailValues = {
  loading: false,
  loaded: true,
  error: false,
};

export default (state = initailValues, action) => {
  switch (action.type) {
    case POST_MY_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case POST_MY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case POST_MY_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false
      };
    default:
      return state;
  }
};

export const postMyData = (data) => {
  return {
    types: [POST_MY_DATA_REQUEST, POST_MY_DATA_SUCCESS, POST_MY_DATA_FAILURE],
    promise: (client) => client.post('postMyData', { data })
  };
};
