import { getHeaders } from '@utility/helperFunction';

export const EMPLOYEE_TIPS_REQUESTED = 'oauth/EMPLOYEE_TIPS_REQUESTED';
export const EMPLOYEE_TIPS_SUCCESS = 'oauth/EMPLOYEE_TIPS_SUCCESS';
export const EMPLOYEE_TIPS_FAILURE = 'oauth/EMPLOYEE_TIPS_FAILURE';

export const DEVICE_ACTIVATE_REQUESTED = 'oauth/DEVICE_ACTIVATE_REQUESTED';
export const DEVICE_ACTIVATE_SUCCESS = 'oauth/DEVICE_ACTIVATE_SUCCESS';
export const DEVICE_ACTIVATE_FAILURE = 'oauth/DEVICE_ACTIVATE_FAILURE';

export const LOG_CAPI_CLIENT_HEALTH_INFO_REQUESTED = 'LOG_CAPI_CLIENT_HEALTH_INFO_REQUESTED';
export const LOG_CAPI_CLIENT_HEALTH_INFO_SUCCESS = 'LOG_CAPI_CLIENT_HEALTH_INFO_SUCCESS';
export const LOG_CAPI_CLIENT_HEALTH_INFO_FAILURE = 'LOG_CAPI_CLIENT_HEALTH_INFO_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  employeeTipsData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_TIPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case EMPLOYEE_TIPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        employeeTipsData: action.result,
      };
    }
    case EMPLOYEE_TIPS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        employeeTipsData: null,
      };
    }
    case DEVICE_ACTIVATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DEVICE_ACTIVATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DEVICE_ACTIVATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOG_CAPI_CLIENT_HEALTH_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case LOG_CAPI_CLIENT_HEALTH_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case LOG_CAPI_CLIENT_HEALTH_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const employeeTips = (data) => {
  const headers = getHeaders();
  return {
    types: [EMPLOYEE_TIPS_REQUESTED, EMPLOYEE_TIPS_SUCCESS, EMPLOYEE_TIPS_FAILURE],
    promise: (client) => client.post('employeeTips', {
      data,
      headers
    })
  };
};

export const deviceActivate = (data) => {
  const headers = getHeaders();
  return {
    types: [DEVICE_ACTIVATE_REQUESTED, DEVICE_ACTIVATE_SUCCESS, DEVICE_ACTIVATE_FAILURE],
    promise: (client) => client.post('deviceActivate', {
      data,
      headers
    })
  };
};

export const logCapiClientHealthInfo = (data) => {
  const headers = getHeaders();
  return {
    types: [LOG_CAPI_CLIENT_HEALTH_INFO_REQUESTED, LOG_CAPI_CLIENT_HEALTH_INFO_SUCCESS, LOG_CAPI_CLIENT_HEALTH_INFO_FAILURE],
    promise: (client) => client.post('logCapiClientHealthInfo', {
      data,
      headers
    })
  };
};
