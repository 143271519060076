export const setItem = (itemName, value) => {
  window.localStorage.setItem(itemName, JSON.stringify(value));
};

export const getItem = (itemName, deafultReturnValue) => {
  try {
    return JSON.parse(window.localStorage.getItem(itemName)) || deafultReturnValue;
  } catch (e) {
    return deafultReturnValue;
  }
};

export const removeItem = (itemName) => {
  window.localStorage.removeItem(itemName);
};

export default {
  setItem,
  getItem,
  removeItem
};
