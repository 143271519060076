import { getHeaders, oneDineFiltersList } from '@utility/helperFunction';
import { groupBy, orderBy } from 'lodash';
import moment from 'moment';

export const GET_CUBBY_TILES_SUCCESS = 'cubby/GET_CUBBY_TILES_SUCCESS';
export const GET_CUBBY_TILES_REQUESTED = 'cubby/GET_CUBBY_TILES_REQUESTED';
export const GET_CUBBY_TILES_FAILURE = 'cubby/GET_CUBBY_TILES_FAILURE';

export const GET_CUBBY_LAYOULT_SUCCESS = 'cubby/GET_CUBBY_LAYOULT_SUCCESS';
export const GET_CUBBY_LAYOULT_REQUESTED = 'cubby/GET_CUBBY_LAYOULT_REQUESTED';
export const GET_CUBBY_LAYOULT_FAILURE = 'cubby/GET_CUBBY_LAYOULT_FAILURE';

export const GET_CHECK_LIST_REQUEST = 'cubby/GET_CHECK_LIST_REQUEST';
export const GET_CHECK_LIST_SUCCESS = 'cubby/GET_CHECK_LIST_SUCCESS';

export const UPDATE_LIST_OF_COLORS = 'cubby/UPDATE_LIST_OF_COLORS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  cubbyTilesList: [],
  cubbyLayoult: null,
  listOfColors: {},
  cubbyChecksList: [],
  cubbyLayoultHashTable: {}
};

export const getCubbyDisplayColor = (slotAssignmentDate, data) => {
  const momentTime = moment.utc(slotAssignmentDate).local();
  const currentTime = moment();
  const diffTime = +(Math.abs(currentTime.diff(momentTime, 'seconds', true))).toFixed(2);
  const cubbyWarningTime = data?.cubbyWarningTime ? +(data?.cubbyWarningTime).toFixed(2) : false;
  const cubbyErrorTime = data?.cubbyErrorTime ? +(data?.cubbyErrorTime).toFixed(2) : false;
  const cubbyClearTime = data?.cubbyClearTime ? +(data?.cubbyClearTime).toFixed(2) : false;
  let color = 'bg-white';
  if (data) {
    if (cubbyWarningTime && cubbyErrorTime && (diffTime >= cubbyWarningTime) && (diffTime <= cubbyErrorTime)) {
      color = 'bg-orange';
    }
    if (cubbyErrorTime && cubbyClearTime && (diffTime >= cubbyErrorTime) && (diffTime <= cubbyClearTime)) {
      color = 'bg-red';
    }
    if (cubbyClearTime && (diffTime >= cubbyClearTime)) {
      color = false;
    }
  }
  return color;
};

const cubbyList = (checkListHashTable, deviceInfo, cubbyLayoultList) => {
  let groupedCubbyLayoults = [];
  let slots = [];
  let list = Object.values(checkListHashTable || {});
  const listColors = {};
  list = list?.reduce((a, v) => {
    const storeCubbySlotId = v?.orders[0]?.storeCubbySlotId;
    const color = getCubbyDisplayColor(v?.orders[0]?.slotAssignmentDate || v?.orders[0]?.pickupDate || v?.orders[0]?.orderDate, deviceInfo?.deviceGroup);
    if (storeCubbySlotId && color) {
      a.push({
        ...v,
        color
      });
    }
    return a;
  }, []);
  if (deviceInfo?.deviceGroup) {
    if (Object.values(deviceInfo?.deviceGroup?.checkChannelFilters).filter((e) => e).length > 0) {
      const oneDineFilter = oneDineFiltersList.reduce((a, v) => {
        if (deviceInfo?.deviceGroup?.checkChannelFilters[v.name]) {
          if (typeof v.sourceType === 'object') {
            a.push(...v.sourceType.filter((d) => !a.includes(d)));
          } else if (!a.includes(v.sourceType)) {
            a.push(v.sourceType);
          }
        }
        return a;
      }, []);
      list = list.filter((e) => oneDineFilter.includes(e.sourceType));
    }
    if (deviceInfo?.deviceGroup?.checkRevenueCenterFilters?.length > 0) {
      list = list.filter((e) => deviceInfo?.deviceGroup?.checkRevenueCenterFilters.includes(e.revenueCenterExternalId));
    }
    if (deviceInfo?.deviceGroup?.checkOrderTypeFilters?.length > 0) {
      list = list.filter((e) => deviceInfo?.deviceGroup?.checkOrderTypeFilters.includes(e.orderTypeExternalId));
    }
  }
  if (list && cubbyLayoultList?.slots) {
    const sortedList = orderBy(list, [(d) => (d?.orders[0]?.slotAssignmentDate || d?.orders[0]?.pickupDate || d?.orders[0]?.orderDate)], ['desc']);
    const listHashTable = groupBy(sortedList, (d) => d?.orders[0]?.storeCubbySlotId);
    slots = cubbyLayoultList?.slots.reduce((a, slot) => {
      const data = listHashTable[slot?.id] && listHashTable[slot?.id].length > 0 ? listHashTable[slot?.id][0] : false;
      if (data) {
        a.push({
          ...slot,
          check: data
        });
      } else {
        a.push({
          ...slot,
          check: null
        });
      }
      listColors[slot.id] = {
        id: data.id,
        name: data.stationName,
        color: data.color || 'bg-white'
      };
      return a;
    }, []);
    groupedCubbyLayoults = Object.values(groupBy(slots, (d) => d.rowNumber));
  }
  return [groupedCubbyLayoults, listColors];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUBBY_TILES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CUBBY_TILES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        cubbyTilesList: action.result
      };
    }
    case GET_CUBBY_TILES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CUBBY_LAYOULT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_CUBBY_LAYOULT_SUCCESS: {
      const list = {};
      action.result.slots.forEach((v) => {
        list[v.id] = {
          ...v
        };
      });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        cubbyLayoult: action.result,
        cubbyLayoultHashTable: list
      };
    }
    case GET_CUBBY_LAYOULT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CHECK_LIST_REQUEST: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case GET_CHECK_LIST_SUCCESS: {
      const [groupedCubbyLayoults, listColors] = cubbyList(action.payload.checkListHashTable, action.payload.deviceInfo, state.cubbyLayoult);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        cubbyChecksList: groupedCubbyLayoults,
        listOfColors: listColors
      };
    }
    case UPDATE_LIST_OF_COLORS: {
      const {
        id,
        parentIndex,
        childIndex,
        color
      } = action.payload;
      const listColors = { ...state.listOfColors };
      listColors[id] = {
        ...listColors[id],
        color: color || 'bg-white'
      };
      const updatedCubbyList = [...state.cubbyChecksList];
      if (!color) {
        updatedCubbyList[parentIndex][childIndex].check = null;
      } else {
        updatedCubbyList[parentIndex][childIndex].check.color = color || 'bg-white';
      }
      return {
        ...state,
        cubbyChecksList: updatedCubbyList,
        listOfColors: listColors
      };
    }
    default:
      return state;
  }
};

export const getCubbyTiles = (storeId) => {
  const headers = getHeaders();
  return {
    types: [GET_CUBBY_TILES_REQUESTED, GET_CUBBY_TILES_SUCCESS, GET_CUBBY_TILES_FAILURE],
    promise: (client) => client.get(`getCubbyTiles/${storeId}`, {
      headers,
    })
  };
};

export const getCubbyLayoult = (storeId) => {
  const headers = getHeaders();
  return {
    types: [GET_CUBBY_LAYOULT_REQUESTED, GET_CUBBY_LAYOULT_SUCCESS, GET_CUBBY_LAYOULT_FAILURE],
    promise: (client) => client.get(`getCubbyLayoult/${storeId}`, {
      headers,
    })
  };
};

export const getChecksList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_CHECK_LIST_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: GET_CHECK_LIST_SUCCESS,
        payload
      });
    }, 100);
  };
};

export const updateListOfColors = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LIST_OF_COLORS,
      payload
    });
  };
};
