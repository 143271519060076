export const HEADER_TOGGLE_NAV = 'header/HEADER_TOGGLE_NAV';
export const SET_HEADER_INFO = 'header/SET_HEADER_INFO';
export const SET_HEADER_REQUEST = 'header/SET_HEADER_REQUEST';
export const SET_HEADER_WITHOUT_LOADING_REQUEST = 'header/SET_HEADER_WITHOUT_LOADING_REQUEST';
export const TOGGLE_RESTAURANT_BAR = 'header/TOGGLE_RESTAURANT_BAR';
export const TOGGLE_CLOSE_ON_PAGE = 'header/TOGGLE_CLOSE_ON_PAGE';
export const CLOSE_HEADER_TOGGLE_NAV = 'header/CLOSE_HEADER_TOGGLE_NAV';

const SET_URL_REQUESTED = 'header/SET_URL_REQUESTED';
const SET_URL_SUCCESS = 'header/SET_URL_SUCCESS';

export const TOGGLE_MALL_STORE = 'header/TOGGLE_MALL_STORE';
export const SET_MENU_LABEL = 'header/SET_MENU_LABEL';

const initialValues = {
  urlData: JSON.parse(window.sessionStorage.getItem('urlData')) || [],
  isNavOpen: null,
  tempLoad: false,
  headerBar: {
    show: false,
    style: '',
    tittle: ''
  },
  menu: {
    type: '',
    controlUrl: ''
  },
  closeButton: {
    type: '',
    controlUrl: '',
    cartValue: '',
  },
  restaurantInfo: {
    show: false
  },
  loading: false,
  cancelOnPage: false,
  action: { type: '', isOpen: false },
  menuLabel: '',
  footerItemsType: JSON.parse(window.localStorage.getItem('footerItemsType')) ? JSON.parse(window.localStorage.getItem('footerItemsType')) : 0
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case HEADER_TOGGLE_NAV:
      return {
        ...state,
        cancelOnPage: false,
        isNavOpen: !state.isNavOpen
      };
    case CLOSE_HEADER_TOGGLE_NAV:
      return {
        ...state,
        cancelOnPage: false,
        isNavOpen: false
      };
    case SET_HEADER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_HEADER_WITHOUT_LOADING_REQUEST:
      return {
        ...state,
        loading: false
      };
    case SET_HEADER_INFO:
      if (action.payload.footerItemsType !== undefined) {
        window.localStorage.setItem('footerItemsType', action.payload.footerItemsType);
      }
      return {
        ...state,
        loading: false,
        cancelOnPage: false,
        tempLoad: !state.tempLoad,
        ...action.payload
      };
    case TOGGLE_RESTAURANT_BAR:
      return {
        ...state,
        isRestaurantBarOpen: !state.isRestaurantBarOpen
      };
    case TOGGLE_CLOSE_ON_PAGE:
      return {
        ...state,
        cancelOnPage: action.payload
      };
    case SET_URL_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case SET_URL_SUCCESS: {
      // const isUserLogin = window.localStorage.getItem('isUserLogin') || null;
      window.sessionStorage.setItem('urlData', JSON.stringify([state.urlData[state.urlData.length - 1], action.payload]));
      action.resolve();
      return {
        ...state,
        urlData: [...state.urlData, action.payload],
        loading: false
      };
    }
    case TOGGLE_MALL_STORE:
      return {
        ...state,
        action: { type: action.payload.type, isOpen: action.payload.show }
      };
    case SET_MENU_LABEL:
      return {
        ...state,
        menuLabel: action.payload
      };
    default:
      return state;
  }
};

export const toggleNavBar = () => {
  return (dispatch) => {
    dispatch(
      {
        type: HEADER_TOGGLE_NAV
      }
    );
  };
};

export const closeNavBar = () => {
  return (dispatch) => {
    dispatch(
      {
        type: CLOSE_HEADER_TOGGLE_NAV
      }
    );
  };
};

export const setHeaderInfo = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_HEADER_INFO,
        payload: data
      }
    );
  };
};

export const setHeaderTitlle = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_HEADER_INFO,
        payload: data
      }
    );
  };
};

export const toggleRestaurantBar = () => {
  return (dispatch) => {
    dispatch(
      {
        type: TOGGLE_RESTAURANT_BAR
      }
    );
  };
};

export const updateHeaderData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_HEADER_REQUEST,
      payload: data
    });

    dispatch({
      type: SET_HEADER_INFO,
      payload: data
    });
  };
};

// Only for without loading header
export const updateHeaderWithoutLoading = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_HEADER_WITHOUT_LOADING_REQUEST,
      payload: data
    });

    setTimeout(() => {
      dispatch({
        type: SET_HEADER_INFO,
        payload: data
      });
    }, 500);
  };
};
export const toggleCancelButtonSamePage = (status) => {
  return (dispatch) => {
    dispatch(
      {
        type: TOGGLE_CLOSE_ON_PAGE,
        payload: status
      }
    );
  };
};

export const urlDataList = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SET_URL_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: SET_URL_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const toggleFooter = (menuType) => {
  return (dispatch) => {
    dispatch(
      {
        type: TOGGLE_MALL_STORE,
        payload: menuType
      }
    );
  };
};

export const setMultipleMenuLabel = (label) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_MENU_LABEL,
        payload: label
      }
    );
  };
};
