import { getHeaders } from '@utility/helperFunction';

export const KIOSK_LANDING_TILE_REQUESTED = 'oauth/KIOSK_LANDING_TILE_REQUESTED';
export const KIOSK_LANDING_TILE_SUCCESS = 'oauth/KIOSK_LANDING_TILE_SUCCESS';
export const KIOSK_LANDING_TILE_FAILURE = 'oauth/KIOSK_LANDING_TILEFAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  kioskTile: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KIOSK_LANDING_TILE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        kioskTile: []
      };
    }
    case KIOSK_LANDING_TILE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kioskTile: action.result
      };
    }
    case KIOSK_LANDING_TILE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        kioskTile: action.result
      };
    }
    default:
      return state;
  }
};

export const landingPageTile = (storeId) => {
  const headers = getHeaders();
  return {
    types: [KIOSK_LANDING_TILE_REQUESTED, KIOSK_LANDING_TILE_SUCCESS, KIOSK_LANDING_TILE_FAILURE],
    promise: (client) => client.get(`getKioskTile/${storeId}`, {
      headers
    })
  };
};
