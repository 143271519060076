export const GET_RECEIPT_REQUESTED = 'receipt/GET_RECEIPT_REQUESTED';
export const GET_RECEIPT_SUCCESS = 'receipt/GET_RECEIPT_SUCCESS';
export const GET_RECEIPT_FAILURE = 'receipt/GET_RECEIPT_FAILURE';

export const RECEIPT_SMS_REQUESTED = 'receipt/RECEIPT_SMS_REQUESTED';
export const RECEIPT_SMS_SUCCESS = 'receipt/RECEIPT_SMS_SUCCESS';
export const RECEIPT_SMS_FAILURE = 'receipt/RECEIPT_SMS_FAILURE';

export const RECEIPT_EMAIL_REQUESTED = 'receipt/RECEIPT_EMAIL_REQUESTED';
export const RECEIPT_EMAIL_SUCCESS = 'receipt/RECEIPT_EMAIL_SUCCESS';
export const RECEIPT_EMAIL_FAILURE = 'receipt/RECEIPT_EMAIL_FAILURE';

export const VALIDATE_CHECK_REQUESTED = 'receipt/VALIDATE_CHECK_REQUESTED';
export const VALIDATE_CHECK_SUCCESS = 'receipt/VALIDATE_CHECK_SUCCESS';
export const VALIDATE_CHECK_FAILURE = 'receipt/VALIDATE_CHECK_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RECEIPT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_RECEIPT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        receiptData: action.result
      };
    }
    case GET_RECEIPT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case RECEIPT_SMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case RECEIPT_SMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case RECEIPT_SMS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case RECEIPT_EMAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case RECEIPT_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case RECEIPT_EMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case VALIDATE_CHECK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case VALIDATE_CHECK_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case VALIDATE_CHECK_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    default:
      return state;
  }
};

export const getReceiptData = (ticketId, referenceCode) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: (client) => client.get(`getReceipt/${ticketId}-${referenceCode}`),
  };
};

export const getCheckReceiptData = (ticketId, referenceCode) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: (client) => client.get(`checkReceipt/${ticketId}-${referenceCode}`),
  };
};

export const getReceipt = (refrenceId, id) => {
  return {
    types: [GET_RECEIPT_REQUESTED, GET_RECEIPT_SUCCESS, GET_RECEIPT_FAILURE],
    promise: (client) => client.get(`getReceipt/${id}-${refrenceId}`),
  };
};

export const smsReceipt = (data) => {
  return {
    types: [RECEIPT_SMS_REQUESTED, RECEIPT_SMS_SUCCESS, RECEIPT_SMS_FAILURE],
    promise: (client) => client.post('smsReceipt', {
      data
    })
  };
};
export const emailReceipt = (data) => {
  return {
    types: [RECEIPT_EMAIL_REQUESTED, RECEIPT_EMAIL_SUCCESS, RECEIPT_EMAIL_FAILURE],
    promise: (client) => client.post('emailReceipt', {
      data
    })
  };
};

export const selectStage = (data) => {
  return {
    types: [RECEIPT_EMAIL_REQUESTED, RECEIPT_EMAIL_SUCCESS, RECEIPT_EMAIL_FAILURE],
    promise: (client) => client.post('selectStage', {
      data
    })
  };
};

export const validateUnchanged = (data) => {
  return {
    types: [VALIDATE_CHECK_REQUESTED, VALIDATE_CHECK_SUCCESS, VALIDATE_CHECK_FAILURE],
    promise: (client) => client.post('validateUnchanged', {
      data
    })
  };
};
