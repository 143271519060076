import { getHeaders } from '@utility/helperFunction';

export const ADD_TO_STAGE_SUCCESS = 'stage/ADD_TO_STAGE_SUCCESS';
export const ADD_TO_STAGE_REQUESTED = 'stage/ADD_TO_STAGE_REQUESTED';
export const ADD_TO_STAGE_FAILURE = 'stage/ADD_TO_STAGE_FAILURE';

export const RENAME_TO_STAGE_SUCCESS = 'stage/RENAME_TO_STAGE_SUCCESS';
export const RENAME_TO_STAGE_REQUESTED = 'stage/RENAME_TO_STAGE_REQUESTED';
export const RENAME_TO_STAGE_FAILURE = 'stage/RENAME_TO_STAGE_FAILURE';

export const DELETE_STAGE_SUCCESS = 'stage/DELETE_STAGE_SUCCESS';
export const DELETE_STAGE_REQUESTED = 'stage/DELETE_STAGE_REQUESTED';
export const DELETE_STAGE_FAILURE = 'stage/DELETE_STAGE_FAILURE';

export const SET_CONFIRM_STAGE_SPLIT = 'stage/SET_CONFIRM_STAGE_SPLIT';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isConfirmStageSplit: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_STAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ADD_TO_STAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case ADD_TO_STAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case RENAME_TO_STAGE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case RENAME_TO_STAGE_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case RENAME_TO_STAGE_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }
    case DELETE_STAGE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELETE_STAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELETE_STAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_CONFIRM_STAGE_SPLIT: {
      return {
        ...state,
        isConfirmStageSplit: action.payload
      };
    }
    default:
      return state;
  }
};

export const addToStage = (data) => {
  const headers = getHeaders();
  return {
    types: [ADD_TO_STAGE_REQUESTED, ADD_TO_STAGE_SUCCESS, ADD_TO_STAGE_FAILURE],
    promise: (client) => client.post('addToStage', {
      headers,
      data
    })
  };
};

export const renameToStage = (data) => {
  const headers = getHeaders();
  return {
    types: [RENAME_TO_STAGE_REQUESTED, RENAME_TO_STAGE_SUCCESS, RENAME_TO_STAGE_FAILURE],
    promise: (client) => client.post('renameToStage', {
      headers,
      data
    })
  };
};

export const deleteStage = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_STAGE_REQUESTED, DELETE_STAGE_SUCCESS, DELETE_STAGE_FAILURE],
    promise: (client) => client.post('deleteStage', {
      headers,
      data
    })
  };
};

export const setConfirmStageSplit = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_CONFIRM_STAGE_SPLIT,
        payload: data,
      });
      resolve();
    });
  };
};
