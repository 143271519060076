import { getObjectValue } from '@utility/utills';
import { getHeaders } from '@utility/helperFunction';
import {toggleKdsTheme, Theme, toggleServerAppTheme} from '../utility/toggleTheme';
// import { APIConfig } from '@constants';

// const HOSTNAME = APIConfig.hostname;

const PUSH_THEME_STYLES = 'theme/PUSH_THEME_STYLES';
const GET_THEME_STYLES = 'theme/PUSH_THEME_STYLES';

const GET_BRAND_THEME_URL_REQUEST = 'theme/GET_BRAND_THEME_URL_REQUEST';
const GET_BRAND_THEME_URL_SUCCESS = 'theme/GET_BRAND_THEME_URL_SUCCESS';
const GET_BRAND_THEME_URL_FAILURE = 'theme/GET_BRAND_THEME_URL_FAILURE';

const GET_BRAND_CUSTOMIZATION_REQUEST = 'theme/GET_BRAND_CUSTOMIZATION_REQUEST';
const GET_BRAND_CUSTOMIZATION_SUCCESS = 'theme/GET_BRAND_CUSTOMIZATION_SUCCESS';
const GET_BRAND_CUSTOMIZATION_FAILURE = 'theme/GET_BRAND_CUSTOMIZATION_FAILURE';
const TOGGLE_THEME = 'theme/TOGGLE_THEME'

const REMOVE_THEME = 'theme/REMOVE_THEME';

const initalValues = {
  loading: false,
  loaded: true,
  storeThemeUrl: '',
  styles: {},
  serverAppTheme: toggleServerAppTheme(),
  kdsTheme:toggleKdsTheme(),
  themeProperties: JSON.parse(window.sessionStorage.getItem('themeProperties')) || {},
  variableProperties: {}
};

const removeTheme = () => {
  try {
    const prevStyle = document.getElementById('themingStyleSheet');
    if (prevStyle) {
      prevStyle.parentNode.removeChild(prevStyle);
    }
    const prevHeaderScriptUrl = document.getElementById('headerScriptUrl');
    if (prevHeaderScriptUrl) {
      prevHeaderScriptUrl.parentNode.removeChild(prevHeaderScriptUrl);
    }
    const prevPaymentCompletionScriptUrl = document.getElementById('paymentCompletionScriptUrl');
    if (prevPaymentCompletionScriptUrl) {
      prevPaymentCompletionScriptUrl.parentNode.removeChild(prevPaymentCompletionScriptUrl);
    }
  } catch (e) {
    console.log(e);
  }
};

const updateTheme = (res) => {
  removeTheme();

  /* enable-disable */

  /* eslint-enable */
  if (res.styleUrl) {
    const css = document.createElement('link');
    css.type = 'text/css';
    css.id = 'themingStyleSheet';
    css.href = `${res.styleUrl}?date=${new Date()}`;
    css.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(css);
  }
  /* enable-disable */
  /* eslint-disable */

  if (res.headerScriptTag) {
    const headerJs = document.createElement('script');
    headerJs.src = res.headerScriptTag;
    headerJs.id = 'headerJs';
    document.getElementsByTagName('head')[0].appendChild(headerJs);
  }

  if (res.headerImageTag) {
    document.getElementById('headerImage').innerHTML = `<noscript><img height="1" width="1" style="border-style:none;" alt="" src=${res.headerImageTag} /></noscript>`;
  }

  if(res.headerScriptUrl) {
    const headerScriptUrl = document.createElement('script');
    headerScriptUrl.type = 'text/javascript';
    headerScriptUrl.id = 'headerScriptUrl';
    headerScriptUrl.src = `${res.headerScriptUrl}`;
    document.getElementsByTagName('head')[0].appendChild(headerScriptUrl);
  }

  if (res.paymentCompletionScriptUrl && res.headerScriptTag) {
    const headerJsExe = document.createElement('script');
    headerJsExe.type = 'text/javascript';
    headerJsExe.id = 'headerScriptUrlExe';
    headerJsExe.src = `${res.paymentCompletionScriptUrl}`;
    document.getElementsByTagName('body')[0].appendChild(headerJsExe);
  }

  if(res && getObjectValue(() => JSON.parse(res.headerMetaTag), null)) {
    const metaResponse = JSON.parse(res.headerMetaTag);
    const data = document.createElement('meta');
    data.name = getObjectValue(() => metaResponse.name, '');
    data.content = getObjectValue(() => metaResponse.content, '');;
    document.getElementsByTagName('head')[0].appendChild(data);
  }

  /* eslint-disable */
};

export default (state = initalValues, action) => {
  switch (action.type) {
    case PUSH_THEME_STYLES: {
      window.sessionStorage.setItem('themeStyle', JSON.stringify(action.payload));
      return { ...state, styles: action.payload };
    }
    case GET_THEME_STYLES:
      return { ...state, styles: action.result };
    case GET_BRAND_THEME_URL_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case GET_BRAND_THEME_URL_SUCCESS:
      window.sessionStorage.setItem('themeProperties', JSON.stringify(action.result));
      updateTheme(action.result);
      return {
        ...state,
        loaded: true,
        loading: false,
        themeProperties: JSON.parse(window.sessionStorage.getItem('themeProperties')),
        variableProperties: JSON.parse(action.result.appCustomization || {})
      };
    case GET_BRAND_CUSTOMIZATION_REQUEST:
      return {
        ...state,
        loaded: false,
        loading: true
      };
    case GET_BRAND_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        variableProperties: JSON.parse(action.result.appCustomization)
      };
    case GET_BRAND_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    case REMOVE_THEME:
      window.sessionStorage.removeItem('themeProperties');
      removeTheme();
      return {
        ...state,
        themeProperties: {}
      };
    case GET_BRAND_THEME_URL_FAILURE:
      return {
        ...state,
        loaded: false,
        loading: false
      };
    case TOGGLE_THEME:
        return {
          ...state,
          serverAppTheme: action.payload === '1' ? Theme.darkTheme : Theme.lightTheme ,
      };
    default:
      return state;
  }
};

export const getStoreThemeUrl = (brandId, storeId, deviceType) => {
  const headers = getHeaders();
  return {
    types: [GET_BRAND_THEME_URL_REQUEST, GET_BRAND_THEME_URL_SUCCESS, GET_BRAND_THEME_URL_FAILURE],
    promise: client => client.get(`getStoreThemeUrl/${deviceType}/brandId/${brandId}/storeId/${storeId}`, {
      headers
    })
  };
  // return (dispatch) => {
  //   dispatch({
  //     type: GET_BRAND_THEME_URL_FAILURE,
  //     payload: storeId
  //   });
  // };
};

export const appCustomization = (brandId, storeId) => {
  return {
    types: [GET_BRAND_CUSTOMIZATION_REQUEST, GET_BRAND_CUSTOMIZATION_SUCCESS, GET_BRAND_CUSTOMIZATION_FAILURE],
    promise: client => client.get(`appCustomization/${brandId}/storeId/${storeId}`)
  };
  // return (dispatch) => {
  //   dispatch({
  //     type: GET_BRAND_THEME_URL_FAILURE,
  //     payload: storeId
  //   });
  // };
};

export const removeThemeStyle = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_THEME
    });
  };
};

export const pushThemeStyles = (data) => {
  return (dispatch) => {
    dispatch({
      type: PUSH_THEME_STYLES,
      payload: data
    });
  };
};

export const changeLanguage = (brandId, storeId, languageId) => {
  const headers = getHeaders();
  return {
    types: [GET_BRAND_THEME_URL_REQUEST, GET_BRAND_THEME_URL_SUCCESS, GET_BRAND_THEME_URL_FAILURE],
    promise: client => client.get(`changeLanguage/${brandId}/storeId/${storeId}/languageId/${languageId}`, {
      headers
    })
  };
};
export const toggleTheme = (data) => {
  return {
    type: TOGGLE_THEME,
    payload: data
  };
}