import { getHeaders, oneDineFiltersList } from '@utility/helperFunction';
import moment from 'moment';
import { last, orderBy } from 'lodash';
import { getObjectValue } from '../utility/utills';

export const GET_ORDER_UP_TILES_SUCCESS = 'orderUp/GET_ORDER_UP_TILES_SUCCESS';
export const GET_ORDER_UP_TILES_REQUESTED = 'orderUp/GET_ORDER_UP_TILES_REQUESTED';
export const GET_ORDER_UP_TILES_FAILURE = 'orderUp/GET_ORDER_UP_TILES_FAILURE';

export const GET_ORDER_UP_LAYOULT_SUCCESS = 'orderUp/GET_ORDER_UP_LAYOULT_SUCCESS';
export const GET_ORDER_UP_LAYOULT_REQUESTED = 'orderUp/GET_ORDER_UP_LAYOULT_REQUESTED';
export const GET_ORDER_UP_LAYOULT_FAILURE = 'orderUp/GET_ORDER_UP_LAYOULT_FAILURE';

export const GET_CHECK_LIST_REQUEST = 'orderUp/GET_CHECK_LIST_REQUEST';
export const GET_CHECK_LIST_SUCCESS = 'orderUp/GET_CHECK_LIST_SUCCESS';

export const UPDATE_LIST_OF_COLORS = 'orderUp/UPDATE_LIST_OF_COLORS';

const getRequiredFilters = (list = [], filters) => {
  const filtersList = [];
  list.forEach((v) => {
    if (filters.includes(v.externalId)) {
      filtersList.push(v.externalId);
    }
  });
  return filtersList;
};

const getCubbyLayoultHashTable = () => {
  const storeCubby = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).storeCubby, null);
  const list = {};
  if (storeCubby) {
    storeCubby.slots.forEach((v) => {
      list[v.id] = {
        ...v
      };
    });
  }
  return list;
};

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  orderUpTilesList: [],
  listOfColors: {},
  readyOrderChecks: {},
  totalOrderCount: 0,
  lastCheckTime: '00:00',
  cubbyLayoultHashTable: getCubbyLayoultHashTable()
};

export const getOrderUpDisplayColor = (completedDate, deviceGroup, prepWindow, type, data) => {
  const momentTime = moment.utc(completedDate).local();
  const currentTime = moment();
  const diffTime = +(Math.abs(currentTime.diff(momentTime, 'seconds', true))).toFixed(2);
  const orderUpWarningTime = deviceGroup?.orderUpWarningTime ? +(deviceGroup?.orderUpWarningTime).toFixed(2) : false;
  const orderUpErrorTime = deviceGroup?.orderUpErrorTime ? +(deviceGroup?.orderUpErrorTime).toFixed(2) : false;
  const orderUpClearTime = deviceGroup?.orderUpClearTime ? +(deviceGroup?.orderUpClearTime).toFixed(2) : false;
  let color = deviceGroup ? false : 'bg-white';
  if (deviceGroup && type === 0) {
    color = 'bg-white';
    if (orderUpWarningTime && (diffTime >= orderUpWarningTime)) {
      color = 'bg-orange';
    }
    if (orderUpErrorTime && (diffTime >= orderUpErrorTime)) {
      color = 'bg-red';
    }
    if (orderUpClearTime && (diffTime >= orderUpClearTime)) {
      color = false;
    }
  } else if (deviceGroup && data) {
    const makingTime = moment(momentTime, 'HH:mm').subtract(prepWindow, 'minutes');
    const validCheck = currentTime.isAfter(makingTime);
    if (data.orderStatusId === 1 && validCheck) {
      color = 'bg-white';
      if (orderUpWarningTime && (diffTime >= orderUpWarningTime)) {
        color = 'bg-orange';
      }
      if (orderUpErrorTime && (diffTime >= orderUpErrorTime)) {
        color = 'bg-red';
      }
    }
    if (data.orderStatusId === 5 && data.pickUpStatus === 0) {
      color = 'bg-white';
      if (orderUpWarningTime && (diffTime >= orderUpWarningTime)) {
        color = 'bg-orange';
      }
      if (orderUpErrorTime && (diffTime >= orderUpErrorTime)) {
        color = 'bg-red';
      }
      if (orderUpClearTime && (diffTime >= orderUpClearTime)) {
        color = false;
      }
    }
    if (data.orderStatusId === 5 && data.pickUpStatus === 3) {
      color = 'bg-white';
      if (orderUpWarningTime && (diffTime >= orderUpWarningTime)) {
        color = 'bg-orange';
      }
      if (orderUpErrorTime && (diffTime >= orderUpErrorTime)) {
        color = 'bg-red';
      }
    }
  }
  return color;
};

const defaultDisplayList = (checkListHashTable, deviceInfo, auth, type) => {
  let list = Object.values(checkListHashTable || {});
  let readyOrderChecks = {};
  const listColors = {};
  list = list.length > 0 ? list?.reduce((a, v) => {
    let color = false;
    try {
      // const date = type === 0 ? (v?.orders[0]?.completedDate || v?.orders[0]?.pickupDate || v?.orders[0]?.orderDate) : v?.orders[0]?.pickupDate;
      const date = (v?.orders[0]?.completedDate || v?.orders[0]?.pickupDate || v?.orders[0]?.orderDate);
      color = getOrderUpDisplayColor(date, deviceInfo?.deviceGroup, auth?.prepWindow, type, (v?.orders?.length > 0 && v?.orders[0]));
    } catch (err) {
      console.log('err', err);
      color = false;
    }
    if (color) {
      a.push({
        ...v
      });
      listColors[v.id] = {
        id: v.id,
        name: v.stationName,
        color
      };
    }
    return a;
  }, []) : [];
  if (deviceInfo?.deviceGroup) {
    if (deviceInfo.deviceGroup.fireBoardSettings.enableFilterSourceOneDineChannel && Object.values(deviceInfo?.deviceGroup?.checkChannelFilters).filter((e) => e).length > 0) {
      const oneDineFilter = oneDineFiltersList.reduce((a, v) => {
        if (deviceInfo?.deviceGroup?.checkChannelFilters[v.name]) {
          if (typeof v.sourceType === 'object') {
            a.push(...v.sourceType.filter((d) => !a.includes(d)));
          } else if (!a.includes(v.sourceType)) {
            a.push(v.sourceType);
          }
        }
        return a;
      }, []);
      list = list?.filter((e) => oneDineFilter.includes(e.sourceType));
    }
    if (deviceInfo.deviceGroup.fireBoardSettings.enableFilterSourcePosRevenueCenter && deviceInfo?.deviceGroup?.checkRevenueCenterFilters?.length > 0) {
      const revenueCenters = getRequiredFilters(JSON.parse(window.sessionStorage.getItem('auth'))?.revenueCenters, JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceGroup.checkRevenueCenterFilters);
      list = list?.filter((e) => revenueCenters.includes(e.revenueCenterExternalId));
    }
    if (deviceInfo.deviceGroup.fireBoardSettings.enableFilterSourcePosOrderType && deviceInfo?.deviceGroup?.checkOrderTypeFilters?.length > 0) {
      const orderTypes = getRequiredFilters((JSON.parse(window.sessionStorage.getItem('auth'))?.orderTypes), JSON.parse(window.sessionStorage.getItem('deviceInfo'))?.deviceGroup.checkOrderTypeFilters);
      list = list?.filter((e) => orderTypes.includes(e.orderTypeExternalId));
    }
    // if (deviceInfo.deviceGroup?.orderUpSettings?.onlyDisplayCubbyOrders) {
    //   list = list?.filter((e) => e?.orders[0]?.storeCubbySlotId);
    // }
  }
  if (list && type === 0) {
    list = list.filter((e) => (e?.orders[0]?.storeCubbySlotId && (e?.orders[0]?.orderStatusId === 5) && (e?.orders[0]?.pickUpStatus === 0) && (e?.orders[0]?.pickUpStatus !== 1)));
  }
  const groupObj = {};
  const totalOrderCount = list.length;
  const lastCheckTime = moment.utc(last(Object.values(checkListHashTable))?.updatedDate).local().format('h:mm A');
  const sortedChecks = orderBy(list, [(check) => (check?.orders[0]?.checkInNote || check.name || check.stationName || check.stationNumber)], ['asc']);
  const {
    rowCount, columnCount, orderUpDisplaySequence,
    primaryOrderUpDisplayColumnCount, primaryOrderUpDisplayRowCount
  } = deviceInfo;
  const primaryCount = Number(orderUpDisplaySequence) > 0 ? +((primaryOrderUpDisplayColumnCount * primaryOrderUpDisplayRowCount) || (rowCount * columnCount)) : +(rowCount * columnCount || 8);
  const primaryList = sortedChecks.splice(0, primaryCount);
  const otherList = sortedChecks.splice(0, list.length);
  groupObj[1] = primaryList;
  if (otherList?.length > 0) {
    const checksPerPage = +(rowCount * columnCount || 8);
    otherList.forEach((v, i) => {
      const page = Math.floor(i / checksPerPage);
      (groupObj[page + 2] = groupObj[page + 2] || []).push(v);
    });
  }
  readyOrderChecks = groupObj;
  return [readyOrderChecks, listColors, totalOrderCount, lastCheckTime];
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_UP_TILES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_ORDER_UP_TILES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        orderUpTilesList: action.result
      };
    }
    case GET_ORDER_UP_TILES_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case GET_CHECK_LIST_REQUEST: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case GET_CHECK_LIST_SUCCESS: {
      const [readyOrderChecks, listColors, totalOrderCount, lastCheckTime] = defaultDisplayList(action.payload.checkListHashTable, action.payload.deviceInfo, action.payload.auth, action.payload.type, action.payload.checkPerPage);
      action.resolve(readyOrderChecks);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        readyOrderChecks,
        listOfColors: listColors,
        totalOrderCount,
        lastCheckTime,
        cubbyLayoultHashTable: getCubbyLayoultHashTable()
      };
    }
    case UPDATE_LIST_OF_COLORS: {
      const {
        id,
        type,
        color,
        index,
        currentPage,
        deviceInfo,
      } = action.payload;
      const listColors = { ...state.listOfColors };
      listColors[id] = {
        ...listColors[id],
        color,
        type,
      };
      const updatedReadyOrderChecks = { ...state.readyOrderChecks };
      if (!color) {
        updatedReadyOrderChecks[currentPage].splice(index, 1);
      }
      const list = Object.values(updatedReadyOrderChecks).flat();
      const totalOrderCount = list.length;
      const sortedChecks = orderBy(list, [(check) => (check?.orders[0]?.checkInNote || check.name || check.stationName || check.stationNumber)], ['asc']);
      const groupObj = {};
      const {
        rowCount, columnCount, orderUpDisplaySequence,
        primaryOrderUpDisplayColumnCount, primaryOrderUpDisplayRowCount
      } = deviceInfo;
      const primaryCount = Number(orderUpDisplaySequence) > 0 ? +((primaryOrderUpDisplayColumnCount * primaryOrderUpDisplayRowCount) || (rowCount * columnCount)) : +(rowCount * columnCount || 8);
      const primaryList = sortedChecks.splice(0, primaryCount);
      const otherList = sortedChecks.splice(0, list.length);
      groupObj[1] = primaryList;
      if (otherList?.length > 0) {
        const checksPerPage = +(rowCount * columnCount || 8);
        otherList.forEach((v, i) => {
          const page = Math.floor(i / checksPerPage);
          (groupObj[page + 2] = groupObj[page + 2] || []).push(v);
        });
      }
      action.resolve(groupObj);
      return {
        ...state,
        readyOrderChecks: groupObj,
        listOfColors: listColors,
        totalOrderCount
      };
    }
    default:
      return state;
  }
};

export const getOrderUpTiles = (storeId) => {
  const headers = getHeaders();
  return {
    types: [GET_ORDER_UP_TILES_REQUESTED, GET_ORDER_UP_TILES_SUCCESS, GET_ORDER_UP_TILES_FAILURE],
    promise: (client) => client.get(`getOrderUpTiles/${storeId}`, {
      headers,
    })
  };
};

export const getChecksList = (payload) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: GET_CHECK_LIST_SUCCESS,
        payload,
        resolve
      });
    });
  };
};

export const updateListOfColors = (payload) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_LIST_OF_COLORS,
        payload,
        resolve
      });
    });
  };
};
