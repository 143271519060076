import { listToHashTable } from '@utility/arrayOperations';
import { getHeaders } from '@utility/helperFunction';
import { modifySeatObject } from '@utility/utills';
import moment from 'moment';
import { utills } from '@utility';
// import EventEmitter from '@utility/eventSubscribers';
// import socketActionTypes from '../sockets/socketActionTypes';
import { flowType } from '@utility/userInfo';

export const GET_STORE_CHECKS_REQUESTED = 'storeChecks/GET_STORE_CHECKS_REQUESTED';
export const GET_STORE_CHECKS_SUCCESS = 'storeChecks/GET_STORE_CHECKS_SUCCESS';
export const UPDATE_STORE_CHECKS = 'storeChecks/UPDATE_STORE_CHECKS';
export const UPDATE_STORE_CHECK_PREPS = 'storeChecks/UPDATE_STORE_CHECK_PREPS';
export const GET_STORE_CHECKS_FAILURE = 'storeChecks/GET_STORE_CHECKS_FAILURE';

const DELETE_ITEM_REQUEST = 'storeChecks/DELETE_ITEM_REQUEST';
const DELETE_ITEM_SUCCESS = 'storeChecks/DELETE_ITEM_SUCCESS';
const DELETE_ITEM_FAILURE = 'storeChecks/DELETE_ITEM_FAILURE';

const SEND_CHECKS_REQUEST = 'storeChecks/SEND_CHECKS_REQUEST';
const SEND_CHECKS_SUCCESS = 'storeChecks/SEND_CHECKS_SUCCESS';
const SEND_CHECKS_FAILURE = 'storeChecks/SEND_CHECKS_FAILURE';

const SEND_BUMP_REQUEST = 'storeChecks/SEND_BUMP_REQUEST';
const SEND_BUMP_SUCCESS = 'storeChecks/SEND_BUMP_SUCCESS';
const SEND_BUMP_FAILURE = 'storeChecks/SEND_BUMP_FAILURE';

const SEND_PDF_REQUEST = 'storeChecks/SEND_PDF_REQUEST';
const SEND_PDF_SUCCESS = 'storeChecks/SEND_PDF_SUCCESS';
const SEND_PDF_FAILURE = 'storeChecks/SEND_PDF_FAILURE';


const SEND_LAYOUT_REQUEST = 'storeChecks/SEND_LAYOUT_REQUEST';
const SEND_LAYOUT_SUCCESS = 'storeChecks/SEND_LAYOUT_SUCCESS';
const SEND_LAYOUT_FAILURE = 'storeChecks/SEND_LAYOUT_FAILURE';

const SEND_DUPLICATE_REQUEST = 'storeChecks/SEND_DUPLICATE_REQUEST';
const SEND_DUPLICATE_SUCCESS = 'storeChecks/SEND_DUPLICATE_SUCCESS';
const SEND_DUPLICATE_FAILURE = 'storeChecks/SEND_DUPLICATE_FAILURE';


const SEND_REPRIORITIZE_REQUEST = 'storeChecks/SEND_REPRIORITIZE_REQUEST';
const SEND_REPRIORITIZE_SUCCESS = 'storeChecks/SEND_REPRIORITIZE_SUCCESS';
const SEND_REPRIORITIZE_FAILURE = 'storeChecks/SEND_REPRIORITIZE_FAILURE';
 
const MOVE_CHECKS_REQUEST = 'storeChecks/MOVE_CHECKS_REQUEST';
const MOVE_CHECKS_SUCCESS = 'storeChecks/MOVE_CHECKS_SUCCESS';
const MOVE_CHECKS_FAILURE = 'storeChecks/MOVE_CHECKS_FAILURE';

const CLEAR_AUTH = 'storeChecks/CLEAR_AUTH';
const UPDATE_CHECKS_AFTER_RESPONSE = 'storeChecks/UPDATE_CHECKS_AFTER_RESPONSE';
const SET_SORTED_ORDER = 'storeChecks/SET_SORTED_ORDER';
const CLEAR_SORTED_ORDER = 'storeChecks/CLEAR_SORTED_ORDER';

const PRINT_REQUEST = 'storeChecks/PRINT_REQUEST';
const PRINT_SUCCESS = 'storeChecks/PRINT_SUCCESS';
const PRINT_FAILURE = 'storeChecks/PRINT_FAILURE';

const SYNC_CHECK_REQUEST = 'storeChecks/SYNC_CHECK_REQUEST';
const SYNC_CHECK_SUCCESS = 'storeChecks/SYNC_CHECK_SUCCESS';
const SYNC_CHECK_FAILURE = 'storeChecks/SYNC_CHECK_FAILURE';

const TRANSFER_CHECK_REQUEST = 'storeChecks/TRANSFER_CHECK_REQUEST';
const TRANSFER_CHECK_SUCCESS = 'storeChecks/TRANSFER_CHECK_SUCCESS';
const TRANSFER_CHECK_FAILURE = 'storeChecks/TRANSFER_CHECK_FAILURE';

const TRANSFER_CHECK_TO_TABLE_REQUEST = 'storeChecks/TRANSFER_CHECK_TO_TABLE_REQUEST';
const TRANSFER_CHECK_TO_TABLE_SUCCESS = 'storeChecks/TRANSFER_CHECK_TO_TABLE_SUCCESS';
const TRANSFER_CHECK_TO_TABLE_FAILURE = 'storeChecks/TRANSFER_CHECK_TO_TABLE_FAILURE';

const SYNC_CHECK_PAYMENT_REQUEST = 'storeChecks/SYNC_CHECK_PAYMENT_REQUEST';
const SYNC_CHECK_PAYMENT_SUCCESS = 'storeChecks/SYNC_CHECK_PAYMENT_SUCCESS';
const SYNC_CHECK_PAYMENT_FAILURE = 'storeChecks/SYNC_CHECK_PAYMENT_FAILURE';

const GET_ALL_CHECKS_REQUEST = 'storeChecks/GET_ALL_CHECKS_REQUEST';
const GET_ALL_CHECKS_SUCCESS = 'storeChecks/GET_ALL_CHECKS_SUCCESS';
const GET_ALL_CHECKS_HANDLE_SUCCESS = 'storeChecks/GET_ALL_CHECKS_HANDLE_SUCCESS';

const GET_ALL_CHECKS_FAILURE = 'storeChecks/GET_ALL_CHECKS_FAILURE';

const GET_ALL_CHECK_PREPS_REQUEST = 'storeChecks/GET_ALL_CHECK_PREPS_REQUEST';
const GET_ALL_CHECK_PREPS_SUCCESS = 'storeChecks/GET_ALL_CHECK_PREPS_SUCCESS';
const GET_ALL_CHECK_PREPS_FAILURE = 'storeChecks/GET_ALL_CHECK_PREPS_FAILURE';

export const CONFIRM_CHECK_ID_REQUEST = 'storeChecks/CONFIRM_CHECK_ID_REQUEST';
export const CONFIRM_CHECK_ID_SUCCESS = 'storeChecks/CONFIRM_CHECK_ID_SUCCESS';
export const CONFIRM_CHECK_ID_FAILURE = 'storeChecks/CONFIRM_CHECK_ID_FAILURE';

export const DISABLE_AGE_RESTRICT_REQUEST = 'storeChecks/DISABLE_AGE_RESTRICT_REQUEST';
export const DISABLE_AGE_RESTRICT_SUCCESS = 'storeChecks/DISABLE_AGE_RESTRICT_SUCCESS';
export const DISABLE_AGE_RESTRICT_FAILURE = 'storeChecks/DISABLE_AGE_RESTRICT_FAILURE';

const SHOW_CHECKS = 'storeChecks/SHOW_CHECKS';
export const MY_CHECKS = 'togglebutton/MY_CHECKS';
export const VISIBLE = 'optionsmenus/VISIBLE';
export const DELETE_CHECK = 'storeChecks/DELETE_CHECK';
export const DELETE_CHECK_PREP = 'storeChecks/DELETE_CHECK_PREP';


export const CLEAR_CHECKS = 'storeChecks/CLEAR_CHECKS';

export const SEND_RECALL_REQUEST = 'storeChecks/SEND_RECALL_REQUEST';
export const SEND_RECALL_SUCCESS = 'storeChecks/SEND_RECALL_SUCCESS';
export const SEND_RECALL_FAILURE = 'storeChecks/SEND_RECALL_FAILURE';

export const CHECK_RESCHEDULE_REQUEST = 'storeChecks/CHECK_RESCHEDULE_REQUEST';
export const CHECK_RESCHEDULE_SUCCESS = 'storeChecks/CHECK_RESCHEDULE_SUCCESS';
export const CHECK_RESCHEDULE_FAILURE = 'storeChecks/CHECK_RESCHEDULE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  recallLoading: false,
  recallLoaded: false,
  storeChecksList: [],
  sortedOrder: null,
  checkListHashTable: null,
  openCheckListHashTable:null,
  checkPrepsListHashTable: null,
  UpdatedcheckPrepsListHashTable: null,
  lastSocketUpdateTime: moment().format('h:mm A'),
  isOn: false,
  bumpLoading: false,
  allChecksVisible: true,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case VISIBLE: {
      return {
        ...state,
        allChecksVisible: !state.allChecksVisible
      };
    }
    case MY_CHECKS: {
      return {
        ...state,
        checkListHashTable: { ...state.checkListHashTable },
        isOn: !state.isOn
      };
    }
    case GET_STORE_CHECKS_REQUESTED: {
      action.resolve({ loading: true });
      return {
        ...state,
        loading: true,
        loaded: false,
        storeChecksList: [],
        checkListHashTable: null
      };
    }
    case GET_STORE_CHECKS_SUCCESS: {
      const filteredChecks = action.payload && flowType() !== 'orderUp' ? (action.payload || [])?.filter((check) => check.open) : action.payload;
      const checkList = (filteredChecks || []).map((check) => {
        const modifiedObject = modifySeatObject(check);
        const Items = modifiedObject.Items;
        const PendingItems = modifiedObject.PendingItems;
        const AllItems = modifiedObject.AllItems;
        return {
          ...check,
          Items,
          PendingItems,
          AllItems
        };
      });
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', checkList && checkList.length);
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkListHashTable: action.payload && listToHashTable([...checkList]),
        lastSocketUpdateTime: moment().format('h:mm A')
      };
    }

    case DELETE_CHECK: {
      const value = { ...state.checkListHashTable };
      return {
        ...state,
        checkListHashTable: action.payload && value[action.payload.id] ? delete value[action.payload.id] : value
      };
    }

    case CLEAR_CHECKS: {
      return {
        ...state,
        checkListHashTable: {}
      };
    }
    case UPDATE_STORE_CHECKS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.payload);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.payload && updatedChecksHashTable[action.payload.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.payload.id]: {
            ...action.payload,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.payload,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = flowType() !== 'orderUp'  && state.allChecksVisible ? [...Object.values(updatedChecksHashTable).filter((check) => check.open)] : [...Object.values(updatedChecksHashTable)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkListHashTable: listToHashTable(openChecksList),
        // { ...updatedChecksHashTable },
        lastSocketUpdateTime: moment().format('h:mm A')
      };
    }
    case UPDATE_STORE_CHECK_PREPS: {
      let updatedcheckPrepsListHashTable = state.checkPrepsListHashTable;
      // const modifiedObject = modifySeatObject(action.payload);
      // const Items = modifiedObject.Items;
      // const PendingItems = modifiedObject.PendingItems;
      // const AllItems = modifiedObject.AllItems;
      if (updatedcheckPrepsListHashTable && action.payload && updatedcheckPrepsListHashTable[action.payload.checkPrep.id]) {
      
        updatedcheckPrepsListHashTable = {
          ...state.checkPrepsListHashTable,
          [action.payload.checkPrep.id]:
          {
            ...action.payload.checkPrep,
        }
        };   
      } else {
              updatedcheckPrepsListHashTable = {
            ...state.checkPrepsListHashTable,
            [action.payload.checkPrep.id]: {
              ...action.payload.checkPrep,
              receivedDate: new Date()
            }
          };      
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkPrepsListHashTable: updatedcheckPrepsListHashTable,
        // { ...updatedChecksHashTable },
        lastSocketUpdateTime: moment().format('h:mm A')
      };
    }
    case DELETE_CHECK_PREP: {
      let updatedcheckPrepsListHashTable = { ...state.checkPrepsListHashTable };
      // const value = { ...state.checkPrepsListHashTable };
      action.payload && updatedcheckPrepsListHashTable[action.payload.checkPrepId] ? delete updatedcheckPrepsListHashTable[action.payload.checkPrepId] : updatedcheckPrepsListHashTable
      console.log(updatedcheckPrepsListHashTable,'vereere');
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkPrepsListHashTable: updatedcheckPrepsListHashTable,
      };
    }
      
    case GET_STORE_CHECKS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true
      };
    }
    case DELETE_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case DELETE_ITEM_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case DELETE_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SEND_CHECKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case SEND_CHECKS_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      // console.log('modifiedObject', modifiedObject);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: true,
        loaded: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case SEND_CHECKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SEND_BUMP_REQUEST:
      return {
        ...state,
        bumpLoading: true,
        loaded: false,
      };
      case SEND_BUMP_SUCCESS: {
        return {
          ...state,
          bumpLoading: false,
          loaded: true,
          // checkListHashTable: listToHashTable(openChecksList)
        };
      }
      case SEND_BUMP_FAILURE:
      return {
        ...state,
        bumpLoading: false,
        error: true,
      };
      case SEND_PDF_REQUEST:
        return {
          ...state,
          loading: true,
          loaded: false,
        };
        case SEND_PDF_SUCCESS: {
          return {
            ...state,
            loading: false,
            loaded: true,
            // checkListHashTable: listToHashTable(openChecksList)
          };
        }
        case SEND_PDF_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
        };
    case MOVE_CHECKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case MOVE_CHECKS_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result.checkHeader);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result.checkHeader && updatedChecksHashTable[action.result.checkHeader.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.checkHeader.id]: {
            ...action.result.checkHeader,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result.checkHeader,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case MOVE_CHECKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SEND_REPRIORITIZE_REQUEST:
      return {
        ...state,
        loading: true,
        error: true,
      };
    case SEND_REPRIORITIZE_SUCCESS:
      const checkPrepsListHashTable = {...state.checkPrepsListHashTable};
      Object.keys(action.result.priorities).forEach((orderKey) => {
        checkPrepsListHashTable[orderKey].sortOrder = action.result.priorities[orderKey];
      })
      return {
        ...state,
        checkPrepsListHashTable,
        loading: false,
        error: true,
      };
    case SEND_REPRIORITIZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case CLEAR_AUTH:
      window.sessionStorage.removeItem('authRes');
      return {
        ...state,
        loading: false
      };
    case UPDATE_CHECKS_AFTER_RESPONSE: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.payload);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.payload && updatedChecksHashTable[action.payload.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.payload.id]: {
            ...action.payload,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.payload,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = state.allChecksVisible ? [...Object.values(updatedChecksHashTable).filter((check) => check.open)] : [...Object.values(updatedChecksHashTable)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case SET_SORTED_ORDER: {
      return {
        ...state,
        loading: false,
        sortedOrder: action.payload
      };
    }
    case CLEAR_SORTED_ORDER: {
      return {
        ...state,
        loading: false,
        sortedOrder: null
      };
    }
    case PRINT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case PRINT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case PRINT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SYNC_CHECK_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case SYNC_CHECK_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case SYNC_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case CONFIRM_CHECK_ID_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case CONFIRM_CHECK_ID_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case CONFIRM_CHECK_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case DISABLE_AGE_RESTRICT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case DISABLE_AGE_RESTRICT_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case DISABLE_AGE_RESTRICT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case SYNC_CHECK_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };

    case SYNC_CHECK_PAYMENT_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = state.allChecksVisible ? [...Object.values(updatedChecksHashTable).filter((check) => check.open)] : [...Object.values(updatedChecksHashTable)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case SYNC_CHECK_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case TRANSFER_CHECK_TO_TABLE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case TRANSFER_CHECK_TO_TABLE_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case TRANSFER_CHECK_TO_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case TRANSFER_CHECK_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case TRANSFER_CHECK_SUCCESS: {
      let updatedChecksHashTable = state.checkListHashTable;
      const modifiedObject = modifySeatObject(action.result);
      const Items = modifiedObject.Items;
      const PendingItems = modifiedObject.PendingItems;
      const AllItems = modifiedObject.AllItems;
      if (updatedChecksHashTable && action.result && updatedChecksHashTable[action.result.id]) {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          [action.result.id]: {
            ...action.result,
            Items,
            PendingItems,
            AllItems
          }
        };
      } else {
        updatedChecksHashTable = {
          ...state.checkListHashTable,
          ...listToHashTable(
            [{
              ...action.result,
              Items,
              PendingItems,
              AllItems
            }]
          )
        };
      }
      const openChecksList = [...Object.values(updatedChecksHashTable).filter((check) => check.open)];
      if (window.ReactNativeWebView) {
        // EventEmitter.syncLogsData(socketActionTypes.INFO, 'Open checks', openChecksList?.length);
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        checkListHashTable: listToHashTable(openChecksList)
      };
    }
    case TRANSFER_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_ALL_CHECKS_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };

    case GET_ALL_CHECKS_SUCCESS: {
      const checkList = (utills.decriptRespose(action.result?.compressedData) || [])?.map((check) => {
        const modifiedObject = modifySeatObject(check);
        const Items = modifiedObject.Items;
        const PendingItems = modifiedObject.PendingItems;
        const AllItems = modifiedObject.AllItems;
        return {
          ...check,
          Items,
          PendingItems,
          AllItems
        };
      });
      const openChecks = checkList.filter((item) => item.open === true);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkListHashTable: listToHashTable([...openChecks]),
        openCheckListHashTable: listToHashTable([...openChecks]),
        lastSocketUpdateTime: moment().format('h:mm A')
      };
    }
    case GET_ALL_CHECKS_HANDLE_SUCCESS: {
      const checkList = (utills.decriptRespose(action.result?.compressedData) || [])?.map((check) => {
        const modifiedObject = modifySeatObject(check);
        const Items = modifiedObject.Items;
        const PendingItems = modifiedObject.PendingItems;
        const AllItems = modifiedObject.AllItems;
        return {
          ...check,
          Items,
          PendingItems,
          AllItems
        };
      });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkListHashTable: action.result && listToHashTable([...checkList]),
        openCheckListHashTable: listToHashTable([...checkList.filter((item) => item.open)]),
        lastSocketUpdateTime: moment().format('h:mm A')
      };
    }
    case GET_ALL_CHECK_PREPS_REQUEST:
      return {
        ...state,
      };

    case GET_ALL_CHECK_PREPS_SUCCESS: {
      const checkList = (utills.decriptRespose(action.result?.compressedData) || [])?.map((check) => {
        return {
          ...check,
        };
      });
      const obj = {}
      for (let idx = 0; idx < checkList.length; idx++) {
        const element = checkList[idx];
        obj[element.id] = element
    }
      return {
        ...state,
        checkPrepsListHashTable: action.result && obj,
        lastSocketUpdateTime: moment().format('h:mm A')
      };
    }
    case SHOW_CHECKS: {
      action.resolve();
      // const checkList = (Object.values(state.checkListHashTable) || [])?.filter((check) => check.open).map((check) => {
      //   const modifiedObject = modifySeatObject(check);
      //   const Items = modifiedObject.Items;
      //   const PendingItems = modifiedObject.PendingItems;
      //   const AllItems = modifiedObject.AllItems;
      //   return {
      //     ...check,
      //     Items,
      //     PendingItems,
      //     AllItems
      //   };
      // });
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        checkListHashTable: openCheckListHashTable,
      };
    }

    case GET_ALL_CHECKS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };

      case GET_ALL_CHECK_PREPS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };

    case SEND_RECALL_REQUEST: return {
      ...state,
      recallLoading: true,
      recallLoaded: false,
    };
    case SEND_RECALL_SUCCESS:return {
      ...state,
      recallLoading: false,
      recallLoaded: true,
      recallData: action.result
    };
    case SEND_RECALL_FAILURE:return {
      ...state,
      recallLoading: false,
      recallError: true,
    };
    case CHECK_RESCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case CHECK_RESCHEDULE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case CHECK_RESCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const dispatchStoreChecksRequested = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: GET_STORE_CHECKS_REQUESTED,
        resolve
      });
    });
  };
};

export const dispatchStoreChecksSuccess = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_STORE_CHECKS_SUCCESS,
      payload: data
    });
  };
};

export const dispatchStoreChecksFailure = () => {
  return (dispatch) => {
    dispatch({
      type: GET_STORE_CHECKS_FAILURE
    });
  };
};

export const deleteItm = (data) => {
  const headers = getHeaders();
  return {
    types: [DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS, DELETE_ITEM_FAILURE],
    promise: (client) => client.post('deleteItem', {
      data,
      headers,
    }),
    payload: data
  };
};

export const sendChecks = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_CHECKS_REQUEST, SEND_CHECKS_SUCCESS, SEND_CHECKS_FAILURE],
    promise: (client) => client.post('sendChecks', {
      data,
      headers
    })
  };
};

export const bumpApiCall = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_BUMP_REQUEST, SEND_BUMP_SUCCESS, SEND_BUMP_FAILURE],
    promise: (client) => client.post('checkPrep', {
      data,
      headers
    })
  };
};

export const receipePdfApiCall = (storeId,menuItemId ) => {
  console.log('reducerStore', storeId,menuItemId );
  const headers = getHeaders();
  return {
    types: [SEND_PDF_REQUEST, SEND_PDF_SUCCESS, SEND_PDF_FAILURE],
    promise: (client) => client.get(`receiptPdf/${storeId}/menuItemId/${menuItemId}`, {
      headers
    })
  };
};

export const getModifierRecipePdf = (storeId,modifierId ) => {
  const headers = getHeaders();
  return {
    types: [SEND_PDF_REQUEST, SEND_PDF_SUCCESS, SEND_PDF_FAILURE],
    promise: (client) => client.get(`receiptPdf/${storeId}/modifierId/${modifierId}`, {
      headers
    })
  };
};

export const layoutApiCall = (storeId) => {
  const headers = getHeaders();
  return {
    types: [SEND_LAYOUT_REQUEST, SEND_LAYOUT_SUCCESS, SEND_LAYOUT_FAILURE],
    promise: (client) => client.get(`layout/${ storeId }`, {
      headers
    })
  };
};

export const layoutUpdateApiCall = (layoutId,value) => {
  const headers = getHeaders();
  return {
    types: [SEND_LAYOUT_REQUEST, SEND_LAYOUT_SUCCESS, SEND_LAYOUT_FAILURE],
    promise: (client) => client.post(`layoutUpdate/${layoutId}/${value}`, {
      headers
    })
  };
};

export const duplicateData = (deviceId) => {
  const headers = getHeaders();
  return {
    types: [SEND_DUPLICATE_REQUEST, SEND_DUPLICATE_SUCCESS, SEND_DUPLICATE_FAILURE],
    promise: (client) => client.get(`duplicate/${deviceId}`, {
      headers
    })
  };
}

export const reprioritize = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_REPRIORITIZE_REQUEST, SEND_REPRIORITIZE_SUCCESS, SEND_REPRIORITIZE_FAILURE],
    promise: (client) => client.post(`reprioritize`, {
      data,
      headers
    })
  };
}

export const prepStartAll = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_BUMP_REQUEST, SEND_BUMP_SUCCESS, SEND_BUMP_FAILURE],
    promise: (client) => client.post('prepStartAll', {
      data,
      headers
    })
  };
};

export const moveChecks = (data) => {
  const headers = getHeaders();
  return {
    types: [MOVE_CHECKS_REQUEST, MOVE_CHECKS_SUCCESS, MOVE_CHECKS_FAILURE],
    promise: (client) => client.post('moveChecks', {
      data,
      headers
    })
  };
};

export const clearAuth = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AUTH
    });
  };
};

export const updateChecksAfterResponse = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_CHECKS_AFTER_RESPONSE,
        payload: data
      });
      resolve();
    });
  };
};

export const setSortedOrder = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SORTED_ORDER,
      payload: data
    });
  };
};

export const clearSortedOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SORTED_ORDER,
    });
  };
};

// export const updateStoreChecks = (check) => {
//   return (dispatch) => {
//     dispatch({
//       type: UPDATE_STORE_CHECKS,
//       payload: check
//     });
//   };
// };

export const printing = (data) => {
  const headers = getHeaders();
  return {
    types: [PRINT_REQUEST, PRINT_SUCCESS, PRINT_FAILURE],
    promise: (client) => client.post('print', {
      data,
      headers
    })
  };
};

export const syncCheck = (storeId, checkHeaderId) => {
  const headers = getHeaders();
  return {
    types: [SYNC_CHECK_REQUEST, SYNC_CHECK_SUCCESS, SYNC_CHECK_FAILURE],
    promise: (client) => client.post(`syncCheck/${storeId}/checkHeaderId/${checkHeaderId}`, {
      headers
    })
  };
};

export const confirmIdCheck = (data) => {
  const headers = getHeaders();
  return {
    types: [CONFIRM_CHECK_ID_REQUEST, CONFIRM_CHECK_ID_SUCCESS, CONFIRM_CHECK_ID_FAILURE],
    promise: (client) => client.post('confirmCheckId', {
      data,
      headers,
    })
  };
};

export const disableAgeRestrict = (data, id) => {
  const headers = getHeaders();
  return {
    types: [DISABLE_AGE_RESTRICT_REQUEST, DISABLE_AGE_RESTRICT_SUCCESS, DISABLE_AGE_RESTRICT_FAILURE],
    promise: (client) => client.post(id ? 'disableAgeRestrictSeat' : 'disableAgeRestrict', {
      data,
      headers,
    })
  };
};

export const transferCheckToTable = (data) => {
  const headers = getHeaders();
  return {
    types: [TRANSFER_CHECK_TO_TABLE_REQUEST, TRANSFER_CHECK_TO_TABLE_SUCCESS, TRANSFER_CHECK_TO_TABLE_FAILURE],
    promise: (client) => client.post('transferTable', {
      headers,
      data
    })
  };
};

export const transferCheckToEmp = (data) => {
  const headers = getHeaders();
  return {
    types: [TRANSFER_CHECK_REQUEST, TRANSFER_CHECK_SUCCESS, TRANSFER_CHECK_FAILURE],
    promise: (client) => client.post('transferEmployee', {
      headers,
      data
    })
  };
};

export const syncCheckForPayment = (data) => {
  const headers = getHeaders();
  return {
    types: [SYNC_CHECK_PAYMENT_SUCCESS, SYNC_CHECK_PAYMENT_FAILURE],
    promise: (client) => client.post('syncCheckForPayment', {
      headers,
      data
    }),
  };
};

export const syncForPayment = (data) => {
  const headers = getHeaders();
  return {
    types: [SYNC_CHECK_PAYMENT_SUCCESS, SYNC_CHECK_PAYMENT_FAILURE],
    promise: (client) => client.post('syncForPayment', {
      headers,
      data
    }),
  };
};

export const updateCheckAfterPriceCheck = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_STORE_CHECKS,
        payload: data
      });
      resolve();
    });
  };
};

export const getAllChecks = (storeId, handleCheck) => {
  const headers = getHeaders();
  return {
    types: [GET_ALL_CHECKS_REQUEST, handleCheck ? GET_ALL_CHECKS_HANDLE_SUCCESS : GET_ALL_CHECKS_SUCCESS, GET_ALL_CHECKS_FAILURE],
    promise: (client) => client.get(`allChecks/${storeId}`, {
      headers,
    }),
  };
};

export const getAllCheckPreps = (storeId) => {
  const headers = getHeaders();
  return {
    types: [GET_ALL_CHECK_PREPS_REQUEST, GET_ALL_CHECK_PREPS_SUCCESS, GET_ALL_CHECK_PREPS_FAILURE],
    promise: (client) => client.get(`allCheckPreps/${storeId}`, {
      headers,
    }),
  };
};

export const showOpenChecks = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SHOW_CHECKS,
        resolve
      });
    });
  };
};

export const setMyChecks = () => {
  return (dispatch) => {
    dispatch(
      {
        type: MY_CHECKS,
      }
    );
  };
};

export const recallApiCall = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_RECALL_REQUEST, SEND_RECALL_SUCCESS, SEND_RECALL_FAILURE],
    promise: (client) => client.post('unBumpItem', {
      data,
      headers
    })
  };
};

export const checkReScheduleRequest = (data) => {
  const headers = getHeaders();
  return {
    types: [CHECK_RESCHEDULE_REQUEST, CHECK_RESCHEDULE_SUCCESS, CHECK_RESCHEDULE_FAILURE],
    promise: (client) => client.post('checkReSchedule', {
      data,
      headers
    })
  };
};
