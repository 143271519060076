export const RENDER_ELEMENTS_PANEL = 'ui/RENDER_ELEMENTS_PANEL';

const initialState = {
  showElementPanel: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RENDER_ELEMENTS_PANEL: {
      return {
        ...state,
        showElementPanel: action.payload
      };
    }
    default:
      return state;
  }
};

export const renderElementPanel = (payload) => {
  return {
    type: RENDER_ELEMENTS_PANEL,
    payload
  };
};
