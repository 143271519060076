import { arrayOperations, utills } from '@utility';
import _ from 'lodash';

const GET_MENU_REQUESTED = 'browse/GET_MENU_REQUESTED';
const GET_MENU_SUCCESS = 'browse/GET_MENU_SUCCESS';
const CHANGE_MENU_REQUESTED = 'browse/CHANGE_MENU_REQUESTED';
const CHANGE_MENU_SUCCESS = 'browse/CHANGE_MENU_SUCCESS';
const GET_MENU_FAILURE = 'browse/GET_MENU_FAILURE';
const GET_MENU_FROM_SESSION = 'browse/GET_MENU_FROM_SESSION';

const GET_MENU_ITEMS_BY_SCREEN_REQUEST = 'browse/GET_MENU_ITEMS_BY_SCREEN_REQUEST';
const GET_MENU_ITEMS_BY_SCREEN_SUCCESS = 'browse/GET_MENU_ITEMS_BY_SCREEN_SUCCESS';

const ADD_TO_WISH_LIST_REQUESTED = 'browse/ADD_TO_WISH_LIST_REQUESTED';
const ADD_TO_WISH_LIST_SUCCESS = 'browse/ADD_TO_WISH_LIST_SUCCESS';

const CLEAR_BROWSE_REQUESTED = 'browse/CLEAR_BROWSE_REQUESTED';
const CLEAR_BROWSE_SUCCESS = 'browse/CLEAR_BROWSE_SUCCESS';

const SEARCH_CATEGORY_REQUESTED = 'browse/SEARCH_CATEGORY_REQUESTED';
const SEARCH_CATEGORY_SUCCESS = 'browse/SEARCH_CATEGORY_SUCCESS';

const REMOVE_ITEM_FROM_WISH_LIST_REQUESTED = 'browse/REMOVE_ITEM_FROM_WISH_LIST_REQUESTED';
const REMOVE_ITEM_FROM_WISH_LIST_SUCCESS = 'browse/REMOVE_ITEM_FROM_WISH_LIST_SUCCESS';

const ADD_TO_FAVOURITE_REQUESTED = 'browse/ADD_TO_FAVOURITE_REQUESTED';
const ADD_TO_FAVOURITE_SUCCESS = 'browse/ADD_TO_FAVOURITE_SUCCESS';
const ADD_TO_FAVOURITE_FAILURE = 'browse/ADD_TO_FAVOURITE_FAILURE';

const REMOVE_FROM_FAVOURITE_REQUESTED = 'browse/REMOVE_FROM_FAVOURITE_REQUESTED';
const REMOVE_FROM_FAVOURITE_SUCCESS = 'browse/REMOVE_FROM_FAVOURITE_SUCCESS';
const REMOVE_FROM_FAVOURITE_FAILURE = 'browse/REMOVE_FROM_FAVOURITE_FAILURE';

const UPDATE_FAVOURITE = 'browse/UPDATE_FAVOURITE';

const ADD_TO_FAVOURITE_GUEST_REQUESTED = 'browse/ADD_TO_FAVOURITE_GUEST_REQUESTED';
const ADD_TO_FAVOURITE_GUEST_SUCCESS = 'browse/ADD_TO_FAVOURITE_GUEST_SUCCESS';
const REMOVE_FROM_FAVOURITE_GUEST_REQUESTED = 'browse/REMOVE_FROM_FAVOURITE_GUEST_REQUESTED';
const REMOVE_FROM_FAVOURITE_GUEST_SUCCESS = 'browse/REMOVE_FROM_FAVOURITE_GUEST_SUCCESS';

const renderMenuItems = (result, menuIndex, isMenu) => {
  const menuCardList = {};
  let nextState = {};
  try {
    nextState = {
      menus: result.menus[menuIndex].screens,
      menuTemp: result.menus[menuIndex].screens,
      menusList: result.menus,
      menuItemsHashTable: arrayOperations.listToHashTable(result.menuItems),
      isMenuLoaded: true,
      isGetMenuLoaded: isMenu
    };
    nextState.menusList.forEach((menuListItem, mIndex) => {
      menuListItem.screens.forEach((menus) => {
        const returnObj = {};
        menus.menuItemLinks.forEach((item, indexMenu) => {
          returnObj[item.menuItemId] = {
            ...item,
            menuItem: nextState.menuItemsHashTable[item.menuItemId],
            menuTypeId: menus.id,
            index: indexMenu,
            menuIndex: mIndex
          };
        });
        menuCardList[menus.id] = returnObj;
      });
    });
    nextState.menuCardList = menuCardList;
  } catch (e) {
    console.log(e);
  }
  if (Object.keys(nextState).length === 0) {
    nextState.hideBrowse = true;
  }
  return nextState;
};

const getInitialBrowseMenuData = (isMenu, menuIndex) => {
  if (window.sessionStorage.getItem('browseMenu')) {
    return { ...renderMenuItems(utills.decriptRespose(JSON.parse(window.sessionStorage.getItem('browseMenu'))), menuIndex, isMenu) };
  }
  return {};
};

const initialValues = {
  loading: false,
  addWishlistLoader: false,
  loaded: true,
  error: false,
  modifierLoder: false,
  wishList: JSON.parse(window.sessionStorage.getItem('wishList')) || [],
  menus: [],
  menuItems: [],
  menusList: [],
  screenMenuItemsList: [],
  isMenuLoaded: false,
  ...getInitialBrowseMenuData(false, 0),
  menuIndex: 0,
  hideBrowse: false,
  favouriteBrowseItems: JSON.parse(window.sessionStorage.getItem('favouriteBrowseItems')) || [],
  addToFavouriteBrowseLoader: false,
  isGetMenuLoaded: false
};
let wishListTemp = [];
// let nextState = {};
// const menuCardList = {};
let searchItemsList = [];
let favouriteTemp = [];
let favItemIndex = -1;
let favouriteTempArray = [];
let favTempItemIndex = -1;

export default (state = initialValues, action) => {
  switch (action.type) {
    case GET_MENU_REQUESTED:
      return {
        ...state,
        // cart: [],
        isMenuLoaded: false,
        loading: true,
        loaded: false,
        isGetMenuLoaded: false
      };
    case GET_MENU_SUCCESS:
      window.sessionStorage.setItem('browseMenu', JSON.stringify(action.result.response.compressedBytes));
      return {
        ...state,
        menuLoading: false,
        loading: false,
        menuIndex: 0,
        ...getInitialBrowseMenuData(true, 0)
      };
    case CHANGE_MENU_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false
      };
    case CHANGE_MENU_SUCCESS:
      // nextState = {
      //   menus: state.menusList[action.payload].screens,
      //   menuTemp: state.menusList[action.payload].screens,
      //   menuIndex: action.payload
      // };
      // window.sessionStorage.setItem('browseMenu', JSON.stringify({ ...JSON.parse(window.sessionStorage.getItem('browseMenu') || {}), ...nextState }));
      action.resolve();
      return {
        ...state,
        menuLoading: false,
        loading: false,
        menuIndex: action.payload,
        ...getInitialBrowseMenuData(false, action.payload)
      };
    case GET_MENU_FROM_SESSION:
      return {
        ...state,
        ...getInitialBrowseMenuData(false, state.menuIndex)
      };
    case SEARCH_CATEGORY_REQUESTED:
      searchItemsList = [];
      return {
        ...state,
        loading: true
      };
    case SEARCH_CATEGORY_SUCCESS:
      action.resolve();
      // console.log('menuCardList', state.menuCardList);
      if (action.payload) {
        arrayOperations.converHashTableToArray(state.menuCardList || {}).forEach((menuItemList) => {
          arrayOperations.converHashTableToArray(menuItemList).forEach((item) => {
            if (item.menuIndex === state.menuIndex && item.menuItem.name.toLowerCase().includes(action.payload.toLowerCase())) {
              // console.log('searchItemsList', item, item.menuItem.name);
              searchItemsList.push(item);
            }
          });
        });
      }
      console.log('searchItemsList - res', searchItemsList, _.uniqBy(searchItemsList, 'menuItemId'));
      return {
        ...state,
        menus: action.payload ? [] : state.menuTemp,
        screenMenuItemsList: action.payload ? searchItemsList : [],
        // screenMenuItemsList: [],
        loading: false
      };
    case GET_MENU_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_MENU_ITEMS_BY_SCREEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_MENU_ITEMS_BY_SCREEN_SUCCESS:
      return {
        ...state,
        screenMenuItemsList: arrayOperations.converHashTableToArray(state.menuCardList[action.payload] || []),
        loading: false
      };
    case ADD_TO_WISH_LIST_REQUESTED:
      return {
        ...state,
        addWishlistLoader: true
      };
    case ADD_TO_WISH_LIST_SUCCESS:
      window.sessionStorage.setItem('wishList', JSON.stringify([...state.wishList, action.payload]));
      action.resolve();
      return {
        ...state,
        wishList: [...state.wishList, action.payload],
        addWishlistLoader: false
      };
    case REMOVE_ITEM_FROM_WISH_LIST_REQUESTED:
      return {
        ...state,
        addWishlistLoader: true
      };
    case REMOVE_ITEM_FROM_WISH_LIST_SUCCESS:
      wishListTemp = [...state.wishList];
      _.remove(wishListTemp, (d) => d.menuItemId === action.payload);
      window.sessionStorage.setItem('wishList', JSON.stringify([...wishListTemp]));
      return {
        ...state,
        wishList: wishListTemp,
        addWishlistLoader: false
      };
    case CLEAR_BROWSE_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case CLEAR_BROWSE_SUCCESS:
      window.sessionStorage.removeItem('wishList');
      window.sessionStorage.removeItem('browseMenu');
      action.resolve();
      return {
        loading: false,
        addWishlistLoader: false,
        loaded: true,
        error: false,
        modifierLoder: false,
        wishList: [],
        menus: [],
        menuItems: [],
        menusList: [],
        screenMenuItemsList: [],
        menuIndex: 0
      };
    case ADD_TO_FAVOURITE_REQUESTED:
      return {
        ...state,
        addToFavouriteBrowseLoader: true
      };
    case ADD_TO_FAVOURITE_SUCCESS:
      favouriteTempArray = [...(state.favouriteBrowseItems || []), { ...action.payload }];
      favItemIndex = _.findIndex(favouriteTempArray, (d) => d.menuItemId === action.payload.menuItemId);
      favouriteTempArray[favItemIndex].menuItem.favorite = action.result;
      window.sessionStorage.setItem('favouriteBrowseItems', JSON.stringify([...favouriteTempArray]));
      return {
        ...state,
        favouriteBrowseItems: favouriteTempArray,
        addToFavouriteBrowseLoader: false
      };
    case ADD_TO_FAVOURITE_FAILURE:
      return {
        ...state,
        addToFavouriteBrowseLoader: false
      };
    case REMOVE_FROM_FAVOURITE_REQUESTED:
      return {
        ...state,
        addToFavouriteBrowseLoader: true
      };
    case REMOVE_FROM_FAVOURITE_SUCCESS:
      favouriteTemp = [...state.favouriteBrowseItems];
      favTempItemIndex = _.findIndex(favouriteTemp, (d) => d.menuItem.favorite.id === action.payload);
      favouriteTemp[favTempItemIndex].menuItem.favorite = null;
      _.remove(favouriteTemp, { menuItemId: favouriteTemp[favTempItemIndex].menuItemId });
      window.sessionStorage.setItem('favouriteBrowseItems', JSON.stringify([...favouriteTemp]));
      return {
        ...state,
        favouriteBrowseItems: favouriteTemp,
        addToFavouriteBrowseLoader: false
      };
    case REMOVE_FROM_FAVOURITE_FAILURE:
      return {
        ...state,
        addToFavouriteBrowseLoader: false
      };
    case UPDATE_FAVOURITE:
      window.sessionStorage.setItem('favouriteBrowseItems', JSON.stringify([...(state.favouriteBrowseItems || []), action.payload]));
      return {
        ...state,
        favouriteBrowseItems: [...(state.favouriteBrowseItems || []), action.payload],
        addToFavouriteBrowseLoader: false
      };
    case ADD_TO_FAVOURITE_GUEST_REQUESTED:
      return {
        ...state,
        addToFavouriteBrowseLoader: true
      };
    case ADD_TO_FAVOURITE_GUEST_SUCCESS:
      favouriteTempArray = [...(state.favouriteBrowseItems || []), { ...action.payload }];
      window.sessionStorage.setItem('favouriteBrowseItems', JSON.stringify([...favouriteTempArray]));
      return {
        ...state,
        favouriteBrowseItems: favouriteTempArray,
        addToFavouriteBrowseLoader: false
      };
    case REMOVE_FROM_FAVOURITE_GUEST_REQUESTED:
      return {
        ...state,
        addToFavouriteBrowseLoader: true
      };
    case REMOVE_FROM_FAVOURITE_GUEST_SUCCESS:
      favouriteTemp = [...state.favouriteBrowseItems];
      favTempItemIndex = _.findIndex(favouriteTemp, (d) => d.menuItemId === action.payload.menuItemId);
      _.remove(favouriteTemp, { menuItemId: favouriteTemp[favTempItemIndex].menuItemId });
      window.sessionStorage.setItem('favouriteBrowseItems', JSON.stringify([...favouriteTemp]));
      return {
        ...state,
        favouriteBrowseItems: favouriteTemp,
        addToFavouriteBrowseLoader: false
      };
    default:
      return state;
  }
};

export const getBrowseMenu = (storeId, flowType) => {
  if (!window.sessionStorage.getItem('browseMenu') || JSON.parse(window.sessionStorage.getItem('storeId')) !== storeId) {
    window.sessionStorage.removeItem('wishList');
    window.sessionStorage.setItem('storeId', storeId);
    const preview = window.localStorage.getItem('isPreviewMode');
    const menuId = window.sessionStorage.getItem('MenuId');
    let dataToSend = {};
    if (preview) {
      dataToSend = {
        storeId,
        flowType,
        preview,
        menuId,
      };
    } else {
      dataToSend = {
        storeId,
        flowType,
      };
    }

    return {
      types: [GET_MENU_REQUESTED, GET_MENU_SUCCESS, GET_MENU_FAILURE],
      promise: (client) => client.post('getMenuCategory', {
        data: dataToSend
      })
    };
  }
  return (dispatch) => dispatch({
    type: GET_MENU_FROM_SESSION
  });
};

export const changeMenuByIndex = (menuIndex) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CHANGE_MENU_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: CHANGE_MENU_SUCCESS,
          payload: menuIndex,
          resolve,
          reject
        });
      }, 100);
    });
  };
};

export const pushMenuItemsByScreenId = (screenId) => {
  return (dispatch) => {
    dispatch({
      type: GET_MENU_ITEMS_BY_SCREEN_REQUEST
    });
    setTimeout(() => {
      dispatch({
        type: GET_MENU_ITEMS_BY_SCREEN_SUCCESS,
        payload: screenId
      });
    }, 100);
  };
};

export const addToWishList = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TO_WISH_LIST_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: ADD_TO_WISH_LIST_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const removeItemWishList = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_ITEM_FROM_WISH_LIST_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_ITEM_FROM_WISH_LIST_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const searchMenuCategory = (searchString) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: SEARCH_CATEGORY_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: SEARCH_CATEGORY_SUCCESS,
          payload: searchString,
          resolve,
          reject
        });
      }, 10);
    });
  };
};

export const clearBrowseData = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: CLEAR_BROWSE_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR_BROWSE_SUCCESS,
          resolve,
          reject
        });
      }, 10);
    });
  };
};

export const handleAddFavourite = (data) => {
  const dataToSend = {
    menuItemId: data.menuItemId,
    orderItemJson: null
  };
  return {
    types: [ADD_TO_FAVOURITE_REQUESTED, ADD_TO_FAVOURITE_SUCCESS, ADD_TO_FAVOURITE_FAILURE],
    promise: (client) => client.post('addFavourite', {
      data: dataToSend
    }),
    payload: data,
  };
};

export const handleRemoveFavourite = (data) => {
  return {
    types: [REMOVE_FROM_FAVOURITE_REQUESTED, REMOVE_FROM_FAVOURITE_SUCCESS, REMOVE_FROM_FAVOURITE_FAILURE],
    promise: (client) => client.post(`removeFavourite/${data}`),
    payload: data
  };
};

export const updateFavourite = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FAVOURITE,
      payload: data
    });
  };
};

export const handleGuestAddFavourite = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: ADD_TO_FAVOURITE_GUEST_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: ADD_TO_FAVOURITE_GUEST_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};

export const handleGuestRemoveFavourite = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: REMOVE_FROM_FAVOURITE_GUEST_REQUESTED
      });
      setTimeout(() => {
        dispatch({
          type: REMOVE_FROM_FAVOURITE_GUEST_SUCCESS,
          payload: data,
          resolve,
          reject
        });
      }, 300);
    });
  };
};
