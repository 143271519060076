export const SET_MODAL_NOTIFICATION = 'modalNotification/SET_MODAL_NOTIFICATION';
export const CLOSE_MODAL_NOTIFICATION = 'modalNotification/CLOSE_MODAL_NOTIFICATION';

const initialValues = {
  isModalOpen: false,
  message: '',
  customClass: '',
  isBtnClicked: false,
  authData: {},
  device: ''
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case SET_MODAL_NOTIFICATION:
      return {
        ...state,
        isModalOpen: true,
        message: action.payload.message,
        authData: (JSON.parse(window.sessionStorage.getItem('auth')) || {}),
        device: (JSON.parse(window.sessionStorage.getItem('deviceInfo')) || {}).deviceName,
        type: action.payload.type,
        isBtnClicked: true,
      };
    case CLOSE_MODAL_NOTIFICATION: {
      return {
        ...state,
        isModalOpen: false,
        isBtnClicked: false
      };
    }
    default:
      return state;
  }
};

export const setModalNotification = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_MODAL_NOTIFICATION,
        payload: data
      }
    );
    setTimeout(() => dispatch({
      type: CLOSE_MODAL_NOTIFICATION
    }), 3000);
  };
};

export const openModal = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: SET_MODAL_NOTIFICATION,
        payload: data
      }
    );
  };
};
