import { getHeaders } from '@utility/helperFunction';
import { getObjectValue } from '@utility/utills';

export const CHECK_OPEN_SUCCESS = 'checks/CHECK_OPEN_SUCCESS';
export const CHECK_OPEN_REQUESTED = 'checks/CHECK_OPEN_REQUESTED';
export const CHECK_OPEN_FAILURE = 'checks/CHECK_OPEN_FAILURE';

export const DELIVERYTIME_SUCCESS = 'checks/CHECK_OPEN_SUCCESS';
export const DELIVERYTIME_REQUESTED = 'checks/CHECK_OPEN_REQUESTED';
export const DELIVERYTIME_FAILURE = 'checks/CHECK_OPEN_FAILURE';

export const OCCUPY_SEATS_SUCCESS = 'checks/OCCUPY_SEATS_SUCCESS';
export const OCCUPY_SEATS_REQUESTED = 'checks/OCCUPY_SEATS_REQUESTED';
export const OCCUPY_SEATS_FAILURE = 'checks/OCCUPY_SEATS_FAILURE';

export const CALL_SERVER_REQUESTED = 'checks/CHECK_SERVER_REQUESTED';
export const CALL_SERVER_FAILURE = 'checks/CHECK_SERVER_FAILURE';
export const CALL_SERVER_SUCCESS = 'checks/CHECK_SERVER_SUCCESS';

export const SET_ASSISSTANCE_BTN = 'checks/SET_ASSISSTANCE_BTN';

export const SEND_QR_REQUESTED = 'checks/SEND_QR_REQUESTED';
export const SEND_QR_FAILURE = 'checks/SEND_QR_FAILURE';
export const SEND_QR_SUCCESS = 'checks/SEND_QR_SUCCESS';

export const PRINT_TABLE_QR_REQUESTED = 'checks/PRINT_TABLE_QR_REQUESTED';
export const PRINT_TABLE_QR_FAILURE = 'checks/PRINT_TABLE_QR_FAILURE';
export const PRINT_TABLE_QR_SUCCESS = 'checks/PRINT_TABLE_QR_SUCCESS';

export const MERGE_OPENCHECK_REQUESTED = 'checks/MERGE_OPENCHECK_REQUESTED';
export const MERGE_OPENCHECK_FAILURE = 'checks/MERGE_OPENCHECK_FAILURE';
export const MERGE_OPENCHECK_SUCCESS = 'checks/MERGE_OPENCHECK_SUCCESS';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  isAssisstanceCalled: getObjectValue(() => window.sessionStorage.getItem('isAssisstanceCalled'), false)
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_OPEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case CHECK_OPEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CHECK_OPEN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DELIVERYTIME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DELIVERYTIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DELIVERYTIME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case OCCUPY_SEATS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case OCCUPY_SEATS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case OCCUPY_SEATS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case CALL_SERVER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case CALL_SERVER_FAILURE: {
      return {
        ...state,
        isAssisstanceCalled: false,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case CALL_SERVER_SUCCESS: {
      window.sessionStorage.setItem('isAssisstanceCalled', true);
      return {
        loading: false,
        isAssisstanceCalled: getObjectValue(() => window.sessionStorage.getItem('isAssisstanceCalled'), true),
        loaded: true,
        error: false,
      };
    }

    case SEND_QR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case SEND_QR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case SEND_QR_SUCCESS: {
      return {
        loading: false,
        loaded: true,
        error: false,
      };
    }

    case PRINT_TABLE_QR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case PRINT_TABLE_QR_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case PRINT_TABLE_QR_SUCCESS: {
      return {
        loading: false,
        loaded: true,
        error: false,
      };
    }

    case MERGE_OPENCHECK_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case MERGE_OPENCHECK_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case MERGE_OPENCHECK_SUCCESS: {
      return {
        loading: false,
        loaded: true,
        error: false,
      };
    }

    case SET_ASSISSTANCE_BTN:
      action.resolve();
      return {
        ...state,
        isAssisstanceCalled: action.payload
      };
    default:
      return state;
  }
};

export const checkOpen = (data, update) => {
  const headers = getHeaders();
  return {
    types: [CHECK_OPEN_REQUESTED, CHECK_OPEN_SUCCESS, CHECK_OPEN_FAILURE],
    promise: (client) => client.post(update? 'checkUpdate':'checkOpen', {
      headers,
      data
    })
  };
};

export const getStorePickDeliveryTimes = (data) => {
  const headers = getHeaders();
  return {
    types: [DELIVERYTIME_REQUESTED, DELIVERYTIME_SUCCESS, DELIVERYTIME_FAILURE],
    promise: (client) => client.post('getStorePickDeliveryTimes', {
      headers,
      data
    })
  };
};

export const occupySeat = (data) => {
  const headers = getHeaders();
  return {
    types: [OCCUPY_SEATS_REQUESTED, OCCUPY_SEATS_SUCCESS, OCCUPY_SEATS_FAILURE],
    promise: (client) => client.post('occupySeat', {
      headers,
      data
    })
  };
};

export const callServer = (data) => {
  const headers = getHeaders();
  return {
    types: [CALL_SERVER_REQUESTED, CALL_SERVER_SUCCESS, CALL_SERVER_FAILURE],
    promise: (client) => client.post('callServer', {
      headers,
      data,
    })
  };
};

export const sendQRLink = (data) => {
  const headers = getHeaders();
  return {
    types: [SEND_QR_REQUESTED, SEND_QR_SUCCESS, SEND_QR_FAILURE],
    promise: (client) => client.post('sendQRLink', {
      headers,
      data,
    })
  };
};

export const printTableQR = (data) => {
  const headers = getHeaders();
  return {
    types: [PRINT_TABLE_QR_REQUESTED, PRINT_TABLE_QR_SUCCESS, PRINT_TABLE_QR_FAILURE],
    promise: (client) => client.post('printTableQR', {
      headers,
      data,
    })
  };
};

export const mergeOpenCheck = (data) => {
  const headers = getHeaders();
  return {
    types: [MERGE_OPENCHECK_REQUESTED, MERGE_OPENCHECK_SUCCESS, MERGE_OPENCHECK_FAILURE],
    promise: (client) => client.post('mergeOpenCheck', {
      headers,
      data,
    })
  };
};

export const setIsAssisstance = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_ASSISSTANCE_BTN,
        resolve,
        payload: data
      });
    });
  };
};
