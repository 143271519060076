import {
  useEffect, useRef,
  useState
} from 'react';
import moment from 'moment';

const DateTime = (shouldStartTimer = false) => {
  const timeoutId = useRef(null);
  const [Date, setDate] = useState(() => {
    return moment().format('dddd, MMMM D');
  });
  const [Time, setTime] = useState(() => {
    return moment().format('hh:mm a');
  });
  const timer = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      const newDate = moment().format('dddd, MMMM D');
      const newTime = moment().format('hh:mm a');
      setDate(newDate);
      setTime(newTime);
      return timer();
    }, 1000);
  };

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    if (shouldStartTimer) {
      timer();
    }
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [shouldStartTimer]);
  return [Time, Date];
};

export default DateTime;
