import React from "react";
import { Navigate, Outlet}  from 'react-router-dom';
import { getObjectValue } from '@utility/utills';

const privateRoute = () => {
    const userInfo = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('userInfo'), false));
    return userInfo ? (
        <Outlet/>
    ) : (
            <Navigate to="/checkIn" />
    )
}
export default privateRoute;