import { getHeaders } from '@utility/helperFunction';

export const EMPLOYEE_CASHOUT_SUCCESS = 'cashOut/EMPLOYEE_CASHOUT_SUCCESS';
export const EMPLOYEE_CASHOUT_REQUESTED = 'cashOut/EMPLOYEE_CASHOUT_REQUESTED';
export const EMPLOYEE_CASHOUT_FAILURE = 'cashOut/EMPLOYEE_CASHOUT_FAILURE';

export const UPDATE_EMPLOYEE_CASHOUT_SUCCESS = 'cashOut/UPDATE_EMPLOYEE_CASHOUT_SUCCESS';
export const UPDATE_EMPLOYEE_CASHOUT_REQUESTED = 'cashOut/UPDATE_EMPLOYEE_CASHOUT_REQUESTED';
export const UPDATE_EMPLOYEE_CASHOUT_FAILURE = 'cashOut/UPDATE_EMPLOYEE_CASHOUT_FAILURE';

export const DRAWER_CASHOUT_SUCCESS = 'cashOut/DRAWER_CASHOUT_SUCCESS';
export const DRAWER_CASHOUT_REQUESTED = 'cashOut/DRAWER_CASHOUT_REQUESTED';
export const DRAWER_CASHOUT_FAILURE = 'cashOut/DRAWER_CASHOUT_FAILURE';

export const UPDATE_DRAWER_CASHOUT_SUCCESS = 'cashOut/UPDATE_DRAWER_CASHOUT_SUCCESS';
export const UPDATE_DRAWER_CASHOUT_REQUESTED = 'cashOut/UPDATE_DRAWER_CASHOUT_REQUESTED';
export const UPDATE_DRAWER_CASHOUT_FAILURE = 'cashOut/UPDATE_DRAWER_CASHOUT_FAILURE';

export const EMPLOYEE_CASHIN_SUCCESS = 'cashOut/EMPLOYEE_CASHIN_SUCCESS';
export const EMPLOYEE_CASHIN_REQUESTED = 'cashOut/EMPLOYEE_CASHIN_REQUESTED';
export const EMPLOYEE_CASHIN_FAILURE = 'cashOut/EMPLOYEE_CASHIN_FAILURE';

export const UPDATE_EMPLOYEE_CASHIN_SUCCESS = 'cashOut/UPDATE_EMPLOYEE_CASHIN_SUCCESS';
export const UPDATE_EMPLOYEE_CASHIN_REQUESTED = 'cashOut/UPDATE_EMPLOYEE_CASHIN_REQUESTED';
export const UPDATE_EMPLOYEE_CASHIN_FAILURE = 'cashOut/UPDATE_EMPLOYEE_CASHIN_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_CASHOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case EMPLOYEE_CASHOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case EMPLOYEE_CASHOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case EMPLOYEE_CASHIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case EMPLOYEE_CASHIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case EMPLOYEE_CASHIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case UPDATE_EMPLOYEE_CASHIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_EMPLOYEE_CASHIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_EMPLOYEE_CASHIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case UPDATE_EMPLOYEE_CASHOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_EMPLOYEE_CASHOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_EMPLOYEE_CASHOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DRAWER_CASHOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case DRAWER_CASHOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DRAWER_CASHOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_DRAWER_CASHOUT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case UPDATE_DRAWER_CASHOUT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case UPDATE_DRAWER_CASHOUT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    default:
      return state;
  }
};

export const employeeCashOut = (data) => {
  const headers = getHeaders();
  return {
    types: [EMPLOYEE_CASHOUT_REQUESTED, EMPLOYEE_CASHOUT_SUCCESS, EMPLOYEE_CASHOUT_FAILURE],
    promise: (client) => client.post('employeeCashOut', {
      headers,
      data
    })
  };
};

export const getCashIn = (data) => {
  const headers = getHeaders();
  return {
    types: [EMPLOYEE_CASHIN_REQUESTED, EMPLOYEE_CASHIN_SUCCESS, EMPLOYEE_CASHIN_FAILURE],
    promise: (client) => client.post('getCashIn', {
      headers,
      data
    })
  };
};

export const updateCashIn = (data) => {
  const headers = getHeaders();
  return {
    types: [UPDATE_EMPLOYEE_CASHIN_REQUESTED, UPDATE_EMPLOYEE_CASHIN_SUCCESS, UPDATE_EMPLOYEE_CASHIN_FAILURE],
    promise: (client) => client.post('updateCashIn', {
      headers,
      data
    })
  };
};

export const drawerCashOut = (data) => {
  const headers = getHeaders();
  return {
    types: [DRAWER_CASHOUT_REQUESTED, DRAWER_CASHOUT_SUCCESS, DRAWER_CASHOUT_FAILURE],
    promise: (client) => client.post('drawerCashOut', {
      headers,
      data
    })
  };
};

export const updateEmployeeCashOut = (data) => {
  const headers = getHeaders();
  return {
    types: [UPDATE_EMPLOYEE_CASHOUT_REQUESTED, UPDATE_EMPLOYEE_CASHOUT_SUCCESS, UPDATE_EMPLOYEE_CASHOUT_FAILURE],
    promise: (client) => client.post('updateEmployeeCashOut', {
      headers,
      data
    })
  };
};

export const updateDrawerCashOut = (data) => {
  const headers = getHeaders();
  return {
    types: [UPDATE_DRAWER_CASHOUT_REQUESTED, UPDATE_DRAWER_CASHOUT_SUCCESS, UPDATE_DRAWER_CASHOUT_FAILURE],
    promise: (client) => client.post('updateDrawerCashOut', {
      headers,
      data
    })
  };
};
