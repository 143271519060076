import moment from 'moment';

export const GET_KDS_CHECKLIST = 'kds/GET_KDS_CHECKLIST';
export const GET_KDS_CHECK_PREP_LIST = 'kds/GET_KDS_CHECK_PREP_LIST';
export const UPDATE_PREP_OBJECT = 'kds/UPDATE_PREP_OBJECT';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  expoObject: {},
  prepObject: {},
  kdsObject: {},
  kdsExpoObject: {},
  inActiveData:{},
  lastHourCount: 0,
  lastCheckDate: '',
  footerItemSelectionId: '',
};

const checkLastHour = (date) => {
  const momentTime = moment.utc(date).local();
  const currentTime = moment();
  const diffTime = +(Math.abs(currentTime.diff(momentTime, 'minutes', true))).toFixed(2);
  return diffTime < 60;
};

let prepObject = {};
const addModifiersToPrepObject = (modifiers, itemsPerPage, checkHeader, currentItemType) => {
  (modifiers || []).forEach((modifier) => {
    const groupedModifierHashTable = {};
    let itemCount = 0;
    const modifierObj = {
      ...modifier,
      id: modifier.modifierId,
      menuItemName: modifier.modifierName,
      type: 'modifier',
      displayLabel: 'Modifier',
      show: (currentItemType === 'all') || (currentItemType === 'modifier'),
      menuItemKitchenName: modifier.modifierName,
    };
    [...(prepObject[modifier.modifierId]?.items || []), modifierObj].forEach((x, modIndex) => {
      const modifierIndex = Math.floor(modIndex / itemsPerPage);
      itemCount += x.quantity;
      (groupedModifierHashTable[modifierIndex] = groupedModifierHashTable[modifierIndex] || []).push({ ...x, index: modIndex });
    });
    prepObject[modifier.modifierId] = {
      id: modifier.modifierId,
      name: modifier.modifierName,
      itemCount,
      type: 'modifier',
      displayLabel: 'Modifier',
      checkHeader,
      show: (currentItemType === 'all') || (currentItemType === 'modifier'),
      items: [...(prepObject[modifier.modifierId]?.items || []), modifierObj],
      groupedItems: groupedModifierHashTable
    };
    if (modifier?.modifiers?.length > 0) {
      return addModifiersToPrepObject(modifier.modifiers, itemsPerPage, checkHeader);
    }
    return '';
  });
};
const getList = (checklist, checkPerPage, itemsPerPage, currentItemType) => {
  const expoObject = {};
  let lastHourCount = 0;
  let lastCheckDate = '';
  prepObject = {};
  if (checklist?.length > 0) {
    checklist.forEach((v, index) => {
      const page = Math.floor(index / checkPerPage);
      (expoObject[page] = expoObject[page] || []).push({ ...v, index });
      if (checkLastHour(v.openDate)) {
        lastHourCount += 1;
        lastCheckDate = v.openDate;
      }
      if (v.Items?.length > 0) {
        v.Items.forEach((item) => {
          const groupedItemHashTable = {};
          let itemCount = 0;
          [...(prepObject[item.menuItemId]?.items || []), item].forEach((x, i) => {
            const itemIndex = Math.floor(i / itemsPerPage);
            itemCount += x.quantity;
            (groupedItemHashTable[itemIndex] = groupedItemHashTable[itemIndex] || []).push({
              ...x,
              index: i,
              type: 'menuItem',
              displayLabel: 'Menu Item',
              show: (currentItemType === 'all') || (currentItemType === 'menuItem'),
            });
          });
          if (item.modifiers?.length > 0) {
            addModifiersToPrepObject(item.modifiers, itemsPerPage, v, currentItemType);
          }
          prepObject[item.menuItemId] = {
            id: item.menuItemId,
            name: item.menuItemName,
            itemCount,
            type: 'menuItem',
            displayLabel: 'Menu Item',
            checkHeader: { ...v },
            show: (currentItemType === 'all') || (currentItemType === 'menuItem'),
            items: [...(prepObject[item.menuItemId]?.items || []), item],
            groupedItems: groupedItemHashTable
          };
        });
      }
    });
  }
  return [expoObject, lastHourCount, lastCheckDate];
};

const getExpoList = (checklist, checkPerPage, itemsPerPage, currentItemType, duplicateDeviceIds) => {
  const expoObject = {};
  let lastHourCount = 0;
  let lastCheckDate = '';
  prepObject = {};
  const deviceInfo = JSON.parse(window.sessionStorage.getItem('deviceInfo'))
  const kdsSortType = deviceInfo?.deviceGroup?.kdsSortType;

  const convertUTCDateToLocalDate = (date) => {
    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
  if (checklist?.length > 0) {
    checklist = checklist.filter((item) => {
      if (item.kdsStatus < 8) {
        const deviceInfo = JSON.parse(window.sessionStorage.getItem('deviceInfo'))
        if (deviceInfo?.kdsMirrorDeviceId == null || deviceInfo?.kdsMirrorDeviceId == undefined) {
          if (duplicateDeviceIds[item.expoDeviceId]) {
            return true
          } else {
            return false
          }
        } else {
          if (duplicateDeviceIds[item.expoDeviceId]) {
            return true
          } else {
            return false
          }
        }
      }
    })

    const kdsSortLogic = (a,b) => {
      if(kdsSortType === 1){
        return (a?.plannedStartDate < b?.plannedStartDate) ? -1 : (a?.plannedStartDate > b?.plannedStartDate) ? 1 : 0;
      }
      else if(kdsSortType === 2){
        return (a?.plannedCompleteDate < b?.plannedCompleteDate) ? -1 : (a?.plannedCompleteDate > b?.plannedCompleteDate) ? 1 : 0;
      }
      else {
        return a?.sortOrder - b?.sortOrder
      }
    }
    
    // if(kdsSortType === 0){
    //   checklist?.sort((a, b) => a?.sortOrder - b?.sortOrder);
    // }
    // else {
      const prioritizedCheckArr = checklist?.filter(el => el.rePrioritized)?.sort((a, b) => kdsSortLogic(a, b));
      const nonPriorityCheckArr = checklist?.filter(el => !el.rePrioritized)?.sort((a, b) => kdsSortLogic(a, b))
  
      checklist = [...prioritizedCheckArr, ...nonPriorityCheckArr];
    // }

    checklist.forEach((v, index) => {
      const page = Math.floor(index / checkPerPage);
      (expoObject[page] = expoObject[page] || []).push({ ...v, index });
      if (checkLastHour(v.openDate)) {
        lastHourCount += 1;
        lastCheckDate = v.openDate;
      }
      if (v.Items?.length > 0) {
        v.Items.forEach((item) => {
          const groupedItemHashTable = {};
          let itemCount = 0;
          [...(prepObject[item.menuItemId]?.items || []), item].forEach((x, i) => {
            const itemIndex = Math.floor(i / itemsPerPage);
            itemCount += x.quantity;
            (groupedItemHashTable[itemIndex] = groupedItemHashTable[itemIndex] || []).push({
              ...x,
              index: i,
              type: 'menuItem',
              displayLabel: 'Menu Item',
              show: (currentItemType === 'all') || (currentItemType === 'menuItem'),
            });
          });
          if (item.modifiers?.length > 0) {
            addModifiersToPrepObject(item.modifiers, itemsPerPage, v, currentItemType);
          }
          prepObject[item.menuItemId] = {
            id: item.menuItemId,
            name: item.menuItemName,
            itemCount,
            type: 'menuItem',
            displayLabel: 'Menu Item',
            checkHeader: { ...v },
            show: (currentItemType === 'all') || (currentItemType === 'menuItem'),
            items: [...(prepObject[item.menuItemId]?.items || []), item],
            groupedItems: groupedItemHashTable
          };
        });
      }
    });
  }
  return [checklist, lastHourCount, lastCheckDate];
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_KDS_CHECKLIST: {
      const {
        checklist, checkPerPage, itemsPerPage,
        currentItemType,
      } = action.payload;
      const [expoObject, lastHourCount, lastCheckDate] = getList(checklist, checkPerPage, itemsPerPage, currentItemType);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        expoObject,
        prepObject,
        lastHourCount,
        lastCheckDate,
        footerItemSelectionId: +(Object.keys(prepObject)[0] || '')
      };
    }
    case GET_KDS_CHECK_PREP_LIST: {
      const {
        checklist, checkPerPage, itemsPerPage,
        currentItemType, duplicateDeviceIds
      } = action.payload;
      
      let uniqueArray = checklist.reduce((accumulator, currentValue) => {
        // Check if currentValue already exists in accumulator
        const existingIndex = accumulator.findIndex(value => value === currentValue);
        if (existingIndex !== -1) {
          // Replace the existing value with the old one
          accumulator[existingIndex] = currentValue;
        } else {
          // Add the unique value to the accumulator
          accumulator.push(currentValue);
        }
        return accumulator;
      }, []);
      
      let kdsObject = uniqueArray;
      let allData = uniqueArray;
      let activeData = allData.filter((item) => item.kdsStatus < 8)
      let inActiveData = allData.filter((item) => item.kdsStatus >= 8)
      let [kdsExpoObject, lastHourCount, lastCheckDate] = getExpoList(uniqueArray, checkPerPage, itemsPerPage, currentItemType, duplicateDeviceIds);
      // kdsExpoObject = activeData;
      kdsObject = activeData;
      inActiveData = inActiveData;
      // console.log(activeData, inActiveData, kdsExpoObject, 'hhhhh', kdsObject, checklist);
    
      let obj = {}
      for (let index = 0; index < kdsObject.length; index++) {
        const element = kdsObject[index];
        obj = {
          ...obj,
          [element.checkHeader.id]: {
            ...element,
          }
        }
      }
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        kdsObject,
        kdsExpoObject,
        inActiveData,
        lastHourCount,
        lastCheckDate,
        footerItemSelectionId: +(Object.keys(obj)[0] || '')
      };
    }
    case UPDATE_PREP_OBJECT: {
      const updatedPrepObject = {};
      let selectionId = '';
      Object.values(state.prepObject || {}).forEach((obj) => {
        updatedPrepObject[obj.id] = {
          ...obj,
          show: (action.payload === 'all') || (obj.type === action.payload)
        };
        if (((action.payload === 'all') || (obj.type === action.payload)) && !selectionId) {
          selectionId = obj.id;
        }
      });
      action.resolve(selectionId);
      return {
        ...state,
        prepObject: updatedPrepObject,
        footerItemSelectionId: selectionId
      };
    }
    default:
      return state;
  }
};

export const getKdsCheckList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_KDS_CHECKLIST,
      payload,
    });
  };
};

export const getKdsCheckPrepList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_KDS_CHECK_PREP_LIST,
      payload,
    });
  };
};


export const updatePrepObject = (payload) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_PREP_OBJECT,
        payload,
        resolve
      });
    });
  };
};
