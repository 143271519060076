import { getHeaders } from '@utility/helperFunction';

export const PAYMENT_BY_CARD_REQUESTED = 'PAYMENT_BY_CARD/PAYMENT_BY_CARD_REQUESTED';
export const PAYMENT_BY_CARD_SUCCESS = 'PAYMENT_BY_CARD/PAYMENT_BY_CARD_SUCCESS';
export const PAYMENT_BY_CARD_FAILURE = 'PAYMENT_BY_CARD/PAYMENT_BY_CARD_FAILURE';

export const PAYMENT_BY_APPLE_PAY_REQUESTED = 'PAYMENT_BY_APPLE_PAY/PAYMENT_BY_APPLE_PAY_REQUESTED';
export const PAYMENT_BY_APPLE_PAY_SUCCESS = 'PAYMENT_BY_APPLE_PAY/PAYMENT_BY_APPLE_PAY_SUCCESS';
export const PAYMENT_BY_APPLE_PAY_FAILURE = 'PAYMENT_BY_APPLE_PAY/PAYMENT_BY_APPLE_PAY_FAILURE';

export const GOOGLE_PAY_MERCHANT_INFO_REQUESTED = 'GOOGLE_PAY_MERCHANT_INFO/GOOGLE_PAY_MERCHANT_INFO_REQUESTED';
export const GOOGLE_PAY_MERCHANT_INFO_SUCCESS = 'GOOGLE_PAY_MERCHANT_INFO/GOOGLE_PAY_MERCHANT_INFO_SUCCESS';
export const GOOGLE_PAY_MERCHANT_INFO_FAILURE = 'GOOGLE_PAY_MERCHANT_INFO/GOOGLE_PAY_MERCHANT_INFO_FAILURE';
export const GET_STATE_FROM_SESSION = 'payment/GET_STATE_FROM_SESSION';

export const PAYMENT_INITIALIZATION_REQUESTED = 'PAYMENT_INITIALIZATION/PAYMENT_INITIALIZATION_REQUESTED';
export const PAYMENT_INITIALIZATION_SUCCESS = 'PAYMENT_INITIALIZATION/PAYMENT_INITIALIZATION_SUCCESS';
export const PAYMENT_INITIALIZATION_FAILURE = 'PAYMENT_INITIALIZATION/PAYMENT_INITIALIZATION_FAILURE';

export const PAYMENT_BY_CASH_REQUESTED = 'PAYMENT_BY_CASH/PAYMENT_BY_CASH_REQUESTED';
export const PAYMENT_BY_CASH_SUCCESS = 'PAYMENT_BY_CASH/PAYMENT_BY_CASH_SUCCESS';
export const PAYMENT_BY_CASH_FAILURE = 'PAYMENT_BY_CASH/PAYMENT_BY_CASH_FAILURE';

export const PAYMENT_AUTH_REQUESTED = 'PAYMENT_AUTH/PAYMENT_AUTH_REQUESTED';
export const PAYMENT_AUTH_SUCCESS = 'PAYMENT_AUTH/PAYMENT_AUTH_SUCCESS';
export const PAYMENT_AUTH_FAILURE = 'PAYMENT_AUTH/PAYMENT_AUTH_FAILURE';

export const PAYMENT_SALE_REQUESTED = 'PAYMENT_SALE/PAYMENT_SALE_REQUESTED';
export const PAYMENT_SALE_SUCCESS = 'PAYMENT_SALE/PAYMENT_SALE_SUCCESS';
export const PAYMENT_SALE_FAILURE = 'PAYMENT_SALE/PAYMENT_SALE_FAILURE';

export const PAYMENT_AUTH_ERROR_REQUESTED = 'PAYMENT_AUTH_ERROR/PAYMENT_AUTH_ERROR_REQUESTED';
export const PAYMENT_AUTH_ERROR_SUCCESS = 'PAYMENT_AUTH_ERROR/PAYMENT_AUTH_ERROR_SUCCESS';
export const PAYMENT_AUTH_ERROR_FAILURE = 'PAYMENT_AUTH_ERROR/PAYMENT_AUTH_ERROR_FAILURE';

export const PAYMENT_PREAUTH_ERROR_REQUESTED = 'PAYMENT_PREAUTH_ERROR/PAYMENT_PREAUTH_ERROR_REQUESTED';
export const PAYMENT_PREAUTH_ERROR_SUCCESS = 'PAYMENT_PREAUTH_ERROR/PAYMENT_PREAUTH_ERROR_SUCCESS';
export const PAYMENT_PREAUTH_ERROR_FAILURE = 'PAYMENT_PREAUTH_ERROR/PAYMENT_PREAUTH_ERROR_FAILURE';

export const PAYMENT_AUTH_COMPLETE_REQUESTED = 'PAYMENT_AUTH_COMPLETE/PAYMENT_AUTH_COMPLETE_REQUESTED';
export const PAYMENT_AUTH_COMPLETE_SUCCESS = 'PAYMENT_AUTH_COMPLETE/PAYMENT_AUTH_COMPLETE_SUCCESS';
export const PAYMENT_AUTH_COMPLETE_FAILURE = 'PAYMENT_AUTH_COMPLETE/PAYMENT_AUTH_COMPLETE_FAILURE';

export const RECEIPT_EMAIL_REQUESTED = 'RECEIPT_EMAIL/RECEIPT_EMAIL_REQUESTED';
export const RECEIPT_EMAIL_SUCCESS = 'RECEIPT_EMAIL/RECEIPT_EMAIL_SUCCESS';
export const RECEIPT_EMAIL_FAILURE = 'RECEIPT_EMAIL/RECEIPT_EMAIL_FAILURE';

export const RECEIPT_SMS_REQUESTED = 'RECEIPT_SMS/RECEIPT_SMS_REQUESTED';
export const RECEIPT_SMS_SUCCESS = 'RECEIPT_SMS/RECEIPT_SMS_SUCCESS';
export const RECEIPT_SMS_FAILURE = 'RECEIPT_SMS/RECEIPT_SMS_FAILURE';

export const PAYMENT_SIGN_REQUESTED = 'PAYMENT_SIGN/PAYMENT_SIGN_REQUESTED';
export const PAYMENT_SIGN_SUCCESS = 'PAYMENT_SIGN/PAYMENT_SIGN_SUCCESS';
export const PAYMENT_SIGN_FAILURE = 'PAYMENT_SIGN/PAYMENT_SIGN_FAILURE';

export const SET_AUTH_INITIALIZE_RESPONSE = 'PAYMENT/SET_AUTH_INITIALIZE_RESPONSE';
export const CLEAR_AUTH_RESPONSE = 'PAYMENT/CLEAR_AUTH_RESPONSE';

export const RECEIPT_PRINT_REQUESTED = 'PAYMENT_SIGN/PAYMENT_SIGN_REQUESTED';
export const RECEIPT_PRINT_SUCCESS = 'PAYMENT_SIGN/RECEIPT_PRINT_SUCCESS';
export const RECEIPT_PRINT_FAILURE = 'PAYMENT_SIGN/RECEIPT_PRINT_FAILURE';

export const ORDER_TICKET_PRINT_REQUESTED = 'PAYMENT_SIGN/ORDER_TICKET_PRINT_REQUESTED';
export const ORDER_TICKET_PRINT_SUCCESS = 'PAYMENT_SIGN/ORDER_TICKET_PRINT_SUCCESS';
export const ORDER_TICKET_PRINT_FAILURE = 'PAYMENT_SIGN/ORDER_TICKET_PRINT_FAILURE';

export const GIFT_CARD_BALANCE_REQUESTED = 'GIFT_CARD/GIFT_CARD_BALANCE_REQUESTED';
export const GIFT_CARD_BALANCE_SUCCESS = 'GIFT_CARD/GIFT_CARD_BALANCE_SUCCESS';
export const GIFT_CARD_BALANCE_FAILURE = 'GIFT_CARD/GIFT_CARD_BALANCE_FAILURE';

export const GAME_CARD_PROCESS_REQUESTED = 'GAME_CARD/GAME_CARD_PROCESS_REQUESTED';
export const GAME_CARD_PROCESS_SUCCESS = 'GAME_CARD/GAME_CARD_PROCESS_SUCCESS';
export const GAME_CARD_PROCESS_FAILURE = 'GAME_CARD/GAME_CARD_PROCESS_FAILURE';

export const GAME_CARD_VALIDATE_REQUESTED = 'GAME_CARD/GAME_CARD_VALIDATE_REQUESTED';
export const GAME_CARD_VALIDATE_SUCCESS = 'GAME_CARD/GAME_CARD_VALIDATE_SUCCESS';
export const GAME_CARD_VALIDATE_FAILURE = 'GAME_CARD/GAME_CARD_VALIDATE_FAILURE';

export const GAME_CARD_BALANCE_REQUESTED = 'GAME_CARD/GAME_CARD_BALANCE_REQUESTED';
export const GAME_CARD_BALANCE_SUCCESS = 'GAME_CARD/GAME_CARD_BALANCE_SUCCESS';
export const GAME_CARD_BALANCE_FAILURE = 'GAME_CARD/GAME_CARD_BALANCE_FAILURE';

export const GIFT_CARD_PAYMENT_INIT_REQUEST = 'GIFT_CARD/GIFT_CARD_PAYMENT_INIT_REQUEST';
export const GIFT_CARD_PAYMENT_INIT_SUCCESS = 'GIFT_CARD/GIFT_CARD_PAYMENT_INIT_SUCCESS';
export const GIFT_CARD_PAYMENT_INIT_FAILURE = 'GIFT_CARD/GIFT_CARD_PAYMENT_INIT_FAILURE';

export const GIFT_CARD_PAYMENT_REQUEST = 'GIFT_CARD/GIFT_CARD_PAYMENT_REQUEST';
export const GIFT_CARD_PAYMENT_SUCCESS = 'GIFT_CARD/GIFT_CARD_PAYMENT_SUCCESS';
export const GIFT_CARD_PAYMENT_FAILURE = 'GIFT_CARD/GIFT_CARD_PAYMENT_FAILURE';

export const GIFT_CARD_IMAGE_REQUEST = 'GIFT_CARD/GIFT_CARD_IMAGE_REQUEST';
export const GIFT_CARD_IMAGE_SUCCESS = 'GIFT_CARD/GIFT_CARD_IMAGE_SUCCESS';
export const GIFT_CARD_IMAGE_FAILURE = 'GIFT_CARD/GIFT_CARD_IMAGE_FAILURE';

export const TEXT_TO_PAY_REQUEST = 'TEXT_TO_PAY/TEXT_TO_PAY_REQUESTED';
export const TEXT_TO_PAY_SUCCESS = 'TEXT_TO_PAY/TEXT_TO_PAY_SUCCESS';
export const TEXT_TO_PAY_FAILURE = 'TEXT_TO_PAY/TEXT_TO_PAY_FAILURE';

export const SET_PAYMENT_TYPE = 'payment/SET_PAYMENT_TYPE';

export const INGENICO_SALE_REQUESTED = 'payment/INGENICO_SALE_REQUESTED';
export const INGENICO_SALE_SUCCESS = 'payment/INGENICO_SALE_SUCCESS';
export const INGENICO_SALE_FAILURE = 'payment/INGENICO_SALE_FAILURE';

export const INGENICO_LOG_REQUESTED = 'payment/INGENICO_LOG_REQUESTED';
export const INGENICO_LOG_SUCCESS = 'payment/INGENICO_LOG_SUCCESS';
export const INGENICO_LOG_FAILURE = 'payment/INGENICO_LOG_FAILURE';

export const GET_TIPS_REQUESTED = 'payment/GET_TIPS_REQUESTED';
export const GET_TIPS_SUCCESS = 'payment/INGENICO_LOG_GET_TIPS_SUCCESS';
export const GET_TIPS_FAILURE = 'payment/GET_TIPS_FAILURE';

export const SET_OFFER_AVAILABLE = 'payment/SET_OFFER_AVAILABLE';

export const GIFT_CARD_SWIPE_REQUESTED = 'payment/GIFT_CARD_SWIPE_REQUESTED';
export const GIFT_CARD_SWIPE_SUCCESS = 'payment/GIFT_CARD_SWIPE_SUCCESS';
export const GIFT_CARD_SWIPE_FAILURE = 'payment/GIFT_CARD_SWIPE_FAILURE';

export const PAYMENT_SPLIT_REQUESTED = 'PAYMENT_SALE/PAYMENT_SPLIT_REQUESTED';
export const PAYMENT_SPLIT_SUCCESS = 'PAYMENT_SALE/PAYMENT_SPLIT_SUCCESS';
export const PAYMENT_SPLIT_FAILURE = 'PAYMENT_SALE/PAYMENT_SPLIT_FAILURE';

export const PAYMENT_CLEAR_SPLIT_REQUESTED = 'PAYMENT_SALE/PAYMENT_CLEAR_SPLIT_REQUESTED';
export const PAYMENT_CLEAR_SPLIT_SUCCESS = 'PAYMENT_SALE/PAYMENT_CLEAR_SPLIT_SUCCESS';
export const PAYMENT_CLEAR_SPLIT_FAILURE = 'PAYMENT_SALE/PAYMENT_CLEAR_SPLIT_FAILURE';

export const APPLY_TIP_REQUESTED = 'payment/APPLY_TIP_REQUESTED';
export const APPLY_TIP_SUCCESS = 'payment/APPLY_TIP_SUCCESS';
export const APPLY_TIP_FAILURE = 'payment/APPLY_TIP_FAILURE';

export const NEW_GIFT_CARD_BALANCE_REQUESTED = 'GIFT_CARD/NEW_GIFT_CARD_BALANCE_REQUESTED';
export const NEW_GIFT_CARD_BALANCE_SUCCESS = 'GIFT_CARD/NEW_GIFT_CARD_BALANCE_SUCCESS';
export const NEW_GIFT_CARD_BALANCE_FAILURE = 'GIFT_CARD/NEW_GIFT_CARD_BALANCE_FAILURE';

export const GIFT_CARD_DELETEBALANCE_REQUESTED = 'GIFT_CARD/GIFT_CARD_DELETEBALANCE_REQUESTED';
export const GIFT_CARD_DELETEBALANCE_SUCCESS = 'GIFT_CARD/GIFT_CARD_DELETEBALANCE_SUCCESS';
export const GIFT_CARD_DELETEBALANCE_FAILURE = 'GIFT_CARD/GIFT_CARD_DELETEBALANCE_FAILURE';

export const TOGGLE_OTHER_PAYMENT_OPTION = 'payment/TOGGLE_OTHER_PAYMENT_OPTION';
export const TOGGLE_OTHER_PAYMENT_OPTION_FALSE = 'payment/TOGGLE_OTHER_PAYMENT_OPTION_FALSE';

export const CREDIT_CARD_PAYMENT_REQUESTED = 'payment/CREDIT_CARD_PAYMENT_REQUESTED'
export const CREDIT_CARD_PAYMENT_SUCCESS = 'payment/CREDIT_CARD_PAYMENT_SUCCESS'
export const CREDIT_CARD_PAYMENT_FAILURE = 'payment/CREDIT_CARD_PAYMENT_FAILURE'

export const PAYMENT_NEWPAYMENTCOMPLETE_REQUESTED = 'payment/PAYMENT_NEWPAYMENTCOMPLETE_REQUESTED'
export const PAYMENT_NEWPAYMENTCOMPLETE_SUCCESS = 'payment/PAYMENT_NEWPAYMENTCOMPLETE_SUCCESS'
export const PAYMENT_NEWPAYMENTCOMPLETE_FAILURE = 'payment/PAYMENT_NEWPAYMENTCOMPLETE_FAILURE'

export const PAYMENT_NEWPAYMENTERROR_REQUESTED = 'payment/PAYMENT_NEWPAYMENTERROR_REQUESTED'
export const PAYMENT_NEWPAYMENTERROR_SUCCESS = 'payment/PAYMENT_NEWPAYMENTERROR_SUCCESS'
export const PAYMENT_NEWPAYMENTERROR_FAILURE = 'payment/PAYMENT_NEWPAYMENTERROR_FAILURE'

export const CREDIT_CARD_DELETE_REQUESTED = "payment/CREDIT_CARD_DELETE_REQUESTED";
export const CREDIT_CARD_DELETE_SUCCESS = "payment/CREDIT_CARD_DELETE_SUCCESS";
export const CREDIT_CARD_DELETE_FAILURE = "payment/CREDIT_CARD_DELETE_FAILURE";

export const PAYMENT_CASH_REQUESTED = 'PAYMENT_BY_CASH/PAYMENT_CASH_REQUESTED';
export const PAYMENT_CASH_SUCCESS = 'PAYMENT_BY_CASH/PAYMENT_CASH_SUCCESS';
export const PAYMENT_CASH_FAILURE = 'PAYMENT_BY_CASH/PAYMENT_CASH_FAILURE';

export const CASH_CLEAR_BALANCE_REQUESTED = 'GIFT_CARD/CASH_CLEAR_BALANCE_REQUESTED';
export const CASH_CLEAR_BALANCE_SUCCESS = 'GIFT_CARD/CASH_CLEAR_BALANCE_SUCCESS';
export const CASH_CLEAR_BALANCE_FAILURE = 'GIFT_CARD/CASH_CLEAR_BALANCE_FAILURE';

export const TIP_CLEAR_REQUESTED = 'payment/TIP_CLEAR_REQUESTED';
export const TIP_CLEAR_SUCCESS = 'payment/TIP_CLEAR_SUCCESS';
export const TIP_CLEAR_FAILURE = 'payment/TIP_CLEAR_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  googlePayMerchantInfo: JSON.parse(window.sessionStorage.getItem('googlePayMerchantInfo')) || {},
  isPaymentLoading: false,
  authResponse: null,
  giftCard: {},
  pymtType: 0,
  isOfferAvailable: false,
  newGiftCard: {},
  isOtherPaymentOption: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_BY_CARD_REQUESTED: {
      return {
        ...state,
        loading: true,
        isPaymentLoading: true,
        loaded: false
      };
    }
    case PAYMENT_BY_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        isPaymentLoading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_BY_CARD_FAILURE: {
      return {
        ...state,
        loading: false,
        isPaymentLoading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_BY_APPLE_PAY_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: false,
        loaded: false
      };
    }
    case PAYMENT_BY_APPLE_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_BY_APPLE_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case GOOGLE_PAY_MERCHANT_INFO_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GOOGLE_PAY_MERCHANT_INFO_SUCCESS: {
      window.sessionStorage.setItem('googlePayMerchantInfo', JSON.stringify(action.result));
      return {
        ...state,
        googlePayMerchantInfo: action.result,
        loading: false,
        loaded: true
      };
    }
    case GOOGLE_PAY_MERCHANT_INFO_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_INITIALIZATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case PAYMENT_INITIALIZATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false
      };
    }
    case PAYMENT_INITIALIZATION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case PAYMENT_BY_CASH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_BY_CASH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_BY_CASH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_AUTH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_AUTH_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_AUTH_COMPLETE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_AUTH_COMPLETE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_AUTH_COMPLETE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_SALE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_SALE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_SALE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_SPLIT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_SPLIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_SPLIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }
    case PAYMENT_CLEAR_SPLIT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_CLEAR_SPLIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_CLEAR_SPLIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_AUTH_ERROR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_AUTH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_AUTH_ERROR_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_PREAUTH_ERROR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_PREAUTH_ERROR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_PREAUTH_ERROR_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case RECEIPT_EMAIL_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case RECEIPT_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case RECEIPT_EMAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case RECEIPT_SMS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case RECEIPT_SMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case RECEIPT_SMS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case PAYMENT_SIGN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case PAYMENT_SIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case PAYMENT_SIGN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_AUTH_INITIALIZE_RESPONSE: {
      window.sessionStorage.setItem('authResponse', JSON.stringify(action.payload));
      return {
        ...state,
        authResponse: action.payload
      };
    }
    case CLEAR_AUTH_RESPONSE: {
      window.sessionStorage.removeItem('authResponse');
      window.sessionStorage.removeItem('loyaltyData');
      window.sessionStorage.removeItem('giftCardReceiptData');
      window.sessionStorage.removeItem('counterPhoneNumber');
      return {
        ...state,
        authResponse: null
      };
    }

    case RECEIPT_PRINT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case RECEIPT_PRINT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case RECEIPT_PRINT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case ORDER_TICKET_PRINT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case ORDER_TICKET_PRINT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case ORDER_TICKET_PRINT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case GIFT_CARD_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GIFT_CARD_BALANCE_SUCCESS: {
      window.sessionStorage.setItem('giftCard', JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        loaded: true,
        giftCard: JSON.parse(window.sessionStorage.getItem('giftCard'))
      };
    }
    case GIFT_CARD_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
      
    case GAME_CARD_PROCESS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GAME_CARD_PROCESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GAME_CARD_PROCESS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
      
    case GAME_CARD_VALIDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GAME_CARD_VALIDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GAME_CARD_VALIDATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
      
    case GAME_CARD_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GAME_CARD_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GAME_CARD_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

      
      

    case GIFT_CARD_PAYMENT_INIT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GIFT_CARD_PAYMENT_INIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GIFT_CARD_PAYMENT_INIT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case GIFT_CARD_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GIFT_CARD_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GIFT_CARD_PAYMENT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case GIFT_CARD_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GIFT_CARD_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case GIFT_CARD_IMAGE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case TEXT_TO_PAY_REQUEST: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TEXT_TO_PAY_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case TEXT_TO_PAY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }

    case SET_PAYMENT_TYPE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        pymtType: action.payload
      };
    }

    case INGENICO_SALE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case INGENICO_SALE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case INGENICO_SALE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true
      };
    }

    case INGENICO_LOG_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case INGENICO_LOG_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case INGENICO_LOG_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }

    case GET_TIPS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case GET_TIPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case GET_TIPS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
      case TIP_CLEAR_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case TIP_CLEAR_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case TIP_CLEAR_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case SET_OFFER_AVAILABLE: {
      return {
        ...state,
        isOfferAvailable: action.payload
      };
    }
    case GIFT_CARD_SWIPE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }
    }
    case GIFT_CARD_SWIPE_SUCCESS: {
      return {
        ...state, 
        loading: false,
        loaded: true,
        error: false
      }
    }
    case GIFT_CARD_SWIPE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      }
    }
    case APPLY_TIP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }
    case APPLY_TIP_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }
    case APPLY_TIP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case NEW_GIFT_CARD_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case NEW_GIFT_CARD_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        newGiftCard: action.result.paymentCheck.giftCard
      };
    }
    case NEW_GIFT_CARD_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    case GIFT_CARD_DELETEBALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GIFT_CARD_DELETEBALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        newGiftCard: {}
      };
    }
    case GIFT_CARD_DELETEBALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    case TOGGLE_OTHER_PAYMENT_OPTION: {
      return {
        ...state,
        isOtherPaymentOption: true,
      }
    }
    case TOGGLE_OTHER_PAYMENT_OPTION_FALSE: {
      return {
        ...state,
        isOtherPaymentOption: false,
      }
    }
    case CREDIT_CARD_PAYMENT_REQUESTED : {
      return {
        ...state,
        loading:true,
        loaded:false,
        error:false
      }
    }
    case CREDIT_CARD_PAYMENT_SUCCESS : {
      return {
        ...state,
        loading:false,
        loaded:true,
        error:false
      }
    }
    case CREDIT_CARD_PAYMENT_FAILURE : {
      return {
        ...state,
        loading:false,
        loaded:false,
        error:true
      }
    }
    case PAYMENT_NEWPAYMENTCOMPLETE_REQUESTED: {
      return {
        ...state,
        loading:true,
        loaded:false,
        error:false
      }
    }
    case PAYMENT_NEWPAYMENTCOMPLETE_SUCCESS: {
      return {
        ...state,
        loading:false,
        loaded:true,
        error:false
      }
    }
    case PAYMENT_NEWPAYMENTCOMPLETE_FAILURE: {
      return {
        ...state,
        loading:false,
        loaded:false,
        error:true
      }
    }
    case PAYMENT_NEWPAYMENTERROR_REQUESTED: {
      return {
        ...state,
        loading:true,
        loaded:false,
        error:false
      }
    }
    case PAYMENT_NEWPAYMENTERROR_SUCCESS: {
      return {
        ...state,
        loading:false,
        loaded:true,
        error:false
      }
    }
    case PAYMENT_NEWPAYMENTERROR_FAILURE: {
       return {
        ...state,
        loading:false,
        loaded:true,
        error:true
      }
    }
    case CREDIT_CARD_DELETE_REQUESTED: {
      return {
        ...state,
        loading:true,
        loaded:false,
        error:false
      }
    }
    case CREDIT_CARD_DELETE_SUCCESS: {
      return {
        ...state,
        loading:false,
        loaded:true,
        error:false
      }
    }
    case CREDIT_CARD_DELETE_FAILURE: {
       return {
        ...state,
        loading:false,
        loaded:true,
        error:true
      }
    }
    case PAYMENT_CASH_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case PAYMENT_CASH_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case PAYMENT_CASH_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case CASH_CLEAR_BALANCE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error:false
      };
    }
    case CASH_CLEAR_BALANCE_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error:false
      };
    }
    case CASH_CLEAR_BALANCE_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    default:
      return state;
  }
};

export const paymentBycard = (data) => {
  return {
    types: [PAYMENT_BY_CARD_REQUESTED, PAYMENT_BY_CARD_SUCCESS, PAYMENT_BY_CARD_FAILURE],
    promise: (client) => client.post('paymentByCard', {
      data
    })
  };
};

export const itemsOrderPay = (data) => {
  return {
    types: [PAYMENT_BY_CARD_REQUESTED, PAYMENT_BY_CARD_SUCCESS, PAYMENT_BY_CARD_FAILURE],
    promise: (client) => client.post('itemsOrderPay', {
      data
    })
  };
};

export const applePayGetSession = (data) => {
  return {
    types: [PAYMENT_BY_APPLE_PAY_REQUESTED, PAYMENT_BY_APPLE_PAY_SUCCESS, PAYMENT_BY_APPLE_PAY_FAILURE],
    promise: (client) => client.post('applePayGetSession', {
      data
    })
  };
};

export const sendToPaymentGateway = (data) => {
  return {
    types: [PAYMENT_BY_CARD_REQUESTED, PAYMENT_BY_CARD_SUCCESS, PAYMENT_BY_CARD_FAILURE],
    promise: (client) => client.post('sendToPaymentGateway', {
      data
    })
  };
};

export const getGooglePayMerchantInfo = () => {
  if (!window.sessionStorage.getItem('googlePayMerchantInfo')) {
    return {
      types: [GOOGLE_PAY_MERCHANT_INFO_REQUESTED, GOOGLE_PAY_MERCHANT_INFO_SUCCESS, GOOGLE_PAY_MERCHANT_INFO_FAILURE],
      promise: (client) => client.post('getGooglePayMerchantInfo')
    };
  }
  return (dispatch) => dispatch({
    type: GET_STATE_FROM_SESSION
  });
};

export const paymentInitialization = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_INITIALIZATION_REQUESTED, PAYMENT_INITIALIZATION_SUCCESS, PAYMENT_INITIALIZATION_FAILURE],
    promise: (client) => client.post('paymentInitialization', {
      data,
      headers
    })
  };
};

export const paymentCash = (data, id) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_BY_CASH_REQUESTED, PAYMENT_BY_CASH_SUCCESS, PAYMENT_BY_CASH_FAILURE],
    promise: (client) => client.post(id ? 'externalCreditCard' : 'paymentCash', {
      data,
      headers
    })
  };
};

export const paymentAuth = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_AUTH_REQUESTED, PAYMENT_AUTH_SUCCESS, PAYMENT_AUTH_FAILURE],
    promise: (client) => client.post('paymentAuth', {
      data,
      headers
    })
  };
};

export const paymentAuthComplete = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_AUTH_COMPLETE_REQUESTED, PAYMENT_AUTH_COMPLETE_SUCCESS, PAYMENT_AUTH_COMPLETE_FAILURE],
    promise: (client) => client.post('paymentAuthComplete', {
      data,
      headers
    })
  };
};

export const paymentAuthError = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_AUTH_ERROR_REQUESTED, PAYMENT_AUTH_ERROR_SUCCESS, PAYMENT_AUTH_ERROR_FAILURE],
    promise: (client) => client.post('paymentAuthError', {
      data,
      headers
    })
  };
};

export const paymentSale = (data, preAuth) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_SALE_REQUESTED, PAYMENT_SALE_SUCCESS, PAYMENT_SALE_FAILURE],
    promise: (client) => client.post(preAuth ? 'preAuth' : 'paymentSale', {
      data,
      headers
    })
  };
};

export const receiptEmail = (data,value) => {
  const headers = getHeaders();
  return {
    types: [RECEIPT_EMAIL_REQUESTED, RECEIPT_EMAIL_SUCCESS, RECEIPT_EMAIL_FAILURE],
    promise: (client) => client.post(value ?'giftcardreceiptEmail':'receiptEmail', {
      data,
      headers
    })
  };
};

export const textToPay = (data) => {
  const headers = getHeaders();
  return {
    types: [TEXT_TO_PAY_REQUEST, TEXT_TO_PAY_SUCCESS, TEXT_TO_PAY_FAILURE],
    promise: (client) => client.post('textToPay', {
      data,
      headers
    })
  };
};

export const receiptSms = (data,value) => {
  const headers = getHeaders();
  return {
    types: [RECEIPT_SMS_REQUESTED, RECEIPT_SMS_SUCCESS, RECEIPT_SMS_FAILURE],
    promise: (client) => client.post(value ?'giftcardreceiptSms':'receiptSms', {
      data,
      headers
    })
  };
};

export const preAuthPayment = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_PREAUTH_ERROR_REQUESTED, PAYMENT_PREAUTH_ERROR_SUCCESS, PAYMENT_PREAUTH_ERROR_FAILURE],
    promise: (client) => client.post('preAuthPayment', {
      data,
      headers
    })
  };
};

export const paymentSignature = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_SIGN_REQUESTED, PAYMENT_SIGN_SUCCESS, PAYMENT_SIGN_FAILURE],
    promise: (client) => client.post('paymentSignature', {
      data,
      headers
    })
  };
};

export const setAuthResponse = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_AUTH_INITIALIZE_RESPONSE,
        payload: data,
      });
      resolve();
    });
  };
};

export const clearAuthResponse = (data) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_AUTH_RESPONSE,
      payload: data,
    });
  };
};

export const receiptPrint = (data,value) => {
  const headers = getHeaders();
  return {
    types: [RECEIPT_PRINT_REQUESTED, RECEIPT_PRINT_SUCCESS, RECEIPT_PRINT_FAILURE],
    promise: (client) => client.post(value ? 'giftCardReceiptPrint':'receiptPrint', {
      data,
      headers
    })
  };
};

export const orderTicketPrint = (data) => {
  const headers = getHeaders();
  return {
    types: [ORDER_TICKET_PRINT_REQUESTED, ORDER_TICKET_PRINT_SUCCESS, ORDER_TICKET_PRINT_FAILURE],
    promise: (client) => client.post('orderTicketPrint', {
      data,
      headers
    })
  };
};

export const giftCardBalance = (data) => {
  const headers = getHeaders();
  return {
    types: [GIFT_CARD_BALANCE_REQUESTED, GIFT_CARD_BALANCE_SUCCESS, GIFT_CARD_BALANCE_FAILURE],
    promise: (client) => client.post('giftCardBalance', {
      data,
      headers
    }),
    payload: data
  };
};

export const gameCardProcess = (data) => {
  const headers = getHeaders();
  return {
    types: [GAME_CARD_PROCESS_REQUESTED, GAME_CARD_PROCESS_SUCCESS, GAME_CARD_PROCESS_FAILURE],
    promise: (client) => client.post('gameCardProcess', {
      data,
      headers
    }),
    payload: data
  };
};

export const giftCardProcess = (data) => {
  const headers = getHeaders();
  return {
    types: [GAME_CARD_PROCESS_REQUESTED, GAME_CARD_PROCESS_SUCCESS, GAME_CARD_PROCESS_FAILURE],
    promise: (client) => client.post('giftCardProcess', {
      data,
      headers
    }),
    payload: data
  };
};

export const gameCardValidate = (data) => {
  const headers = getHeaders();
  return {
    types: [GAME_CARD_VALIDATE_REQUESTED, GAME_CARD_VALIDATE_SUCCESS, GAME_CARD_VALIDATE_FAILURE],
    promise: (client) => client.post('gameCardvalidate', {
      data,
      headers
    }),
    payload: data
  };
};

export const giftCardValidate = (data) => {
  const headers = getHeaders();
  return {
    types: [GAME_CARD_VALIDATE_REQUESTED, GAME_CARD_VALIDATE_SUCCESS, GAME_CARD_VALIDATE_FAILURE],
    promise: (client) => client.post('giftCardvalidate', {
      data,
      headers
    }),
    payload: data
  };
};
export const gameCardBalance = (data) => {
  const headers = getHeaders();
  return {
    types: [GAME_CARD_BALANCE_REQUESTED, GAME_CARD_BALANCE_SUCCESS, GAME_CARD_BALANCE_FAILURE],
    promise: (client) => client.post('gameCardBalance', {
      data,
      headers
    }),
    payload: data
  };
};

export const giftCardBalances = (data) => {
  const headers = getHeaders();
  return {
    types: [GAME_CARD_BALANCE_REQUESTED, GAME_CARD_BALANCE_SUCCESS, GAME_CARD_BALANCE_FAILURE],
    promise: (client) => client.post('giftCardBalances', {
      data,
      headers
    }),
    payload: data
  };
};

export const giftCardPaymentInit = (data) => {
  const headers = getHeaders();
  return {
    types: [GIFT_CARD_PAYMENT_INIT_REQUEST, GIFT_CARD_PAYMENT_INIT_SUCCESS, GIFT_CARD_PAYMENT_INIT_FAILURE],
    promise: (client) => client.post('giftCardPaymentInit', {
      data,
      headers
    }),
    payload: data
  };
};
export const giftCardSwipe = (data) => {
  const headers = getHeaders();
  return {
    types: [GIFT_CARD_SWIPE_REQUESTED, GIFT_CARD_SWIPE_SUCCESS, GIFT_CARD_SWIPE_FAILURE],
    promise: (client) => client.post('giftCardSwipe', {
      data,
      headers
    }),
    payload: data
  };
};

export const giftCardPayments = (data) => {
  const headers = getHeaders();
  return {
    types: [GIFT_CARD_PAYMENT_REQUEST, GIFT_CARD_PAYMENT_SUCCESS, GIFT_CARD_PAYMENT_FAILURE],
    promise: (client) => client.post('giftCardPayment', {
      data,
      headers
    }),
  };
};

export const giftCardImages = (data) => {
  const headers = getHeaders();
  return {
    types: [GIFT_CARD_IMAGE_REQUEST, GIFT_CARD_IMAGE_SUCCESS, GIFT_CARD_IMAGE_FAILURE],
    promise: (client) => client.post('giftCardImage', {
      data,
      headers
    }),
  };
};

export const setPymtType = (data) => {
  // const headers = getHeaders();
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_PAYMENT_TYPE,
        payload: data,
      });
      resolve();
    });
  };
};

export const ingenicoPayment = (data) => {
  const headers = getHeaders();
  return {
    types: [INGENICO_SALE_REQUESTED, INGENICO_SALE_SUCCESS, INGENICO_SALE_FAILURE],
    promise: (client) => client.post('ingenioPayment', {
      data,
      headers
    })
  };
};

export const ingenicoLog = (data) => {
  const headers = getHeaders();
  return {
    types: [INGENICO_LOG_REQUESTED, INGENICO_LOG_SUCCESS, INGENICO_LOG_FAILURE],
    promise: (client) => client.post('apiLog', {
      data,
      headers
    })
  };
};

export const getTips = (data) => {
  const headers = getHeaders();
  return {
    types: [GET_TIPS_REQUESTED, GET_TIPS_SUCCESS, GET_TIPS_FAILURE],
    promise: (client) => client.post('getTips', {
      data,
      headers
    })
  };
};

export const setOfferAvailable = (data) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_OFFER_AVAILABLE,
        payload: data,
      });
      resolve();
    });
  };
};

export const paymentSplitCheck = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_SPLIT_REQUESTED, PAYMENT_SPLIT_SUCCESS, PAYMENT_SPLIT_FAILURE],
    promise: (client) => client.post('paymentSplitCheck', {
      data,
      headers
    })
  };
};

export const paymentClearSplit = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_CLEAR_SPLIT_REQUESTED, PAYMENT_CLEAR_SPLIT_SUCCESS, PAYMENT_CLEAR_SPLIT_FAILURE],
    promise: (client) => client.post('paymentClearSplit', {
      data,
      headers
    })
  };
};

export const applyTipForPayment = (data) => {
  const headers = getHeaders();
  return {
    types: [APPLY_TIP_REQUESTED, APPLY_TIP_SUCCESS, APPLY_TIP_FAILURE],
    promise: (client) => client.post('applyTip', {
      data,
      headers
    }),
    payload: data
  };
};

export const applyGiftCardBalance = (data) => {
  const headers = getHeaders();
  return {
    types: [NEW_GIFT_CARD_BALANCE_REQUESTED, NEW_GIFT_CARD_BALANCE_SUCCESS, NEW_GIFT_CARD_BALANCE_FAILURE],
    promise: (client) => client.post('applyGiftCardBalance', {
      data,
      headers
    }),
    payload: data
  };
};

export const giftCardDeleteBalance = (data) => {
  const headers = getHeaders();
  return {
    types: [GIFT_CARD_DELETEBALANCE_REQUESTED, GIFT_CARD_DELETEBALANCE_SUCCESS, GIFT_CARD_DELETEBALANCE_FAILURE],
    promise: (client) => client.post('giftCardDeleteBalance', {
      data,
      headers
    }),
    payload: data
  };
};

export const toggleOtherPaymentOption = () => {
  return (dispatch) => {
    dispatch({
      type:TOGGLE_OTHER_PAYMENT_OPTION
    });
  }
}

export const toggleOtherPaymentOptionFalse = () => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_OTHER_PAYMENT_OPTION_FALSE
    });
  }
}

export const payByCreditCard = (data) => {
  const headers = getHeaders();
  return {
    types: [CREDIT_CARD_PAYMENT_REQUESTED, CREDIT_CARD_PAYMENT_SUCCESS, CREDIT_CARD_PAYMENT_FAILURE],
    promise: (client) => client.post('payByCreditCard', {
      data,
      headers
    })
  }
}

export const paymentNewInitialization = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_INITIALIZATION_REQUESTED, PAYMENT_INITIALIZATION_SUCCESS, PAYMENT_INITIALIZATION_FAILURE],
    promise : (client) => client.post('paymentNewInitialization', {
      data,
      headers
    })
  }
};

export const paymentNewPaymentComplete = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_NEWPAYMENTCOMPLETE_REQUESTED, PAYMENT_NEWPAYMENTCOMPLETE_SUCCESS, PAYMENT_NEWPAYMENTCOMPLETE_FAILURE],
    promise: (client) => client.post('paymentNewPaymentComplete', {
      data,
      headers
    })
  }
};

export const paymentNewPaymentError = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_NEWPAYMENTERROR_REQUESTED, PAYMENT_NEWPAYMENTERROR_SUCCESS, PAYMENT_NEWPAYMENTERROR_FAILURE],
    promise : (client) => client.post('paymentNewPaymentError', {
      data,
      headers
    })
  }
}

export const creditCardDeleteBalance = (data) => {
  const headers = getHeaders();
  return {
    types: [CREDIT_CARD_DELETE_REQUESTED, CREDIT_CARD_DELETE_SUCCESS, CREDIT_CARD_DELETE_FAILURE],
    promise: (client) => client.post('creditCardDeleteBalance', {
      data,
      headers
    })
  }
}

export const paymentByCash = (data) => {
  const headers = getHeaders();
  return {
    types: [PAYMENT_CASH_REQUESTED, PAYMENT_CASH_SUCCESS, PAYMENT_CASH_FAILURE],
    promise: (client) => client.post('paymentByCash', {
      data,
      headers
    })
  }
};

export const cashClearBalance = (data) => {
  const headers = getHeaders();
  return {
    types: [CASH_CLEAR_BALANCE_REQUESTED, CASH_CLEAR_BALANCE_SUCCESS, CASH_CLEAR_BALANCE_FAILURE],
    promise: (client) => client.post('cashClearBalance', {
      data,
      headers
    })
  };
};
export const tipClear = data => {
  const headers = getHeaders();
  return {
    types: [TIP_CLEAR_REQUESTED, TIP_CLEAR_SUCCESS, TIP_CLEAR_FAILURE],
    promise: client =>
      client.post('tipClear', {
        data,
        headers,
      }),
  };
};

export const newIngenicoPayment = data => {
  const headers = getHeaders();
  return {
    types: [
      INGENICO_SALE_REQUESTED,
      INGENICO_SALE_SUCCESS,
      INGENICO_SALE_FAILURE,
    ],
    promise: client =>
      client.post('newIngenicoPayment', {
        data,
        headers,
      }),
  };
};