export default {
  // Login screen
  NO_NOTIFICATION: 'No Notification Found',
  REACTIVATE_WITH_NEW_CODE: 'Do you wish to re-activate with different activation code?',
  ACT_SUCCESS: 'Device Activated Successfully',
  LOGIN_SUCCESS: 'Successfully Logged In',
  ENTER_VERIFICATION_CODE: 'Enter your verification code:',
  SUCCESSFULLY_READ_EMPLOYEE_CARD: 'Employee card read successfully',

  // Check listing
  ENTER_TABLE_NUMBER: 'Enter Table Number',
  ENTER_CODE_FROM_WATCH: 'Enter 6-digit code from watch',
  ENTER_TAB_NAME: 'Enter Tab Name',
  ENTER_EMAIL_ADDRESS: 'Enter Email Address',
  ENTER_PHONE_NUMBER: 'Enter Phone Number',
  NO_ITEM_FOUND: 'No Items Found',
  NO_RECORDS_FOUND: 'No Records Found',
  SENT_VOID: 'This will void the item from the check. this operation cannot be reversed. Do you want to continue?',
  CHECK_VOID: 'This will void the the check. this operation cannot be reversed. Do you want to continue?',
  THE_CHECK_HAS_A_PAYMENT_WHICH_SHOULD_BE_REVERSED_FROM_PAYMENT_ADJUSTMENT_REPORT:"The check has a payment which should be reversed from payment adjustment report.",
  ENTER_NAME: 'Enter Name',
  CONFIRM_CASHOUT: 'This will cash you out. You will need to start a new bank to accept cash. Do you want to continue?',
  DRAWER_OPEN_FAIL: 'Cash out cannot be performed because the drawer is not responding to open command. Please request a manger to complete the cashout.',
  DRAWER_CASHIN_FAIL: 'Drawer is not responding to open command. Please complete the cash in and contact a manager to open the drawer.',
  EMPLOYEE_DRAWER_FAIL: 'Cashier should cash out from their assigned terminal',
  DRAWER_CASHOUT_FAIL: 'Drawer is not responding to open command. Please complete cash out and request a manger to open the drawer',
  CASH_PAYMENT_PROCESSED_BY_SERVER: 'Cash payment has to be processed by server. Do you want to continue?',
  MERGE_OPEN_CHECKS_CONFIRMATION_MSG: 'This will merge the selected checks. do you want to continue?',
  REMOVE_TAX_EXEMPT: 'This will remove the tax from the check. This operation cannot be reversed. Do you want to continue?',
  // Order Screen
  ENTER_CHECK_NAME: 'Enter Check Name',
  ENTER_SEAT_NUMBER: 'Enter Seat Number',
  ENTER_SEAT_NAME: 'Enter Seat Name',
  ENTER_STAGE_NAME: 'Enter Stage Name',
  NEGATIVE_BALANCE: 'Cannot make payment when one of the stages has a negative balance',
  DELETE_ALL_UNSENT_MSG: 'This will delete all unsent items. Do you want to continue ?',
  SEND_ALL_UNSENT_MSG: 'This will send all items from the cart to the kitchen. Do you want to continue ?',
  DELETE_ALL_SINGLE_UNSENT_MSG: 'This will delete all unsent item. Do you want to continue ?',
  SEND_ALL_SINGLE_UNSENT_MSG: 'This will send all item from the cart. Do you want to continue ?',
  SEND_MULTIPLE_MSG: 'This will send all items from the cart to the kitchen. Do you want to continue ?',
  SEND_SINGLE_MSG: 'This will send all item from the cart to the kitchen, Do you want to continue ?',
  DELETE_MULTIPLE_MSG: 'This will delete all items from the cart, Do you want to continue ?',
  DELETE_SINGLE_MSG: 'This will delete all item from the cart, Do you want to continue ?',
  SINGLE_DELETE_MSG: 'This will delete item from the cart. Do you want to continue ?',
  SINGLE_SEND_MSG: 'This will send the item from the  cart to the kitchen. Do you want to continue? ',
  MULTIPLE_DELETE_MSG: 'This will delete selected items from the cart. Do you want to continue ?',
  MULTIPLE_SEND_MSG: 'This will send selected items from the cart. Do you want to continue? ',
  SINGLE_SELECTED_DELETE_MSG: 'This will delete selected item from the cart. Do you want to continue ?',
  SINGLE_SELECTED_SEND_MSG: 'This will send selected item from the cart. Do you want to continue? ',
  CLEAR_ALL_FROM_SEAT: 'This will delete all items from the seat and move it to the table. do you want to continue?',
  NO_ITEM_MSG: 'Please add item/items to do payment',
  CLEAR_SEAT_FROM_STAGE: 'This will clear all items from the seat, do you want to continue?',
  PAYMENT_ERROR_HAVING_UNSENT: 'Payment cannot be made when check has unsent items',
  CLEAR_SEAT: 'This will clear all items from the seat and move it to the selected table or seat. do you want to continue?',
  RESTART_ORDER: 'This will restart order. Do you want to continue?',
  REPEAT_ITEM: 'This will repeat item from the cart. Do you want to continue?',
  REPEAT_ITEMS: 'This will repeat selected items from the cart. Do you want to continue? ',
  CANCEL_ORDER: 'This will cancel the order. Do you want to continue? ',
  OPEN_TABLE_CHECK: 'You have an open check on this table. Would you like to go to that check or create a new check',
  REVENUE_CENTER: 'Please select a revenue center',
  BEVERAGE_REQUIREMENT_ERROR: 'Beverage requirement by seat not met for the following seats: ',
  PLEASE_MAKE_A_SELECTION : 'Please make a selection',
  SEND_ORDER_CONFIRMATION_MESSAGE: 'This will send the order. do you want to continue?',
  TEXT_TO_PAY_CONFIRMATION_MESSAGE: 'This will send the order and SMS the guest a payment link. Do you want to continue?',

  // Stage
  SELECT_SEATS_CLICK_STAGE: 'Select seat(s) and click on stage',
  PLEASE_SELECT_SEAT: 'Please select one or more seats to add to the stage',
  DELETE_STAGE: 'This will delete the stage, do you want to continue ?',
  NO_STAGE_MSG: 'Please add stage/stages to do payment',
  PLEASE_SELECT_A_STAGE_TO_SEE_ITEMS: 'Please select a stage to see items',
  SELECT_CHECK: 'PLEASE SELECT THE CHECK YOU WOULD LIKE TO PAY',
  CONFIRM_STAGE_WITH_SPLIT: 'Some of the items on this check are split across seats. Loyalty offers cannot be applied when items are split. Do you want to continue with payment',

  // Survey
  SURVEY_SAVE: 'Thank you, survey has saved successfully',
  SURVEY_EXPERIENCE_ERROR: 'Select Option to share Experience',
  SURVEY_ENTER__VALID_EMAIL: 'Enter valid email',
  SURVEY_VALID_PHONE_NUMBER: 'Enter valid phone number',

  // Loyalty
  LOYALTY_JOIN_MESSAGE: 'Would you like to join our loyalty program today?',
  SUCCESSFULLY_READ_GIFT_CARD: 'Gift card read successfully',
  LOYALTY_SIGN_IN_MESSAGE: 'How would you like to sign in today?',
  LOYALTY_JOIN_IN_MESSAGE: 'How would you like to join today?',
  ENTER_COUPON_CODE: 'Enter Coupon Code',
  DELETE_LOYALTY_ACCOUNT: 'This will delete the loyalty account associated with this check. Do you want to continue?',

  // Payment Screen
  SIGNATURE_REQUIRED: 'Signature required',
  ENTER_TIP_MSG: 'Select tips or enter a valid tip amount or 0',
  ENTER_TIP_AMOUNT: 'ENTER TIP AMOUNT',
  ENTER_CARD_NUMBER: 'Click to enter card number',
  ENTER_SECURITY_CODE: 'Click to enter security code',
  AMOUNT_TO_PAY: 'Amount to pay',
  ENTER_EMPLOYEE_CODE: 'Enter Employee Code',
  CONTINUE_PAY_REMAINING_BALANCE: 'Click On Continue To Pay Remaining Balance',
  GREAT_SERVING_YOU: 'It was great serving you!',
  COME_BACK_SOON: 'Please come back to see us again soon.',
  PAYMENT_PROCESSING_ERROR: 'There was error processing your payment',
  PROCEED_WITH_CASH_PAYMENT_OF: 'Proceed with cash payment of',
  SWIPE_CARD_AFTER_BEEP: 'Please swipe card after the beep',
  QR_CODE_MESSAGE: 'Ensure that you fit the QR code completely into the box below.',
  CARD_READER_NOT_INITIALIZED: 'Card Reader not initialized',
  ENTER_MANAGER_CODE: 'Enter Manager Code',
  SCAN_QR_CODE_INFO: 'ScanQR code using phone or go to https://scanqr.io',
  COMPLETE_PAYMENT_AND_CONTINUE: 'Complete the payment on your phone and click continue',
  QR_PAY_COMPLETED_INFO: 'Thank you for using QR pay. You can get a copy of your receipt on your phone when payment is completed.',
  SELECT_PAYMENT_TYPE: 'PLEASE SELECT YOUR FORM OF PAYMENT BELOW',
  CONFIRM_SPLIT: 'If check is split by persons or amount, then no guest in your party can apply offers to this check. Please request the server to split the check if anybody in your party want to redeem an offer. Do you want to continue with this form of payment?',
  PREAUTH_POST_ONE: 'This will post a payment of $',
  PREAUTH_POST_TWO: 'to the selected card. do you want to continue?',
  PREAUTH_TIP: 'Tip should be less than or equal to max%',
  PAY_AN_AMOUNT: 'Enter Amount You Want To Pay',
  SPLITTING_MESSAGE: 'How Many Will be Splitting?',
  EACH_PAY: 'Each Will Pay',
  ENTER_TIP_PERCENT: 'ENTER THE PERCENT YOU WANT TO TIP',
  ENTER_TIP_AMOUNT: 'Enter The Amount You Want To Tip',
  ENTER_TIP_AMOUNT_TEXT: 'Enter the amount you want to tip',
  PAYMENT_COMPLETE:'Payment Completed !',
  ESTIMATED_POINTS: 'Estimated points earned',
  PAYMENT_OPTION_SELECTION: 'Please select a preferred payment method',
  PLEASE_COMPLETE_PAYMENT_AT_THE_COUNTER: 'please complete payment at the counter',
  PRESS_CONTINUE_TO_PLACE_ORDER: 'press continue to place order',
  NO_TIP_AMOUNT: 'Suggested tip is calculated on check amount before any applied discounts and after sales tax has been added',

  // TableTop
  CLICK_ON_OPEN_CHECK: '*Click on open check to open a check ',

  // Kiosk
  PLEASE_MAKE_SELECTION: 'Please make a selection',
  HOW_WOULD_YOU_LIKE_TO_PAY: 'HOW WOULD YOU LIKE TO PAY?',
  CASH_PAYMENTS_ARE_MADE_AT_THE_COUNTER: 'Cash payments are made at the counter',
  DO_YOU_WANT_TO_CONTINUE_PAYING_WITH_CASH: 'Do you want to continue paying with Cash',
  ORDER_COMPLETED: 'Order completed',
  RECEIPT_PHONE_NUMBER: 'Enter your phone number',
  RECEIPT_EMAIL: 'Enter your email address',
  NAME_FOR_THIS_ORDER: 'Please enter a name for this order',
  SEND_NEWS_VIA_SMS: 'Send me news and offers via SMS',
  SEND_NEWS_VIA_EMAIL: 'Send me news and offers via EMAIL',
  DO_WANT_US_TO_TEXT: 'Do you want us to text you when order is ready?',
  WOULD_YOU_LIKE_A_RECEIPT: 'WOULD YOU LIKE A RECEIPT?',
  YOUR_ORDER_PLACED: 'YOUR ORDER HAS BEEN PLACED!',
  PLEASE_CONTINUE_THE_PAYMENT: 'Please complete payment on the pin pad below.',
  PRESS_CONTINUE: 'Press continue when ready.',
  DO_YOU_NEED_ASSISTANCE: 'Do you need assistance?',
  RETRY_WHEN_READY: 'Press retry when ready',
  IN_ACTIVITY_MESSAGE: 'Your order will be cancelled for inactivity in',
  IN_RECEIPT_ACTIVITY_MESSAGE: 'You will be signed out for inactivity in',
  SCAN_MESSAGE: `PLEASE SCAN THE QR CODE USING YOUR PHONE${"'"}S CAMERA TO COMPLETE PAYMENT`,
  SCAN_QR_CONFIRM_MESSAGE: 'PAYMENT WILL BE COMPLETED ON YOUR PHONE. PLEASE HAVE YOUR PHONE WITH YOU.',
  YOUR_ORDER_WILL_CANCELLED: 'PLEASE CLICK THE STAY BUTTON IF YOU NEED MORE TIME TO COMPLETE PAYMENT. OTHERWISE, YOUR ORDER WILL BE CANCELLED',
  COUPON_APPLIED: 'Coupon is been applied to this check cannot make split payment or pay an amount',
  // -------------------------------------------------------------

  // Defaults
  default: 'Hmm, an unknown error occured',
  timeout: 'Server Timed Out. Check your internet connection',
  invalidJson: 'Please try again in sometime.',

  // Messsages
  ORDER_AND_PAY_MESSAGE: 'Order & pay from your phone!',
  PARTICIPATING_RESTAURANT_MESSAGE: 'Scan the OneDine QR code at any participating restaurant.',
  PAGE_NOT_FOUND: 'Oops! Page not found',
  ERROR_MESSAGE: 'We are sorry, but the page you requested was not found',
  USER_INFO: 'User Info',
  CONNECT_WITH: 'OR CONNECT WITH',
  YOUR_SERVER_IS: 'Your server is',
  SERVER_CALL_MESSAGE: 'A notification has been sent to your server.',
  THANK_YOU: 'THANK YOU!',
  ACTION_PAGE_TITLE: 'What would you like to do?',
  CONFIRM_LEAVE_TABLE: 'Please confirm that you want to leave table',
  LEAVE_TABLE_CONFIRMATION: 'Are you sure, you want to leave table?',
  POPIN_CONFIRM_MSG: 'Are you sure you want to cancel?',
  MODAL_CONIRM_MESSAGE: 'This will clear the order, Do you want to continue?',
  RECEIPT_SENT_TO: 'Receipt has been sent to your',
  YOUR_RECEIPT: 'Your Receipt',
  PLEASE_SELECT_SPLIT_CHECK: 'Please select how many people will split this check:',
  PLEASE_ENTER_AMOUNT: 'Please enter the amount you want pay',
  SPLITED_CHECK_LIST_TITLE: 'Your server separated the check, please select your split',
  HOW_YOU_SPLIT_CHECK: 'How would you like to split the check',
  SURVEY_TITLE: 'We would appreciate if you can take 1 minute to answer a survey',
  PHONE_NUMBER_LOGIN: 'Sign in with your phone number:',
  PASSWORD_LOGIN: 'Sign in to your account:',
  VERIFY_OTP_TITLE: 'We sent you a text with your verification code.',
  PLEASE_ENTER_CODE: 'Please enter the code below.',
  YOU_ARE_LOCATED_AT_TABLE: 'You are located at table ',
  MORE_ITEMS: 'more items',
  YOUR_REQUEST_FOR_LEAVE_TABLE: 'Your request for leave table is recorded.',
  PROFILE_HEADER: 'OneDine',
  YOUR_PAYMENT_WAS_SUCCESSFUL: 'Your Payment was successful',
  THANK_YOU_MESSAGE: 'Thank you and come see us again soon',
  HOW_WOULD_YOU_LIKE_YOUR_RECEIPT: 'How would you like your receipt',
  AT_LEAST_ONE_SPECIAL_CASE: 'At least 1 special case, uppercase & number',
  TERMS_AND_CONDITIONS: 'I agree to terms and conditions',
  PASSWORD_MUST_MEET_THE_FOLLOWING: 'Your password must meet the following:',
  EIGHT_CHARACTERS_LONG: '  be 8 to 15 characters long',
  AT_LEAST_ONE_NUMBER: 'contain at least one number',
  AT_LEAST_ONE_SPECIAL_CHARACTER: 'contain at least one special character',
  HAVE_BOTH_UPPER_AND_LOWER_CASE: 'contain a mix of upper and lower case letters',
  SWIPE_LEFT_REMOVE_CARD: 'Swipe left to edit / remove a card',
  WARNING_MESSAGE: 'Ugh! Something went wrong.',
  WARNING_MESSAGE_QRCODE: 'Ugh! Invalid sensor code',
  PLEASE_ENTER_EMAIL: 'Please enter the email you use to sign in to OneDine.',
  SUDDENLY_REMEMBERED_YOUR_PASSWORD: 'Suddenly remembered your Password? ',
  OVERALL_EXPERIENCE: 'How was your overall experience?',
  CANCEL: 'Cancel',
  TABLE_NOT_SELECTED: 'Table not selected',
  PAYMENT_SUCCESS: 'Payment Success!',
  APPLY_PAYMENT: 'Select or add a card to apply towards payment',
  PAYMENT: 'Payment',
  TOTAL: 'Total',
  ASSIGN_SENSOR: 'This will assign the sensor to table',
  HIGH_QUALITY: 'Were the ingredients of high quality?',
  SPEED_OF_FOOD_SERVICE: 'What do you think of the speed of food service?',
  STANDARD_TEXT_RATES: 'By entering your mobile number you agree to recieve a one time password and order status alerts via text message. Message and data rates may apply.',
  WANT_TO_DELETE: 'Are you sure want to delete?',
  PICKUP_YOUR_ORDER: 'When would you like to pickup your order?',
  DELIVER_YOUR_ORDER: 'When would you like your order delivered?',
  DELETE_ITEM_CONFIRMATION: 'Are you sure, you want to delete this item?',
  TABLE_ORDER_VALIDATION: 'There is no open check at this time. Please contact your server to open a check before placing an order',
  PAYMENT_CONFIRMATION: 'If you proceed, your card will be charged.',
  ORDER_CONFIRMATION: 'If you proceed, your order will be placed.',
  PAY_LATER_MESSAGE: 'Do you want pay now or at the restaurant?',

  // PROVIDE_PAYMENT_AT_ARRIVAL_TO_RESTAURANT: 'Please provide payment at arrival to restaurant.',
  PROVIDE_PAYMENT_AT_ARRIVAL_TO_RESTAURANT: '',
  CREATE_YOUR_PROFILE: 'Create your OneDine profile:',
  NO_ORDER_HISTORY: 'You haven\'t placed any orders yet.',
  ENTER_SPOT_NUMBER: 'Enter your spot number:',
  YOUR_SPOT_NUMBER: 'What\'s your spot number?',
  ENTER_CAR_DESCRIPTION: 'Provide a short description of your vehicle:',
  PAYMENT_RECEIVED_MSG: 'Payment has been received. Your order should be out shortly.',
  ORDER_RECEIVED_MSG: 'Your order has been received. A team member will be with you shortly to collect payment.',
  ORDER_RECEIVED_MSG_CONF: 'Your order has been received. You will receive a text message with a confirmation link.',
  ORDER_RECEIVED_MSG_CONF_WITH_LINK: 'Your order has been received. You will receive a text message with a link to check-in when you arrive at the location.',
  ORDER_FOR_STAGE: 'Please order something to stage the payment',
  NO_SEAT_SELECTED: 'Select seats',
  DISCOUNT_GREATER: 'Discount is greater than item price',
  SELECT_RECEIPT_TYPE: 'HOW WOULD YOU LIKE YOUR RECEIPT?',

  // Other_tips page message
  TIPS_PERCENTAGE_MSG: 'Tip this percentage of the subtotal:',
  TIPS_AMOUNT_MSG: 'Tip this amount:',
  TOTAL_TIPS_MSG: 'Including tip, make the total:',

  // links
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  SIGN_UP: 'SIGN UP',
  LOGIN_WITH_CODE: 'Sign in with your phone number',
  LOGIN_WITH_PASSWORD: 'Sign in with a password',
  CHANGE_YOUR_PASSWORD: 'Change Your Password',
  CHANGE_BASIC_INFO: 'Change Your Basic Informations',
  VIEW_DETAILS: 'View Details',
  EDIT: 'Edit',
  GO_TO_HOME: 'Go to Home',
  VIEW_CHECK: 'View Check',
  EDIT_PROFILE: 'Edit Your Profile',
  CHANGE: 'Change',

  // OTHER-LINKS
  TAP_SENSOR_NO_STAGE: 'Tap Sensor / No Stage',
  TAP_SENSOR_STAGE: 'Tap Sensor / Staged',
  TAP_SENSOR_NO_CHECK: 'Tap Sensor / No Check',
  KIOSK_TEXT: 'Kiosk Text',
  ONEDINE_MAIL: 'www.onedineapp.com',
  CPK_ONEDINE: 'cpk.onedineapp.com',
  CONFIRM_TERMS_CONDITION: 'By clicking continue, I agree to the Terms and Privacy Policy.',
  I_AGREE_TO_RECEIV_TEXT: 'I agree to receive text messages to the phone number provided about this order. Message and data rates may apply.',

  // Payment screen
  TOTAL_DUE: 'Total Due',
  ORDER_COMPLETE_MSG: 'Orders are currently taking',
  MINUTES: 'minutes',
  CASH_PAYMENT_PROCESS: 'Cash Payment has to be processed by your server.',
  SELECT_ONE_OPTION: 'Please select one of the following options.',

  // Checked_IN
  CHECKED_IN_HEADER: 'You checked in at:',
  YOU_NEED_SIGN_IN: 'You`ll need to sign in before you can order.',
  CANCEL_CHECKIN: 'Cancel check-In',

  // dashBoard
  SCAN_QR_CODE: 'Scan QR Code',
  ENTER_CODE_INSTEAD: 'Or enter the 7-digit code instead:',

  // Login
  YOUR_CHECKED_LOCATION: 'You`re checking in at:',
  USE_DIFFERENT_NUMBER: 'Use a different phone number',

  // OTHER ORDERS
  SINGLE_PERSON_ORDERING: 'Is anyone else using their phone to place an order at this table',

  // Spot Screens
  ENTER_NAME_BELOW: 'Enter your name below',

  // Up sell
  MAY_WE_SUGGEST: 'May we suggest?',

  // Daisy Chain Screens
  DC_ORDER_MSG: 'Are you the last person to add to this order?',
  DC_ORDER_PLACE_MSG: 'Are you the only person sending an order right now?',

  SELECT_STORE_ORDER_FROM: 'Select the store you had like to order from',

  ENTER_MOBILE_NUMBER: 'Please Enter Your Mobile Number',
  ENTER_EMAIL_ID: 'Please Enter Your Email Id',

  STORE_CHECK_OUT: 'Please select the store to checkout from?',

  CONFIRM: 'Confirm',

  USE_SAVED_CARD: 'Use a saved card',
  MY_SAVED_CARD: 'My saved card',
  CARD_NAME: 'Card Name',
  EXP: 'Exp',
  ENTER_NEW_CARD: 'Enter new card',
  CHANGE_CARD: 'Change card',
  UPDATE_CARD_NAME: 'Update Card Name',
  WALLET_EMPTY: 'Your wallet is empty',
  WALLET_PLEASE_ADD_CARD: 'Please save some card to wallet',
  PLEASE_SELECT_CARD: 'Please select a card',
  PLEASE_ADD_CARD: 'Please add a card',

  WELCOME_MSG: '',

  // Coupon
  ENTER_COUPON_NUMBER: 'Enter coupon number',
  YOUR_CART_EMPTY: 'Your cart is empty',

  LOYALTY_LOOKUP_ACCOUNT: 'How would you like to look up your account?',
  LOYALTY_REWARDS_MESSAGE: 'Would you like to join rewards program?',

  LOYALTY_ACC_NUMBER_LABEL: 'Please enter your account number',
  LOYALTY_PHONE_NUMBER_LABEL: 'Please enter your phone number',
  LOYALTY_EMAIL_ADDRESS_LABEL: 'Please enter your email address',
  LOYALTY_QR_CODE_LABEL: 'QR code',

  SELECT_ALL_CONFIRMATION: 'You have not selected all items for payment. Please confirm how would you like to proceed.',
  NO_CHECK_MESSAGE: 'Checks are not available',

  PRE_AUTH_LOCATION_CREDIT_CARD: 'This location requires a credit card hold before placing an order. You must settle your tab for the final check amount before departing this establishment.',
  PRE_AUTH_PERCENTAGE_ZERO: 'If you leave without settling your tab, your hold will be used to settle the final bill amount.',
  PRE_AUTH_PERCENTAGE_NOT_ZERO: 'If you leave without settling your tab, your card will be used to settle the final bill amount ',
  PRE_AUTH_HOLD: 'A hold will be placed on your card. I agree to settle my tab for the final check amount before departing this establishment.',
  EXTERNAL_CREDIT_CARD: 'This will post a payment of subtotal and tip tips',

  //kds
  KDS_PRIORITIZE_CONFIRMATION: 'This will reprioritize this ticket. Do you want to continue?',
  KDS_BUMP_CONFIRMATION: 'There are items pending in this order. Are you sure you want to bump?',
  KDS_DRAG_CONFIRMATION: 'This will drag the item by the selected time. do you want to continue?',
  KDS_RUSH_CONFORMATION: 'This will rush the item by the selected time. do you want to continue?',
  
  //Landing Screen
  SEND_AN_SMS_LINK_TO_ALLOW_QR_ORDER_AND_PAY : 'Send a SMS link to allow QR order and pay',
  ADD_PHONE_NUMBER : 'add phone number',

  // Table Grouping Order
  // QR_CODE_MESSAGE: 'Please request a QR from your party host to join this table. The host can generate a QR by clicking on the Invite Guest tile on their app. Ensure that you fit the QR code completely into the box below.',
  // QR_CODE_JOIN_MESSAGE: 'Please present this QR code to any other member of your party who would like to join this check.',
  // JOIN_THROUGH_SMS_MESSAGE: 'Want to invite over SMS? Copy the link below and send it using your SMS app.',
  WANT_TO_INVITE_OVER_SMS: 'Want to invite over SMS? Copy the link below and send it using your SMS app.',
  PLEASE_PRESENT_THIS_QR: 'Please present this QR code to any other member of your party who would like to join this check.',
  PLEASE_REQUEST_QR_PARTY_HOST: 'Please request a QR from your party host to join this table. The host can generate a QR by clicking on the Invite Guest tile on their app. Ensure that you fit the QR code completely into the box below.',
  CHECK_TABLE_OPEN: 'There is a check open on this table.would you like to join this check?',
  AUTOMATIC_SERVICE_CHARGE_ADDED: 'An automatic Service Charge has been added to you bill. 100% of our Service Charge benefits our team. To mantain a safe and contactless environment, we do not accept tips or gratuities. Thank you!',
  // Immediate order
  IMMEDIATE_ORDER_SEND: 'Would you like it sent right away?',
  IMMEDIATE_ORDER_FAILURE: 'There was an error sending your order. Would you like to add to cart instead?',

  // Pay-my-guests
  PAY_SELECTED_SEATS: 'Select seats for payment',
  PAY_SELECTED_ITEMS: 'Select items for payment',
  UNAVAILABLE_TOAST_MESSAGE: 'the highlighted items are not available at the selected time. please remove these items from your cart or select a new time',
  EDIT_ADDRESS_MESSAGE: 'if you change the address, your cart will be cleared and you will have to restart your order. do you want to continue?',

  // Pickup and delivery
  PICKUP_TIME_NOT_AVAILABLE: 'Pickup time not available for selected date',
  DELIVERY_TIME_NOT_AVAILABLE: 'Delivery time not available for selected date',

  TIP_SELECTION_REQUIRED_MESSAGE: 'Please select a tip to complete payment',
  PASSWORD_MISMATCH: "Password and Confirm Password doesn't match!",

  // Others
  INACTIVITY_LOG_OUT: 'You will be logged out for inactivity in',

  // language
  PLEASE_SELECT_YOUR_LANGUAGE: 'Please select your language',

  TIPS_MESSAGE: 'Waiting for guest to enter tip',

  PAYMENT_SALE_MESSAGE: 'Waiting for guest to complete payment',

  NO_DISCOUNTS: 'No discounts available',

  //game card
  SWIPE_MESSAGE: "This will activate the swiped cards. do you want to continue?",

  // Receipt screen
  RS_PAYMENT_COMPLETE: "Payment Complete!",
  RS_IT_WAS_GREAT_SERVING_YOU: "It was Great Serving You!",
  RS_PLEASE_COME_BACK_SOON: "Please Come Back To See Us Again Soon.",

  // split by selected
  SBSS_SELECT_SEAT_TO_PREVIEW_ITEMS: "Please select seat(s) to preview items",
  SBSS_SELECT_CHECK_TO_PREVIEW_ITEMS: "Please select a check to preview items",
  SBSS_SELECT_SEAT_AND_CREATE_CHECKS: "Select seat(s) and click create check",
  SBSS_SELECT_SEATS_TO_CREATE_SPLIT_CHECK:'Please select one or more seats to create a split check.',
 
  // Employee Initiated payment Screen
  EPS_CASH_PAYMENT_PROCESSED_BY_SERVER: "Cash Payment has to be processed by your server",
  EPS_CHECK_DUE_AMOUNT: "Check has a due amount of",
  EPS_YOU_WANT_TO_EXIT: ". are you sure you want to exit?", 

  //gift card receipt
  RS_NO_RECEIPT_SELECTED : "No receipt option was selected. Do you want to proceed without a receipt?",

  //Print 
  PRINT_SUCCESS: 'Your receipt has been printed. Please contact your server for the receipt.',
  PRINT_CLOSE: 'Close',
};
