import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import otp from './otp';
import oauth from './oauth';
import localization from './localization';
import { register } from './register';
import password from './password';
import googleFonts from './getGoogleFonts';
import storeChecks from './storeChecks';
import storeNotifications from './storeNotifications';
import ui from './ui';
import notification from './notification';
import notificationmodal from './notificationmodal';
import theme from './theme';
import browse from './browse';
import profile from './profile';
import orders from './orders';
import mydata from './mydata';
import payment from './payment';
import survey from './survey';
import header from './header';
import signup from './signup';
import receipt from './receipt';
import checks from './checks';
import keyboard from './keyboard';
import stage from './stage';
import utils from './utils';
import optionsMenus from './optionsMenus';
import loyalty from './loyalty';
import tableTop from './tableTop';
import kiosk from './kiosk';
import cubby from './cubby';
import orderUp from './orderUp';
import kds from './kds';
import cashOut from './cashOut';

const appReducer = combineReducers({
  router: routerReducer,
  otp,
  header,
  profile,
  mydata,
  browse,
  survey,
  orders,
  payment,
  oauth,
  theme,
  password,
  localization,
  register,
  notificationmodal,
  signup,
  googleFonts,
  ui,
  storeChecks,
  storeNotifications,
  form: formReducer,
  notification,
  receipt,
  checks,
  keyboard,
  stage,
  utils,
  optionsMenus,
  loyalty,
  tableTop,
  kiosk,
  cubby,
  orderUp,
  kds,
  cashOut,
});

// Setup root reducer
const rootReducer = (state, action) => {
  // console.log('MIDDLEWARE:', state, action);
  return appReducer(state, action);
};

export default rootReducer;
