export const sortTable = (sortColumn, data, sortBy) => {
  if (data.length === 1) {
    return data;
  }
  if (sortColumn === '') {
    return data;
  }

  const sorted = data.sort((a, b) => {
    if (sortBy === 'desc') {
      if (b[sortColumn] > a[sortColumn]) {
        return 1;
      }
      return b[sortColumn] < a[sortColumn] ? -1 : 0;
    }
    if (b[sortColumn] < a[sortColumn]) {
      return 1;
    }
    return b[sortColumn] > a[sortColumn] ? -1 : 0;
  });
  return sorted;
};

export default {
  sortTable
};
