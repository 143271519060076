/* eslint-disable */
import EventEmitter from '@utility/eventSubscribers';

import superagent from 'superagent';
import { APIConfig } from '../constants';
import { userInfo, utills } from '@utility';
import moment from 'moment';

const methods = ['get', 'post', 'put', 'patch', 'del'];
const HOSTNAME = APIConfig.hostname;
const ENDPOINTS = APIConfig.endpoints;

function formatUrl(path) {
  if (!path.includes('https://')) {
    let mappedEndpoint = ENDPOINTS[path];
    if (path.indexOf('/') !== -1) {
      mappedEndpoint = '';
      const splitPathArray = path.split('/');
      mappedEndpoint += ENDPOINTS[splitPathArray[0]]+'/';
      splitPathArray.shift();
      mappedEndpoint += splitPathArray.join('/')
    }
    const adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint;
    return adjustedPath;
  }
  return path;
}

export default class ApiClient {
  constructor(req) {
    methods.forEach(method => {
      this[method] = (path, { params, data, headers, files, fields } = {}) => new Promise((resolve, reject) => {
        let request = superagent[method](formatUrl(path));
        if(path.indexOf('fakeapi') !== -1) {
          let fakePath = path;
          let splitPathArray = fakePath.split('/');
          splitPathArray.shift();
          let constructedURL = splitPathArray.join('/');
          request = superagent[method](`http://localhost:3004/${constructedURL}`).withCredentials();
        }
        
        request.set({ 'Content-Type': 'application/json;charset=UTF-8' });
        let requestTime = '';
        let requestDate = moment().format('MMMM Do YYYY, h:mm:ss a');
        let responseDate = '';
        let responseTime = '';
        let duration = '';

        if (params) {
          request.query(params);
        }

        if (headers) {
          request.set(headers);
        }

        if (userInfo.isUserLogin()) {
          request.set('Authorization', `Bearer ${userInfo.getToken()}`);
        }

        if (files) {
          files.forEach(file => request.attach(file.key, file.value));
        }

        if (fields) {
          fields.forEach(item => request.field(item.key, item.value));
        }

        if (data) {
          request.send(data);
          requestDate = moment().format('MMMM Do YYYY, h:mm:ss a');
          requestTime = new Date().getTime();
        }

        request.end((err, { body } = {}) => {
          responseDate = moment().format('MMMM Do YYYY, h:mm:ss a');
          responseTime = new Date().getTime();
          duration = responseTime - requestTime;
          if (err) {
            reject(body || err);
            // if (window.ReactNativeWebView) {
            //   EventEmitter.networkLogs(request.url, request._data ? request._data : '', { type: 'failure', body }, { requestDate, responseDate, duration });
            // }
          } else {
            if (request.url.includes('compressed')) {
              resolve({ ...utills.decriptRespose(body.compressedBytes), response: body });
            } else {
              resolve(body);
            }
            // if (window.ReactNativeWebView) {
            //   EventEmitter.networkLogs(request.url, request._data ? request._data : '', { type: 'success', body }, { requestDate, responseDate, duration });
            // }
          }
        });
      });
    });
  }

  empty() {}
}
