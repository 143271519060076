import React from 'react'
import Modal from '@components/common/Modal';
import {closeErrorPopUp} from '@reducers/notification';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const ErrorPopUp = ({
  isOpen,
  res,
  serverAppTheme,
  ...props
}) => {
  return (
    <div>
        <Modal noBackground isOpen={isOpen} isModalCenter style={{width:'363px',height:'363px', margin:'auto', backgroundColor:'transparent', border:'none'}}>
            <div style={{fontFamily:'poppins', color: serverAppTheme?.primary_text}} className='h-100'>
                <div className='h-100'>
                 <div className='col-12 h-26 px-0 d-flex justify-content-center align-items-center' style={{ backgroundColor: serverAppTheme?.toastError, borderTopLeftRadius:'15px',borderTopRightRadius:'15px', borderLeft: serverAppTheme?.popupBorder, borderRight: serverAppTheme?.popupBorder, borderTop: serverAppTheme?.popupBorder }}>
                 <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                  <mask id="mask0_1262_8515" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="56">
                    <rect width="56" height="56" fill="#D9D9D9"/>
                  </mask>
                  <g mask="url(#mask0_1262_8515)">
                    <path d="M27.7083 39.375C28.3694 39.375 28.9236 39.1514 29.3708 38.7042C29.8181 38.2569 30.0417 37.7028 30.0417 37.0417C30.0417 36.3806 29.8181 35.8264 29.3708 35.3792C28.9236 34.9319 28.3694 34.7083 27.7083 34.7083C27.0472 34.7083 26.4931 34.9319 26.0458 35.3792C25.5986 35.8264 25.375 36.3806 25.375 37.0417C25.375 37.7028 25.5986 38.2569 26.0458 38.7042C26.4931 39.1514 27.0472 39.375 27.7083 39.375ZM25.375 30.0417H30.0417V16.0417H25.375V30.0417ZM27.7083 51.0417C24.4806 51.0417 21.4472 50.4292 18.6083 49.2042C15.7694 47.9792 13.3 46.3167 11.2 44.2167C9.1 42.1167 7.4375 39.6472 6.2125 36.8083C4.9875 33.9694 4.375 30.9361 4.375 27.7083C4.375 24.4806 4.9875 21.4472 6.2125 18.6083C7.4375 15.7694 9.1 13.3 11.2 11.2C13.3 9.1 15.7694 7.4375 18.6083 6.2125C21.4472 4.9875 24.4806 4.375 27.7083 4.375C30.9361 4.375 33.9694 4.9875 36.8083 6.2125C39.6472 7.4375 42.1167 9.1 44.2167 11.2C46.3167 13.3 47.9792 15.7694 49.2042 18.6083C50.4292 21.4472 51.0417 24.4806 51.0417 27.7083C51.0417 30.9361 50.4292 33.9694 49.2042 36.8083C47.9792 39.6472 46.3167 42.1167 44.2167 44.2167C42.1167 46.3167 39.6472 47.9792 36.8083 49.2042C33.9694 50.4292 30.9361 51.0417 27.7083 51.0417ZM27.7083 46.375C32.9194 46.375 37.3333 44.5667 40.95 40.95C44.5667 37.3333 46.375 32.9194 46.375 27.7083C46.375 22.4972 44.5667 18.0833 40.95 14.4667C37.3333 10.85 32.9194 9.04167 27.7083 9.04167C22.4972 9.04167 18.0833 10.85 14.4667 14.4667C10.85 18.0833 9.04167 22.4972 9.04167 27.7083C9.04167 32.9194 10.85 37.3333 14.4667 40.95C18.0833 44.5667 22.4972 46.375 27.7083 46.375Z" fill="white"/>
                  </g>
                 </svg>
                </div>
                <div className='h-70 position-relative' style={{ backgroundColor: serverAppTheme?.popUp_bg, borderBottomLeftRadius:'15px', borderBottomRightRadius:'15px', borderLeft:serverAppTheme?.popupBorder, borderRight:serverAppTheme?.popupBorder, borderBottom:serverAppTheme?.popupBorder}}>
                   <div className='col-12 h-55 px-0 text-center pt-1'>
                       <span className='col-12 px-0 fs_40 fw-500'>
                          Error!
                       </span>
                       <br/>
                       <p style={{fontSize:'1rem', wordBreak:'break-word'}} className='col-12 px-1 h-55 mb-0 overflowY'>
                         {res}
                       </p>
                   </div>
                   <div className='col-12 px-0 h-45 d-flex justify-content-center align-items-center position-absolute'>
                      <button style={{backgroundColor: serverAppTheme?.secondaryBtn}} className='col-8 px-0 border-0 rounded text-white btn44 fw-600'  type='button' onClick={() => props.closeErrorPopUp()}>OK</button>
                   </div>
                </div>  
                </div>  
            </div>
        </Modal>
    </div>
  )
}

const mapSateToProps = ({ notification, theme }) => ({
  isOpen: notification.isErrorPopUpOpen,
  res: notification.res,
  serverAppTheme: theme.serverAppTheme
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
  closeErrorPopUp,
}, dispatch);
export default connect(
  mapSateToProps,
  mapDispatchToProps
)(ErrorPopUp);